
import React, { Component } from 'react';
import './ErrorList.css';
import utils from './../../utils';
import { Row, Col, Button, Modal, Form, Card, Image } from 'react-bootstrap'
import EditErrorDetails from './EditErrorDetails';
import RoleConstant from "./../../constants/RoleConstant";
import PropTypes from 'prop-types';
import MessageConstant from './../../constants/MessageConstant';
import ConfirmationModal from './../PopupModels/ConfirmationModal'
import RouterConstant from './../../constants/RouterConstant';
import { withRouter } from "react-router-dom";
import EndpointConstant from './../../constants/EndpointConstant';
import PopupModels from '../PopupModels/PopupModels';
import Moment from 'react-moment';
import CommentHistory from './CommentHistory';
import { FileUploader } from '..';
import moment from 'moment';

/**
* A class that can return some html
*@return {Object} .
*@param {object} props
*/
class ErrorListViewDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      followUp: false,
      editError: false,
      errorDetails: this.props.errorDetails,
      comments: this.props.errorDetails.comments,
      editComment: false,
      commentSuccess: false,
      latestComments:null,
      // commentHist:[{
      //   updatedBy:'ABC',
      //   updatedAt:'05/01/2020',
      //   comment:"Testing ABC"
      //     },
      //     {
      //         updatedBy:'DEF',
      //         updatedAt:'05/02/2020',
      //         comment:"Testing DEF"
      //     },
      //     {
      //         updatedBy:'GHI',
      //         updatedAt:'05/03/2020',
      //         comment:"Testing GHI"
      //     }
      // ],
      showComment: false,
      viewAttachment : this.props.errorDetails.attachments,
      showDatasetNameInput: false,
      datasetName: [],
      deleteConfirmation: false,
      deleteModel: false,

    }
    //console.log("comments",this.props.errorDetails.comments)
    this.goBack = this.goBack.bind(this);
    this.showFollowUp = this.showFollowUp.bind(this);
    this.showEditError = this.showEditError.bind(this);
    this.hideEditError = this.hideEditError.bind(this);
    this.editComment = this.editComment.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.showErrorDetails1 = this.showErrorDetails1.bind(this);
    this.showHistory = this.showHistory.bind(this);
    this.hideHistory = this.hideHistory.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.showDatasetNameInput = this.showDatasetNameInput.bind(this);
    this.handleDatasetNameInputClose = this.handleDatasetNameInputClose.bind(this);
    this.performFileUpload = this.performFileUpload.bind(this);
    this.hideDatasetNameInput = this.hideDatasetNameInput.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleDeleteConfirmation=this.handleDeleteConfirmation.bind(this);
    this.deleteAttachment=this.deleteAttachment.bind(this);
    this.handleDeletionClose=this.handleDeletionClose.bind(this);
  }
  goBack() {
    if (this.props.error === 'old') {
      this.props.hideViewDetails(); }
    else if (this.props.error === 'created') {
      const path = this.props.location.pathname.slice(1);
      if (path === RouterConstant.HOME_LINK.slice(1)) {
        utils.gotoPage(RouterConstant.ERROR_LIST_LINK)
      } else {
        window.location.reload(false)
      }
    } else {
      window.location.reload(false)
    }
    // window.location.reload(false)
  }

  onDrop(result) {
    //console.log("onDrop", result);
    this.setState({ viewAttachment: result })
  }

  showDatasetNameInput(id, files, fileType) {
    let fileName = [];
    if (fileType) {

     fileName = files.map((data)=>{
        return (
          data.name.split('.')[0]
        )
      });
      this.setState({
        datasetName: fileName,
        showDatasetNameInput: true,
        currentDatasetInput: 'upload',
        files: files,
        fileType,
        id: id,
      });
    } else {
      this.setState({
        datasetName: files.split('.')[0],
        datasetType: files.split('.')[1],
        showDatasetNameInput: true,
        currentDatasetInput: 'editName',
        datasetId: id,

      });
    }
  }

  handleDatasetNameInputClose() {
    let self = this;
    let fileUpload = true;
    // datasetName = this.state.datasetName;
    this.state.datasetName.forEach((emptyFile)=>{
      if (emptyFile.length === 0 || emptyFile==undefined) {
        // self.refs.header.showAlertDialog("Please enter File name");
        //alert("Please enter File name");
        this.growl.show({severity:'error', summary: 'Error', detail:`Please enter File name`, life: 5000});
        fileUpload = false;
        return;
      }
    });

    let datasetNameExist = false;
  if(fileUpload){
    this.state.viewAttachment.forEach((data) => {
      // datasetName.push(data.attachmentPath.split('.')[0]);
      const datasetName = data.attachmentName;
      self.state.datasetName.forEach((datasetDetails)=>{
        if (datasetName.toString().toLowerCase() === datasetDetails.toLowerCase()) {
          datasetNameExist = true;
        }
      })


    });
  }
    if (datasetNameExist) {
      // self.refs.header.showAlertDialog("File name already exist!");
      //alert("File name already exist!");
      this.growl.show({severity:'error', summary: 'Error', detail:`File name already exist!`, life: 5000});
      return;
    }
    if (this.state.currentDatasetInput === 'upload') {
      if(fileUpload) {
      this.performFileUpload(this.state.files, this.state.fileType, this.state.id);
    }

    } else {
      const data = {
        id: this.state.datasetId,
        name: this.state.datasetName + '.' + this.state.datasetType
      };
      this.setState({
        showDatasetNameInput: false,
      });
      utils.makeRequest(this, EndpointConstant.UPDATE_DATASET_NAME, data, function (result) {
        //console.log(JSON.stringify(result));
        self.onDrop(result.attachmentList);
        self.setState({
          successDialog: true,
          successMessageContent: 'Your Dataset name has been updated successfully',
          datasetName: ''
        });
      });
    }
  }

  performFileUpload(files, fileType, id) {
    let self = this;
    this.setState({
      showDatasetNameInput: false,
    });
    files.forEach((fileDetails,index)=>{
      fileType.forEach((fileTypesData,i)=>{
        if(i===index) {
        utils.uploadFiles(this, fileDetails, fileTypesData, id, function (result) {
          //console.log("viewdetailsattachment",result);
          self.onDrop(result.attachmentList);
          if(i===fileType.length-1) {
            self.setState({
              successDialog: true,
              successMessageContent: 'Your File is uploaded successfully',
            });
          }
        }, this.state.datasetName[i], EndpointConstant.ADD_ERROR_ATTACHMENT);
      }
      });

    });

  }

  downloadErrorAttachment(name,filename) {
    const data = {
      attachmentName: name,
      filename:filename
    };
    utils.makeRequest(this, EndpointConstant.DOWNLOAD_ERROR_ATTACHMENT, data, function (result) {

      //console.log(JSON.stringify(result));

      window.open(result.url, 'Download');

    });
  }

  
  handleDeleteConfirmation(id) {
    this.setState({ deleteConfirmation: !this.state.deleteConfirmation, deleteId: id })

  }

  deleteAttachment(attachmentId) {
    const data = {
      id: attachmentId
    };
    const { viewAttachment } = this.state;
    let self = this;
    utils.makeRequest(this, EndpointConstant.DELETE_ERROR_ATTACHMENT, data, function (result) {
      //console.log(result);
      // if(result.error===false)
      // {
      //   let attachments=result.attachmentList
      //   self.setState({viewAttachment:attachments});

      // }
      let attachments = viewAttachment.filter(attachment => attachment.id !== attachmentId);
      //console.log("delete result", attachments);
      self.setState({ viewAttachment: attachments , deleteConfirmation:false, deleteModel:true})

    });
  }

  handleDeletionClose(event) {
    event.preventDefault();
    this.setState({
    deleteModel: false
    });
  }
  hideDatasetNameInput() {
    this.setState({ showDatasetNameInput: false })
  }

  closeDialogBox() {
    this.setState({
      successDialog: false,
    });
  }

  changeHandler(e,i) {
    e.preventDefault();
    let datasetName=this.state.datasetName;
    datasetName[i]=e.target.value;
    this.setState({ datasetName });
  }



  colorChangeStatus(status) {
    const styles = {
      Completed: '#0F9D58',
      Pending: '#EA4335',
      Processing: '#F4B400'
    }
    switch (status) {
      case ('2'):
        return { color: styles.Completed };
      case ('0'):
        return { color: styles.Pending };
      case ('1'):
        return { color: styles.Processing };
      default:
        return {};
    }
  }
  showFollowUp() {
    this.setState({ followUp: !this.state.followUp })
  }
  showEditError() {
    this.setState({ editError: !this.state.editError })
  }
  hideEditError(errors) {

    // utils.makeRequest(this, EndpointConstant.ERROR_DETAILS, data, function (result) {
    //   debugger
    //   const errors = result.errorsDetails[0];
    //   self.loading = false;
    this.setState({ errorDetails: errors, editError: false })
    // self.setState({ show: true })

    // }) 
  }
  handleCommentChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  updateAdminComment(){
    let self = this
    let data = {
      id: this.state.errorDetails.id,
      comments: this.state.latestComments
    }
    utils.makeRequest(this, EndpointConstant.UPDATE_ERROR, data, function (result) {
      if (result.error === false || result.error === "false") {
        self.loading = false;
        self.setState({ editComment: false, commentSuccess: true,id: result.errorId});
        let errorData={
          id:result.errorId
        }
        utils.makeRequest(this, EndpointConstant.ERROR_DETAILS, errorData, function (results) {
          const errors = results.errorsDetails[0];
          self.loading = false;
          self.setState({ errorDetails: errors });
          self.setState({ show: true, latestComments:null });
        });
      }
    });
  }
  updateComment() {
    // let self = this
   
    if(this.state.latestComments=== '' || this.state.latestComments=== null) {
      this.setState({ editComment: false });
      // this.setState({ editComment: false })
    }
    else {
      this.updateAdminComment();
    }
  }
  showErrorDetails1() {
    this.setState({ commentSuccess: false })
  }
  editComment() {
    this.setState({ editComment: true })
  }
  hideEditComment() {
    this.setState({ editComment: false, comments: this.props.errorDetails.comments })
  }
  doMailto() {
    let sMailto="mailto:"
    window.open(sMailto);
 }
 showHistory() {
    this.setState({showComment: true});
  }
  hideHistory() {
    this.setState({showComment:false});
  }
  render() {
    const { editError } = this.state
    let errorDetails = this.state.errorDetails;

  //console.log("errorlistviewdetails",errorDetails);
    const role = utils.getSessionItem('role');
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const followUpButton = <span className='a_followup'><a href="mailto:" className='followupLink mt-4 clear mr-2 rounded-lg follow-up-back'>Follow Up</a></span>
    const editErrorButton = (this.state.errorDetails.status !== '2') ? <Button type="submit" onClick={this.showEditError} className={role === RoleConstant.QualityMonitor ? 'mt-4 button-color follow-up-back' : 'mt-4 clear mr-2 follow-up-back'}>Edit Error</Button> : ''
    
    let fileNames;
    // if(this.state.fileType.length>0 && this.state.datasetName) {
   fileNames = this.state.datasetName.map((data,index)=>
    {
      return(
        <div className="dataset-name-entry-content scrollFileNames" key={index}>
        <label>Files Name{index+1}</label><br />
        <input type="text" className="datasetname-input" name="datasetName" onChange={()=>this.changeHandler(event,index)} value={data}></input>
      </div>
      )
    });
    
    let commentHist = this.state.errorDetails.comments;
    // let commentHist = this.state.latestComments;
    //console.log("sort",commentHist);
    // const commentEditData = this.state.errorDetails.comments[0].comments;
    let commentEditData;
    if(Array.isArray(commentHist)) {
    commentHist.sort(function (a, b) {
      return b.modifiedAt - a.modifiedAt;
    });
    //console.log("sort",commentHist);
  }
    let commentHistory = '';
    if(Array.isArray(commentHist) && commentHist.length > 0){
      // [commentHist[commentHist.length-1],commentHist[commentHist.length-2]]=[commentHist[commentHist.length-2],commentHist[commentHist.length-1]]
      commentHistory =  commentHist.map((data,index)=>{
        //console.log(index,'index')
        if(index<3){
            return (
                <Card className="mb-2 commentHistory">
                    <div className="cmt">
                        <span className="updatedBy">Updated By: {data.modifiedBy}</span>
                        <span className="updatedAt">Updated At: <Moment unix format="MM/D/YYYY">{data['modifiedAt']}</Moment></span>
                        </div>
                    <Card.Body>{data.comments}</Card.Body>
                </Card>
            )
        }
        }); 
    } else if (commentHist.length > 0){
      commentEditData = commentHist;
      //console.log("commentEditData",commentEditData)
    }
    else {
        commentHistory = (
            <Card className="mb-2">
                <Card.Body>Comments Not Available</Card.Body>
            </Card>
        );
    }
    return (
      <span>
        {editError ? <EditErrorDetails showEditError={this.hideEditError} errorDetails={errorDetails} close={this.goBack} /> : <div className="content-layout">
          <div className='error-details-size'>Error Details</div>
          <div className="details-layout">
            <div className="details-row error-details-row">
              <Row>
                <Col md={4}><label>Error ID</label><span>{errorDetails.errorId.toUpperCase()}</span></Col>
                <Col md={4}><label>Created On</label><span>{moment.unix(errorDetails.createdAt).utc().format('MM/DD/YYYY hh:mm A')}</span></Col>
                <Col md={4}><label>Dataset</label><span>{errorDetails.datasetName}</span></Col>
              </Row>
              <Row>
              <Col md={4}><label>Associated Team</label><span>{errorDetails.associatedTeam}</span></Col>
                <Col md={4}><label>Sub-Case Number</label><span>{errorDetails.subcaseNumber.toUpperCase()}</span></Col>
                <Col md={4}><label>Modified On</label><span>{moment.unix(errorDetails.modifiedAt).utc().format('MM/DD/YYYY hh:mm A')}</span></Col>                
              </Row>
              <Row>
                <Col md={4}><label>Error Type</label><span>{siteinfo.ErrorTypes[errorDetails.errorType]}</span></Col>
                <Col md={4}><label>Country</label><span>{errorDetails.country}</span></Col>
                <Col md={4}><label>Created By</label><span>{errorDetails.createdBy}</span></Col>                
              </Row>
              <Row>
              <Col md={4}><label>Error Criticality</label><span>{siteinfo.ErrorCriticality[errorDetails.errorCriticality]}</span></Col>
                <Col md={4}><label>Owner</label><span>{errorDetails.owner}</span></Col>
                <Col md={4}><label>Modified By</label><span>{errorDetails.modifiedBy}</span></Col>                
              </Row>
              <Row>
                
                <Col md={4}><label>Final Team</label><span>{errorDetails.finalTeam}</span></Col>
                <Col md={4}><label>Error Status</label><span style={this.colorChangeStatus(errorDetails.status)}>{siteinfo.ErrorStatus[errorDetails.status]}</span></Col>
                <Col md={4}></Col>
              </Row>
            </div>
            <div className='padding-left-right'>
            <div className= "interaction-details-screen">
            <div className="layout-2 layout-file">
            <Row>
             <Col md={12} sm={12}><label className="mb-2 text-left">Files</label>

                            <div className="fileLayout">

                              {this.state.viewAttachment.length ?

                                <div className={(utils.getSessionItem("role") === RoleConstant.CallCenterAdmin || utils.getSessionItem("role") === RoleConstant.TranslationVendor || utils.getSessionItem("role") === RoleConstant.QualityMonitor)
                                  ? "file" : "max-file"} >
                                  {
                                    this.state.viewAttachment.map((data, index) => {
                                      //console.log("sdfdsf", data);
                                      // let split=data.attachmentPath.split('.')[0];
                                      return (
                                        <div className="file-boxes" key={index}>
                                          <span className=" file-box">
                                            <p className="file-name">{data.attachmentName}</p>
                                            <span className="file-download"><img src="Images/ico-download.png" alt='Amgen' onClick={() => this.downloadErrorAttachment(data.attachmentPath,data.attachmentName)} />
                                            {((utils.getSessionItem('role') === data.role && utils.getSessionItem('role') === RoleConstant.CallCenterAdmin) || ((utils.getSessionItem('role') === data.role || data.role === null) && utils.getSessionItem('role') === RoleConstant.QualityMonitor)) &&
                                                <i className="fa fa-trash" aria-hidden="true" onClick={() => this.handleDeleteConfirmation(data.id)}></i>}
                                            </span>
                                          </span>
                                        </div>
                                      )
                                    })
                                  }
                                </div>

                                : <h5 className="upload mt-3 ml-2">No Attachments</h5>}

                              {
                                ((utils.getSessionItem("role") === RoleConstant.CallCenterAdmin) || (utils.getSessionItem("role") === RoleConstant.QualityMonitor)) &&
                                <div className="select" style={{ "float": "right" }}><FileUploader onDrop={this.onDrop} self={this} id={errorDetails.id} showDatasetNameInput={this.showDatasetNameInput} hideDatasetInput ={this.hideDatasetNameInput} maxFileSize="70"/></div>
                              }
                            </div>

                          </Col>   
            </Row>
            </div>
            </div>
              <Row>
                <Col md={12} sm={12}><label className="mb-2 text-left">Error Description</label>
                  <div className="mir-desc">
                    <span className='description'>{errorDetails.errorDescription}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12}><label className="mb-2 text-left">Comments
                {errorDetails.status !== '2' && role === RoleConstant.Admin &&
                    <img className="edit-case-owner" onClick={this.editComment} src="/Images/ico-edit.png" alt='Amgen' />}
                </label>
                
                 
              <div className='agent-notes'>{!Array.isArray(commentHist) && <span className='scroll'>{commentEditData}</span>}</div>
              {/* <div className='agent-notes'>{Array.isArray(commentHist) && this.state.comments.length==2 && <span className='scroll'>{this.state.comments[1]}</span>}</div> */}
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12}>
                  {commentHistory}
                  {Array.isArray(commentHist) && commentHist.length >=3 && <span onClick={this.showHistory} className="showCmt">Show Comment History</span>}
                </Col>
              </Row>
             
            </div>

          </div>
          <div className="row pull-right buttongroup"><div className="buttongroup col-xs-12 col-md-12 ">
            <Button variant="secondary" className="mt-4 clear follow-up-back mr-2" onClick={this.goBack}>
              <img src="Images/icon-arrow-left.png" className="btn-back" alt='Amgen' />Back</Button>
            {/* <Button type="submit" onClick={this.showFollowUp} className='mt-4 button-color follow-up-back'>Follow Up</Button> */}
            {role === RoleConstant.Admin && errorDetails.status !== '2' ?
              // <Button type="submit" className='mt-4 button-color follow-up-back'>
                <span className='a_followup'><a href="mailto:" className='followupLink mt-4 button-color follow-up-back'>Follow Up</a></span>
                // </Button> 
                : ''
                }
            {role === (RoleConstant.CallCenterAdmin || RoleConstant.Reviewer) && errorDetails.status !== '2' ?
              <span>{editErrorButton}</span> : ''}
            {role === RoleConstant.QualityMonitor && errorDetails.status !== '2' ? <span>{followUpButton}{editErrorButton}</span> : ''}
          </div>
          </div>
        </div>}
        <PopupModels showPopup={this.state.commentSuccess} iconFlag="success" message={MessageConstant.COMMENT_EDIT_SUCCESS} handleClosePopup={this.showErrorDetails1} />
        <Modal
          dialogClassName="rejectionCommentModal"
          show={this.state.editComment}
          onHide={this.hideEditComment.bind(this)}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header closeButton className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Add Comment
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Form.Group controlId="rejectionComment">
              <Form.Label>Comment</Form.Label>
              {/* {Array.isArray(this.state.comments) && <Form.Control as='textarea' name='comments' value={this.state.comments.length===2?this.state.comments[1].comments:this.state.comments[0].comments} onChange={this.handleCommentChange} />} */}
              { <Form.Control as='textarea' name='latestComments' value={this.state.latestComments} onChange={this.handleCommentChange} />}
              {/* {!Array.isArray(this.state.comments) && <Form.Control as='textarea' name='comments' value={this.state.comments} onChange={this.handleCommentChange} />} */}
              {/* {this.state.errorMessage.length > 0 && 
                <div className="error"><span>{this.state.errorMessage}</span></div>} */}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button className='commentUpdate' active onClick={this.updateComment}>
              Ok
          </Button>
          </Modal.Footer>
        </Modal>
        <Modal
        size="lg"
        show={this.state.showComment}
        onHide={this.hideHistory}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Comment History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollcommentpopup">
          <CommentHistory commentHist={commentHist}/>
        </Modal.Body>
      </Modal>

      <Modal dialogClassName="deletionModal" show={this.state.successDialog} aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'></Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Image src="/Images/ico-success.png" className='success-img'></Image>
            <div className='success-styles'>Success!</div>
            {this.state.successMessageContent}
          </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.closeDialogBox.bind(this)} className='user-deletion-cancel'>Ok</Button>
          </Modal.Footer>
        </Modal>

      <Modal show={this.state.showDatasetNameInput} onHide={this.hideDatasetNameInput} className="dataset-list-screen-dialog">
          <Modal.Header closeButton>
            <Modal.Title>Files</Modal.Title>
          </Modal.Header>

          <Modal.Body className="scrollFileNames">
           {fileNames}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleDatasetNameInputClose}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <ConfirmationModal showModal={this.state.deleteConfirmation}
        handleCloseModal={this.handleDeleteConfirmation.bind(this)}
        message={MessageConstant.DELETE_CONFIRMATION}
        navigate={()=>this.deleteAttachment(this.state.deleteId)} />

<PopupModels showPopup={this.state.deleteModel} iconFlag="success" message={MessageConstant.ATTACHMENT_DELETED} handleClosePopup={()=>this.handleDeletionClose(event)} />


      </span>
    )
  }
}
ErrorListViewDetails.propTypes = {
  hideViewDetails: PropTypes.func,
  errorDetails: PropTypes.JSON,
  error: PropTypes.string,
  location: PropTypes.object
}
export default withRouter(ErrorListViewDetails);

