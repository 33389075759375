import React, { Component } from 'react';
import { Router, ErrorHandler } from '../';
import './App.css';
import SpinnerLoader from '../Loader/SpinnerLoader';
import "core-js/es/array";
import "core-js/es/object";
import 'core-js/features/url-search-params';

class App extends Component {
  render() {
    return (
      <div>
        <ErrorHandler>
          <Router/>
        </ErrorHandler>
        <div id="loader" >
          <SpinnerLoader/>
          <div className="loader-container"></div>
        </div>
      </div>
    );
  }
}

export default App;
