import React, { Component } from 'react';
import './EvaluationReport.css';
import { Accordion, Card, Button, Form, Col, Image, Modal } from 'react-bootstrap';
import utils from '../../../utils';
import EndpointConstant from '../../../constants/EndpointConstant';
import PropTypes from 'prop-types';
import MultiSelect from "@khanacademy/react-multi-select";
import MessageConstant from '../../../constants/MessageConstant';
import * as uuid from 'uuid';
import { ProgressBar } from 'primereact/progressbar';
import ConfirmationModal from '../../PopupModels/ConfirmationModal';
import PopupModels from '../../PopupModels/PopupModels';
import ReportPage from '../ReportsManagement/Report';

class EvaluationReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teamNames: [],
            showpagination: false,
            currentTodos: [],
            emptyData: false,
            iconName: 'icon-drop-up',
            newIconName: 'icon-drop-up',
            teamName: '',
            dataSet: [],
            datasetId: '',
            datasetIds: '',
            searchdata: null,
            downloadType: false,
            extractSuccess: false,
            extractValue: 0,
            downloadRequest: "Processing",
            sId: '',
            downloadUrl: '',
            showDataSync: false,
            deleteConfirmation: false,
            extractValidation: false,
            extractErrMsg: '',
            modalPopUp: '',
        }
        this.iconChange = this.iconChange.bind(this);
        this.newIconChange = this.newIconChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnClear = this.handleOnClear.bind(this);
        this.getDatasetList = this.getDatasetList.bind(this);
        this.evaluateDataFromBioConnect = this.evaluateDataFromBioConnect.bind(this);
        this.downloadEvaluationReport = this.downloadEvaluationReport.bind(this);
        this.getEvaluationReport = this.getEvaluationReport.bind(this);
        this.evaluateGIMSData = this.evaluateGIMSData.bind(this);
        this.downloadEvaluationGIMSReport = this.downloadEvaluationGIMSReport.bind(this);
        this.getEvaluationGIMSReport = this.getEvaluationGIMSReport.bind(this);
        this.hideDataSyncOnCancel = this.hideDataSyncOnCancel.bind(this);
        this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this);
        this.handleExtractSuccessClose = this.handleExtractSuccessClose.bind(this);
        this.handleExtractValidationClose = this.handleExtractValidationClose.bind(this);
    }
    componentDidMount() {
        this.getDatasetList();
    }
    iconChange() {
        if (this.state.iconName === 'icon-drop-up') {
            this.setState({ iconName: 'icon-drop-down' })
        } else {
            this.setState({ iconName: 'icon-drop-up' })
        }
    }
    newIconChange() {
        if (this.state.newIconName === 'icon-drop-up') {
            this.setState({ newIconName: 'icon-drop-down' })
        } else {
            this.setState({ newIconName: 'icon-drop-up' })
        }
    }
    handleOnChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    handleOnClear() {
        this.setState({
            dataSet: [],
            datasetId: '',
            datasetIds: '',
        });
        this.getDatasetList();
    }
    hideDataSyncOnCancel() {
        //event.preventDefault();
        this.setState({
            showDataSync: false,
            extractValue: 0,
            // downloadRequest: 'Processing',
            downloadUrl: '',
            sId: '',
            deleteConfirmation: false
        });
        clearInterval(this.interval);
        this.interval = null;
    }

    handleDeleteConfirmation() {
        this.setState({ deleteConfirmation: !this.state.deleteConfirmation });
    }
    handleExtractSuccessClose() {
        this.setState({
            extractSuccess: false
        })
    }
    handleExtractValidationClose() {
        this.setState({
            extractValidation: false,
            extractErrMsg: ''
        })
    }
    getDatasetList() {
        let data = {};
        let self = this;
        utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
            result.datasets.sort(self.compare);
            self.setState({
                dataSet: result.datasets,
            });
        });
    }
    downloadEvaluationReport() {
        let data = {};
        data.datasetId = this.state.datasetId;
        this.setState({ downloadType: false });
        this.setState({ modalPopUp: "EvaluationReport" });
        data.sId = uuid.v4();
        this.setState({ sId: data.sId, downloadRequest: "Processing" });
        //console.log("downinteractions", data);
        utils.makeRequestInBackground(this, EndpointConstant.EVALUATION_REPORT, data, function (result) {
            //console.log(JSON.stringify(result), "abc");
            //window.open(result.url, 'Download');
        });
    }
    evaluateDataFromBioConnect(event) {
        //console.log("Evaluate Data From BioConnect");
        if (this.state.datasetId.length === 0) {
            //console.log("No dataseId");
            this.setState({
                extractValidation: true,
                extractErrMsg: 'Please select one dataset'
            });
        } else if (this.state.datasetId.length === 1) {
            event.preventDefault();
            clearInterval(this.interval);
            //console.log("this state", this.state);
            this.interval = null;
            this.setState({
                showDataSync: true
            });
            this.downloadEvaluationReport(event);
            this.interval = setInterval(() => {
                let val = this.state.extractValue;
                val += Math.floor(Math.random() * 10) + 1;
                if (val >= 100) {
                    val = 100;
                }

                this.setState({
                    extractValue: val
                });
                //console.log("downloadss")
                this.state.downloadRequest == "Processing" ? this.getEvaluationReport() : '';

                if (this.state.downloadRequest == "Completed") {
                    this.hideDataSyncOnCancel();
                    this.setState({
                        extractSuccess: true,
                        extractValue: 0,
                        extractResponseMsg: "Evaluation Report has been downloaded successfully",
                        extractResponseFlagIcon: 'success'
                    });
                    clearInterval(this.interval);
                    this.interval = null;
                }

            }, 8000);
        } else if (this.state.datasetId.length >= 2) {
            this.setState({
                extractValidation: true,
                extractErrMsg: 'Please select exactly one dataset'
            });
        }

    }
    getEvaluationReport() {
        //console.log("downn", this.state.sId);

        let data = {
            downloadRequest: this.state.downloadRequest,
            sId: this.state.sId
        };
        let self = this;
        utils.makeRequestInBackground(this, EndpointConstant.EVALUATION_REPORT_STATUS, data, function (result) {
            //console.log("downResulttt", result);

            if (self.state.downloadRequest == "Processing") {
                self.setState({
                    downloadRequest: result.downloadRequest,
                    downloadUrl: result.url,
                })
                result.url != '' ? window.open(result.url, 'Download') : '';

            }

        });

    }
    downloadEvaluationGIMSReport() {
        let data = {};
        data.datasetId = this.state.datasetIds;
        this.setState({ downloadType: false });
        this.setState({ modalPopUp: "GIMSDetailedReport" });
        data.sId = uuid.v4();
        this.setState({ sId: data.sId, downloadRequest: "Processing" });
        //console.log("downinteractions", data);
        utils.makeRequestInBackground(this, EndpointConstant.EVALUATION_GIMS_REPORT, data, function (result) {
            //console.log(JSON.stringify(result), "abc");
            //window.open(result.url, 'Download');
        });
    }
    evaluateGIMSData(event) {
        event.preventDefault();
        //console.log("Evaluate Data From BioConnect");
        if (this.state.datasetIds.length === 0) {
            //console.log("No dataseId");
            this.setState({
                extractValidation: true,
                extractErrMsg: 'Please select at least one dataset'
            });
        } else {
            clearInterval(this.interval);
            //console.log("this state", this.state);
            this.interval = null;
            this.setState({
                showDataSync: true
            });
            this.downloadEvaluationGIMSReport(event);
            this.interval = setInterval(() => {
                let val = this.state.extractValue;
                val += Math.floor(Math.random() * 10) + 1;
                if (val >= 100) {
                    val = 100;
                }

                this.setState({
                    extractValue: val
                });
                //console.log("downloadss")
                this.state.downloadRequest == "Processing" ? this.getEvaluationGIMSReport() : '';

                if (this.state.downloadRequest == "Completed") {
                    this.hideDataSyncOnCancel();
                    this.setState({
                        extractSuccess: true,
                        extractValue: 0,
                        extractResponseMsg: "GIMS Detailed Report has been downloaded successfully",
                        extractResponseFlagIcon: 'success'
                    });
                    clearInterval(this.interval);
                    this.interval = null;
                }

            }, 8000);
        }

    }
    getEvaluationGIMSReport() {
        //console.log("downn", this.state.sId);

        let data = {
            downloadRequest: this.state.downloadRequest,
            sId: this.state.sId
        };
        let self = this;
        utils.makeRequestInBackground(this, EndpointConstant.EVALUATION_GIMS_REPORT_STATUS, data, function (result) {
            //console.log("downResulttt", result);

            if (self.state.downloadRequest == "Processing") {
                self.setState({
                    downloadRequest: result.downloadRequest,
                    downloadUrl: result.url,
                })
                result.url != '' ? window.open(result.url, 'Download') : '';
            }
        });
    }
    colorChangeStatus(status) {
        const styles = {
            Approved: '#0F9D58',
            Rejected: '#EA4335',
            Processing: '#F4B400'
        }
        switch (status) {
            case ('Approved'):
                return { color: styles.Approved };
            case ('Rejected'):
                return { color: styles.Rejected };
            default:
                return {};
        }
    }
    compareDataset(a, b) {
        const valueA = a.label.toUpperCase();
        const valueB = b.label.toUpperCase();

        let comparison = 0;
        if (valueA > valueB) {
            comparison = 1;
        } else if (valueA < valueB) {
            comparison = -1;
        }
        return comparison;
    }

    render() {
        const { dataSet } = this.state;
        let filterDataSet = [];
        filterDataSet = dataSet.filter(data => data.status === 'Complete');
        let datasetList = filterDataSet.map((dataset, i) => {
            return ({ id: dataset.id, label: dataset.datasetName && dataset.datasetName.split('.')[0], value: dataset.id });
        });
        datasetList.sort(this.compareDataset);
        let newDatasetList = filterDataSet.map((dataset, i) => {
            return (
                <option key={dataset.id} value={dataset.id}>{dataset.datasetName.split('.')[0]}</option>
            );
        });
        const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));

        return (
            <div className='report-padding'>
                <Accordion defaultActiveKey='0' alwaysOpen className='cardPadding' >
                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
                            <span className="search-title">DOWNLOAD EVALUATION REPORT</span> <Image src="/Images/ico-drop-up.png" className={this.state.iconName} aria-hidden="true"></Image>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className='cardBodyStyle'>
                                <Form className="searchForm">
                                    <Form.Row style={{ width: "564px" }}>
                                        <Col>
                                            <Form.Label>Dataset</Form.Label>
                                            <Form.Control as='select' name='role' id="datasetDropDnForEvlnRept"
                                                className="dataset-dropdown"
                                                onChange={event => {
                                                    let selectedId = event.target.value;
                                                    //console.log("Selected Values", selectedId);
                                                    this.setState({ datasetId: [selectedId] });
                                                    return null;
                                                }}
                                                value={this.state.datasetId}
                                            >
                                                <option style={{ marginTop: "10px" }} value="">--- Select Dataset ---</option>
                                                {newDatasetList}
                                            </Form.Control>
                                        </Col>
                                        <Button variant="secondary" className='scorecardSearch button-color userSearch datasetMargin dnldBtnMargin' onClick={this.evaluateDataFromBioConnect}>Download</Button>
                                        <Button variant="secondary" className='clear userSearch' onClick={this.handleOnClear}>Clear</Button>
                                    </Form.Row>
                                </Form></Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion defaultActiveKey="0" className='cardPadding' >
                    <Card >
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.newIconChange} className='cardBodyStyle'>
                            <span className="search-title">DOWNLOAD GIMS DETAILED REPORT</span> <Image src="/Images/ico-drop-up.png" className={this.state.newIconName} aria-hidden="true"></Image>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className='cardBodyStyle'>
                                <Form className="searchForm" id="DetailedReportDatsets">
                                    <Form.Row style={{ width: "564px" }}>
                                        <Col>
                                            <Form.Label>Dataset</Form.Label>
                                            <MultiSelect
                                                options={datasetList}
                                                selected={this.state.datasetIds}
                                                disableSearch={false}
                                                hasSelectAll={true}
                                                onSelectedChanged={selected => {
                                                    //console.log("selected values", selected);
                                                    this.setState({ datasetIds: selected });
                                                    return null;
                                                }}
                                                overrideStrings={{
                                                    selectSomeItems: "--Select Dataset--",
                                                    allItemsAreSelected: "All are Selected",
                                                    selectAll: "Select All"
                                                }}
                                            />
                                        </Col>
                                        <Button variant="secondary" className='scorecardSearch button-color userSearch datasetMargin' onClick={this.evaluateGIMSData}>Download</Button>
                                        <Button variant="secondary" className='clear userSearch' onClick={this.handleOnClear}>Clear</Button>
                                    </Form.Row>
                                </Form></Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <ReportPage maxFileSize="70"/>
                <Modal show={this.state.showDataSync} onHide={this.hideDataSyncOnCancel} className="dataset-extract-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modalPopUp == "EvaluationReport"? "DOWNLOADING EVALUATION REPORT" : "DOWNLOADING GIMS DETAILED REPORT"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProgressBar style={{ 'height': '10px', 'borderRadius': '5px', 'border': '1px solid #0063C3', 'background': '#F2F2F2' }} showValue={false} value={this.state.extractValue} />
                        <p className="progressMsg">Downloading is in progress...</p>
                        <p className="progressMsg">{this.state.extractValue}% completed</p>
                    </Modal.Body>
                    <Modal.Footer className="confirm-popup-header">
                        <Button variant="primary" className="clear" onClick={this.handleDeleteConfirmation}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                <PopupModels showPopup={this.state.extractSuccess} iconFlag={this.state.extractResponseFlagIcon} message={this.state.extractResponseMsg} handleClosePopup={this.handleExtractSuccessClose.bind(this)} />

                <ConfirmationModal showModal={this.state.deleteConfirmation}
                    handleCloseModal={this.handleDeleteConfirmation.bind(this)}
                    message={MessageConstant.CANCEL_DOWNLOAD}
                    navigate={this.hideDataSyncOnCancel} />
                <PopupModels showPopup={this.state.extractValidation} iconFlag="error" message={this.state.extractErrMsg} handleClosePopup={this.handleExtractValidationClose.bind(this)} />
            </div>
        )
    }
}
EvaluationReport.propTypes = {
    // onDownloadFilter: PropTypes.func,
}

export default EvaluationReport;