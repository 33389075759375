import React, { Component } from 'react';
import './ScoreCard.css';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
import { Accordion, Card, Button, Modal, Form, Col, Alert, Image } from 'react-bootstrap';
import PopupModels from './../PopupModels/PopupModels';
import ConfirmationModal from './../PopupModels/ConfirmationModal'
import MessageConstant from './../../constants/MessageConstant';
// import CommonService from '../../commonService/CommonService';
import Multiselect from "@khanacademy/react-multi-select";
import PropTypes from 'prop-types';
import RoleConstant from '../../constants/RoleConstant';
import { Dropdown } from 'primereact/dropdown';
class PerformSampling extends Component {

  constructor(props) {
    super(props);
    this.state = {
      iconName: 'icon-drop-up',
      selectedDataset: [],
      dataSet: [],
      ifrOptions: [],
      caseType: [],
      caseTypesCount: '',
      interactionId: '',
      channel: this.props.getsample,
      percent: 3,
      createSample: false,
      sampleSets: [],
      qcName: '',
      // allScorecards: [],
      scorecards: [],
      scorecardsWithCasetypes: [],
      sampledScorecards: [],
      caseTypeSc: false,
      errors: {
        qcName: '',
      },
      noSampling: false,
      deleteConfirmation: false,
      deleteModel: false,
      deletefail: false,
      newScorecard: [],
    }

    this.iconChange = this.iconChange.bind(this);
    this.handleDatasetChanged = this.handleDatasetChanged.bind(this);
    this.handleSelectedChanged = this.handleSelectedChanged.bind(this);
    this.createSample = this.createSample.bind(this);
    this.getDatasetList = this.getDatasetList.bind(this);
    this.getScoreCardList = this.getScoreCardList.bind(this);
    this.handleQCNameChange = this.handleQCNameChange.bind(this);
    this.handleDatasetChange = this.handleDatasetChange.bind(this);
    this.loadCaseTypes = this.loadCaseTypes.bind(this);
    this.createSampleSet = this.createSampleSet.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.loadSampleSets = this.loadSampleSets.bind(this);
    this.loadSampledScorecards = this.loadSampledScorecards.bind(this);
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this);
    this.handleDeletionClose=this.handleDeletionClose.bind(this);
    this.sampleOption = this.sampleOption.bind(this);
    this.onSampleChange = this.onSampleChange.bind(this);
    this.handleSampleDelete = this.handleSampleDelete.bind(this);
    }
  componentDidMount() {
    // this.getScoreCardList(false);
    if (utils.getSessionItem("role") === RoleConstant.Admin) {
      this.getScoreCardList(false);
    }
    if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
      this.getScoreCardList(false, 'stage1');
    }
    this.getDatasetList();
    this.loadCaseTypes('All');
    this.loadSampleSets();
  }
  handleOnChangeInput(e){
    const {value} = e.target;
     if(value >= 0 && value <=100) {
      this.setState({
        percent:e.target.value
       });
    } 
  }
  compare(a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    return 0;
  }
  compareDataset(a, b) {
    const valueA = a.label.toUpperCase();
    const valueB = b.label.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  } 
  getDatasetList() {
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
      let filterDataSet = [];
      filterDataSet = result.datasets.filter(data => data.status === 'Complete');
      filterDataSet.sort(self.compare);
      // console.log(result);
      const dataSet = [];
      let selectedDataset = [];
      let selectedDatasetCopy = [];
      
      selectedDataset = filterDataSet.map(ds => ds.id);
      selectedDatasetCopy = selectedDataset.slice(0);
      // const spaces = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
      filterDataSet.forEach(function (ds) {       
        dataSet.push({ label: ds.datasetName && ds.datasetName.split('.')[0], value: ds.id })      
      });
      //console.log(dataSet);
      dataSet.sort(self.compareDataset);
      self.setState({
        dataSet: dataSet,
        selectedDataset: selectedDataset,
        selectedDatasetCopy: selectedDatasetCopy,
      })
    });
  }
 
  getScoreCardList(flag,stage) {
    let data = {
      datasetId: 'All',
      stage:stage
    };
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_SCORECARD_FROM_JSON, data, async function (result) {
      if (result.error === false) {
        utils.showLoader();
        await fetch(result.scorecards, {
          mode: 'cors',
          headers: {
              'Access-Control-Allow-Origin':'*'
          },
          method: 'GET',
          body: null,
        })
        .then((response) => response.json())
          .then((s3ResponseJson) => {
            utils.hideLoader();
            const scorecards = s3ResponseJson;
            // const nonSampledScorecards = scorecards.filter(function(sc) {
            //   return (sc.sampleSet == null || sc.sampleSet == "");
            // });
            self.loading = false;
            let news=self.state.scorecards.length>0?self.state.scorecards.concat(scorecards):scorecards;
            let newscorecards = news.reduce((acc, current) => {
              const x = acc.find(item => item.id === current.id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            self.setState({
              // allScorecards: scorecards,
              // scorecards: nonSampledScorecards }, () => {
              scorecards: newscorecards,
              newScorecard: newscorecards,
            }, () => {
              if (flag) {
                self.props.getScorecardList(newscorecards);
              }
            });            
          })
        .catch((error) => {
          console.error(error);
          
        });        
      }
    })
  }
  loadCaseTypes(datasetId) {
    let datasetDetails = {
      datasetId: datasetId,
    };
    let self = this;
    if (datasetId.length > 0) {
      utils.makeRequest(this, EndpointConstant.GET_CASE_TYPES_FOR_DATASET, datasetDetails, function (result) {
        const caseTypes = [];
        let caseTypesCount = 0;
        // const spaces = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
        result.caseTypesAndCount.forEach(function (val) {
          caseTypes.push({ label: val.casetype + ' - ' + val.count, value: { casetype: val.casetype, count: val.count } });
          caseTypesCount += val.count;
        });
        //console.log(caseTypes);
        self.setState({
          ifrOptions: caseTypes,
          caseTypesCount: caseTypesCount,
        })
      });
    }
    else {
      self.setState({
        ifrOptions: [],
      })
    }

  }
  loadSampleSets() {
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_SAMPLE_SETS, data, function (result) {
      // console.log(result);
      self.setState({
        sampleSets: result.sampleSets,
      })
    });
  }
  loadSampledScorecards(sampleSetName) {
    let data = {};
    let self = this;
    if (sampleSetName) {
      data.sampleSetName = sampleSetName.replace(/[,]+/g, '|');
      utils.makeRequest(this, EndpointConstant.SCORECARDS_FROM_SAMPLESET, data, function (result) {
       //console.log("samplingggresult",result);
        self.setState({
          scorecards: result.scorecards,
          sampledStateCheck: result.scorecards,
          selectedDataset: [],
          ifrOptions: [],
          caseType: [],
          caseTypeSc: false,
          percent: 3,
        }, () => { self.props.getScorecardList(result.scorecards); })
      });
    } else {
      self.setState({
        selectedDataset: self.state.selectedDatasetCopy,
      })
      // self.props.getScorecardList(self.state.allScorecards);
      self.setState({scorecards:[]})
      self.getScoreCardList(true);
      self.loadCaseTypes('All');
    }
  }
  iconChange() {
    if (this.state.iconName === 'icon-drop-up') {
      this.setState({ iconName: 'icon-drop-down' })
    } else {
      this.setState({ iconName: 'icon-drop-up' })
    }
  }
  handleSearch() {

  }
  percentExtract(data) {
    // const { percent } = this.state;
    switch (data) {
      case 'minus':
        this.setState((p) => {
          //console.log(p);
          let percent = p.percent - 1;
          if (percent <= 0) return p.percent = 1;
          else return p.percent = percent;
        }, () => this.handleSampleSizeChange(this.state.percent));
        break;
      case 'plus':
        this.setState((p) => {
          //console.log(p);
          let percent = ++p.percent;
          if (percent > 100) return p.percent = 100;
          else return p.percent = percent;
        }, () => this.handleSampleSizeChange(this.state.percent));
        break;
      default:
        break;
    }
  }
  handleDatasetChanged(selected) {
    let self = this;
    // console.log(selected);
    self.setState({ selectedDataset: selected, caseType: [], caseTypeSC: false, channel: '', });
    self.loadCaseTypes(selected);
    self.handleDatasetChange(selected);
  }
  handleSelectedChanged(selected) {
    //console.log("handleSelectedChanged ++++ " + JSON.stringify(selected));
    let selectedCopy = selected.slice();
    selectedCopy = selectedCopy.map(function (sCopy) {
      return sCopy.casetype;
    });
    let self = this;
    //console.log("selected ++++ " + JSON.stringify(selected));
    self.setState({ caseType: selected });
    if (selected.length === 0) {
      self.setState({
        scorecardsWithCasetypes: [], caseTypeSc: false,
      });
    }
    else {
      let scorecardsWithCasetypes = [];
      selectedCopy.forEach(function (caseType) {
        let scWithCasetype = [];
        //console.log('self.state.scorecards',self.state.scorecards)
        //console.log(utils.getSessionItem("role")===RoleConstant.QualityMonitor)
        self.state.scorecards.forEach(function (sc) {
          if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
            if (!sc.QualitysampleSet && caseType === sc.caseType && sc.createdBy!==utils.getSessionItem("userId") ) {
              scWithCasetype.push(sc);
            }
          }else if (!sc.sampleSet && caseType === sc.caseType && sc.assignedTo===RoleConstant.Admin) {
            scWithCasetype.push(sc);
          }
        })
        // const end = Math.ceil((self.state.percent / 100) * scWithCasetype.length);
        // scorecardsWithCasetypes.push(...(scWithCasetype.slice(0, end)));
      })

      // scorecardsWithCasetypes = this.state.scorecards.filter(function(sc) {
      //   return (!sc.sampleSet && selectedCopy.includes(sc.caseType)) ? true : false; 
      // })
      if (scorecardsWithCasetypes.length > 0) {
        self.setState({
          scorecardsWithCasetypes: scorecardsWithCasetypes,
          caseTypeSc: true,
        });
      }
      else {
        self.setState({
          scorecardsWithCasetypes: scorecardsWithCasetypes,
          caseTypeSc: true,
        });
      }
    }
  }
  createSample() {
    // const { caseTypeSc, scorecardsWithCasetypes, scorecards, percent } = this.state;
    // const end = (caseTypeSc) ? Math.ceil((percent/100)*scorecardsWithCasetypes.length) : Math.ceil((percent/100)*scorecards.length);
    // const sampledScorecards = (caseTypeSc) ? scorecardsWithCasetypes.slice(0, end) : scorecards.slice(0, end);
    // if (sampledScorecards.length > 0) {
    this.setState({ createSample: !this.state.createSample, qcName: '', errors: { qcName: '' } })
    // } else {
    //   this.setState({noSampling: true});
    // }
  }
  onKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\||1|2|3|4|5|6|7|8|9|0/.test(keyValue)) {
      event.preventDefault();
    }
  }
  handleQCNameChange(event) {
    this.setState({
      qcName: event.target.value,
    });
    let errors = this.state.errors;
    errors.qcName = event.target.value === '' ? 'Please enter a name for the sample set' : '';
  }
  handleDatasetChange(datasetId) {
    let self = this;
    self.loadCaseTypes(datasetId);
    let datasetDetails = {};
    datasetDetails.datasetId = datasetId;
    if (datasetId.length > 0) {
      utils.makeRequest(this, EndpointConstant.GET_SCORECARD_FROM_JSON, datasetDetails, async function (result) {
        utils.showLoader();
        await fetch(result.scorecards, {
          mode: 'cors',
          headers: {
              'Access-Control-Allow-Origin':'*'
          },
          method: 'GET',
          body: null,
        })
        .then((response) => response.json())
          .then((s3ResponseJson) => {
            utils.hideLoader();
            const scorecards = s3ResponseJson ? s3ResponseJson : [];
            // self.loading = false;
            self.setState({
              scorecards: scorecards,
            });
            
          })
        .catch((error) => {
          console.error(error);
          
        })        
      })
    }
    else {
      self.setState({
        scorecards: [],
      });
    }
  }
  handleSampleSizeChange(sampleSize) {
    let self = this;
    const { caseTypeSc, scorecardsWithCasetypes, scorecards, caseType } = self.state;
    const end = (caseTypeSc) ? Math.ceil((sampleSize / 100) * scorecardsWithCasetypes.length) : Math.ceil((sampleSize / 100) * scorecards.length);
    const sampledScorecards = (caseTypeSc) ? scorecardsWithCasetypes.slice(0, end) : scorecards.slice(0, end);
    self.setState({
      sampledScorecards: sampledScorecards,
    });
    if (caseTypeSc) {
      self.handleSelectedChanged(caseType);
    }
  }
  createSampleSet() {
    let self = this;
    const { caseTypeSc, scorecardsWithCasetypes, scorecards, caseType } = self.state;
    let selectedCopy = caseType.slice();
    selectedCopy = selectedCopy.map(function (sCopy) {
      return sCopy.casetype;
    });
    if (caseType.length === 0) {
      self.setState({
        scorecardsWithCasetypes: [], caseTypeSc: false,
      });
    }
    else {
      // let scorecardsWithCasetypes = [];
      selectedCopy.forEach(function (typeCase) {
        let scWithCasetype = [];
        //console.log('self.state.scorecards',self.state.scorecards)
        //console.log(utils.getSessionItem("role")===RoleConstant.QualityMonitor)
        self.state.scorecards.forEach(function (sc) {
          if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
            if (!sc.QualitysampleSet && typeCase === sc.caseType && sc.createdBy!==utils.getSessionItem("userId") ) {
              scWithCasetype.push(sc);
            }
          }else if (!sc.sampleSet && typeCase === sc.caseType && sc.assignedTo===RoleConstant.Admin) {
            scWithCasetype.push(sc);
          }
        })
        const end = Math.ceil((self.state.percent / 100) * scWithCasetype.length);
        scorecardsWithCasetypes.push(...(scWithCasetype.slice(0, end)));
      })

      // scorecardsWithCasetypes = this.state.scorecards.filter(function(sc) {
      //   return (!sc.sampleSet && selectedCopy.includes(sc.caseType)) ? true : false; 
      // })
      if (scorecardsWithCasetypes.length > 0) {
        self.setState({
          scorecardsWithCasetypes: scorecardsWithCasetypes,
          caseTypeSc: true,
        });
      }

      else {
        self.setState({
          scorecardsWithCasetypes: scorecardsWithCasetypes,
          caseTypeSc: true,
        });
      }

    }
    // const end = (caseTypeSc) ? Math.ceil((percent/100)*scorecardsWithCasetypes.length) : Math.ceil((percent/100)*scorecards.length);
    // const sampledScorecards = (caseTypeSc) ? scorecardsWithCasetypes.slice(0, end) : scorecards.slice(0, end);
    let data = {};
    // data.scorecardIds = sampledScorecards.map(sc => sc.id);

    //adding other subcases which belong to the same mastercase
    scorecardsWithCasetypes.forEach(function (scWithCasetype) {
      scorecards.forEach(function (sc) {
        if (utils.getSessionItem("role")===RoleConstant.Admin && sc.assignedTo===RoleConstant.Admin && !sc.sampleSet && scWithCasetype.subcaseNumber !== sc.subcaseNumber && scWithCasetype.subcaseNumber.split('-').slice(0, 2).join('-') === sc.subcaseNumber.split('-').slice(0, 2).join('-')) {
          scorecardsWithCasetypes.push(sc);
        }
        else if(utils.getSessionItem("role")===RoleConstant.QualityMonitor && sc.assignedTo===RoleConstant.QualityMonitor && !sc.QualitysampleSet && scWithCasetype.subcaseNumber !== sc.subcaseNumber && scWithCasetype.subcaseNumber.split('-').slice(0, 2).join('-') === sc.subcaseNumber.split('-').slice(0, 2).join('-')){
          scorecardsWithCasetypes.push(sc);
        }
      });
    });
    data.scorecardIds = scorecardsWithCasetypes.map(sc => sc.id);
    if (self.state.selectedDataset && self.state.selectedDataset.length === 0) {
      self.setState({
        errors: { qcName: 'Please select a dataset' },
      });
    }
    else if (!caseTypeSc) {
      self.setState({
        errors: { qcName: 'Please select casetype to proceed' },
      });
    }
    else if (self.state.sampleSets.includes(self.state.qcName.trim())) {
      self.setState({
        errors: { qcName: 'Sample set name is already taken. Please enter a different name' },
      });
    }
    else if (self.state.qcName !== '') {
      data.sampleSetName = self.state.qcName;
      utils.makeRequest(this, EndpointConstant.CREATE_SAMPLE, data, function (result) {
        //console.log(result);
        const sampleSets = result.QCSampleSets;
        const createdSample = result.createdSample;
        // self.loading = false;
        self.getScoreCardList(false);
        self.setState({
          sampleSets: sampleSets
        }, () => {
          self.setState({createSample: !self.state.createSample, channel: {name:createdSample,value:createdSample}},
            ()=>{self.loadSampledScorecards(self.state.channel.name);self.props.getSampleName({name:createdSample,value:createdSample}); })
        });
      })
    }
    else {
      self.setState({
        errors: { qcName: 'Please enter a name for the sample set' },
      });
    }
  }
  handleOnClear(flag) {
    let self = this;
    self.setState({
      // iconName: 'fa fa-caret-up iconstyles-case',
      selectedDataset: self.state.selectedDatasetCopy,
      caseType: [],
      percent: 3,
      qcName: '',
      channel: '',
    }, () => {
      if (flag) {
        self.props.getSampleName('')
         if(!self.props.hasOwnProperty('getResult') && self.props.hasOwnProperty('getsample') && self.props.getsample=='' ) {
          self.getScoreCardList(true);
          // if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
          // self.getScoreCardList(true,'stage1');
          // }
        }  else if(self.props.hasOwnProperty('getResult') && self.props.hasOwnProperty('getsample') && Array.isArray(self.props.getsample) && self.props.getsample.length==0 ) {
          // self.getScoreCardList(true);
          if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
          self.getScoreCardList(true,'stage1');
          // self.setState({scorecards:[]});
          } else {
            self.getScoreCardList(true);
          }
          // self.setState({scorecards:[]});
          
        } else {
          // self.getScoreCardList(true);
           if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
            // self.setState({ scorecards: [] });
             self.getScoreCardList(true, 'stage1');
           } else {
             self.getScoreCardList(true);
           }
           // self.setState({scorecards:[]});
        }
        
      }
      // self.props.getScorecardList(self.state.allScorecards);
      self.loadCaseTypes('All');
      self.loadSampleSets();
    })
  }
  handleErrorClose() {
    this.setState({ noSampling: false });
  }

  onSampleChange(e) {
    e.preventDefault();
   this.setState({ channel : e.target.value});

   this.props.getSampleName(e.target.value); 
   this.loadSampledScorecards(e.target.value.name);
   //console.log("channell",this.state.channel)
  }

  sampleOption(option) {
    return (
      <div className="country-item">
        <div>{option.name}</div>
        <span className="delOption" onClick={e => this.handleDeleteConfirmation(e,option.name)}>
         <Image src="/Images/ico-delete.png" className='image-padding'></Image> 
      
        </span>
      </div>
    );
  }

  handleDeleteConfirmation(e,name) {
    e.stopPropagation();
    this.setState({ deleteConfirmation: !this.state.deleteConfirmation, delOption:name })
  }

  handleSampleDelete(){
    let self = this
    let data = {
      sampleSetName : this.state.delOption,
      deleteSample : true
    }
    let currentScorecards = this.state.newScorecard;
    let deletedSampleName = this.state.delOption;
    let updatedScorecards = [];
    if (utils.getSessionItem("role") === RoleConstant.Admin) {
      currentScorecards.forEach((score) => {
        if (score.sampleSet === deletedSampleName) {
          let newScore = { ...score, sampleSet: "" }
          updatedScorecards.push(newScore);
        } else {
          updatedScorecards.push(score);
        }
      })
    } else if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
      currentScorecards.forEach((score) => {
        if (score.QualitysampleSet === deletedSampleName) {
          let newScore = { ...score, QualitysampleSet: "" }
          updatedScorecards.push(newScore);
        } else {
          updatedScorecards.push(score);
        }
      })
    }
    
    data.sampleSetName = this.state.delOption.replace(/[,]+/g, '|');
    utils.makeRequest(this, EndpointConstant.SCORECARDS_FROM_SAMPLESET, data, function (result) {
      if (result.error === false) {
      //console.log('sampleresult',result);
      self.setState({ deleteConfirmation:false, deleteModel:true, scorecards: updatedScorecards, newScorecard: updatedScorecards},()=>{
        // self.getScoreCardList(true);
        // self.loadCaseTypes('All');
        // self.loadSampleSets();
        self.handleOnClear(true);
      });
      
      }
      else{
        self.setState({ deleteConfirmation : false, deletefail: true })
      }
    }
    )
  }

  handleDeletionClose(event) {
    event.preventDefault();
    this.setState({deleteModel: false});
  }

  handleClose() {
    this.setState({ deletefail: false })
  }

  render() {
    const { dataSet, ifrOptions, caseType, channel, createSample, errors, sampleSets, caseTypeSc, caseTypesCount, percent } = this.state;
    // const ifrOptions = CommonService.ifrType.map((status, i) => {
    //   return (
    //     <option key={i} value={status.value}>{status.value}</option>
    //   )
    // })
    // console.log('ds:'+JSON.stringify(dataSet));
    // const datasetList = dataSet.map((dataset, index) => {
    //   return (
    //     <option key={index} value={dataset.id}>{dataset.datasetName && dataset.datasetName.split('.')[0]}</option>  
    //   )
    // });
    // console.log(dataSet);
    // const ifrOptions = [];
    // CommonService.ifrType.forEach((ifrOption) => {
    //   ifrOptions.push({
    //     label: ifrOption.value, value: ifrOption.value  
    //   });
    // })
    // const channelOptions = CommonService.channel.map((status, i) => {
    //   return (
    //     <option key={i} value={status.value}>{status.value}</option>
    //   )
    // })

    const sampleSetOptions= sampleSets && sampleSets.length>0 && sampleSets.sort().map((sampleSet) => {
      return ({name:sampleSet, value:sampleSet });
      
    });
    // const sampleSetOptions = sampleSets && sampleSets.length>0 && sampleSets.sort().map((sampleSet, i) => {
    //   return (
        
    //      <option key={i} value={sampleSet}>{sampleSet} </option>     
    //   )
    // });

    const caseTypesDetails = caseType.sort().map((typeCase, index) => {
      return (
        <span key={index}>{typeCase.casetype + ': ' + Math.ceil((percent / 100) * typeCase.count) + ' '}</span>
      )
    })
    let caseTypePercentCount = 0;
    caseType.forEach(function (caseTypePercent) {
      // caseTypePercentCount+=caseType.count/10;
      caseTypePercentCount += Math.ceil((percent / 100) * caseTypePercent.count);
    });
    return (
      <div>
        <Accordion defaultActiveKey="0" className='cardPadding performSampling'>
          <Card >
            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
              <span className="search-title">PERFORM SAMPLING</span><Image src="/Images/ico-drop-up.png" className={this.state.iconName}></Image>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className='cardBodyStyle'>
                <Form className="searchForm">
                  <Form.Row>
                    <Col md='2'>
                      <Form.Label>Data Set</Form.Label>
                      {/* <Form.Control as='select' name='selectedDataset' value={selectedDataset} onChange={e => { this.setState({ selectedDataset: e.target.value }, () => { this.loadCaseTypes(e.target.value); this.handleDatasetChange(e.target.value); }) } }> */}
                      {/* <Form.Control as='select' name='selectedDataset' value={selectedDataset} onChange={e => { this.setState({ selectedDataset: e.target.value }); this.loadCaseTypes(e.target.value); this.handleDatasetChange(e.target.value); } }>
                      <option value="All">--- ALL ---</option>
                      {datasetList}
                    </Form.Control> */}
                      <Multiselect
                        options={dataSet}
                        onSelectedChanged={this.handleDatasetChanged}
                        selected={this.state.selectedDataset}
                        disableSearch={false}
                        hasSelectAll={true}
                        overrideStrings={{
                          selectSomeItems: "-- Select --",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                    </Col>
                    <Col md='3'>
                      <Form.Label>Case Type</Form.Label>
                      {/* <Form.Control as='select' name='caseType' value={caseType} onChange={e => { this.setState({ caseType: e.target.value }) }}>
                      <option value="">--- ALL ---</option>
                      {ifrOptions}
                    </Form.Control> */}
                      <Multiselect
                        options={ifrOptions}
                        onSelectedChanged={this.handleSelectedChanged}
                        selected={this.state.caseType}
                        disableSearch={true}
                        hasSelectAll={false}
                        overrideStrings={{
                          selectSomeItems: "-- Select Case Type --",
                          allItemsAreSelected: "All Casetypes",
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Sample Size</Form.Label>
                      <div className="plus-min score-plus-minus">
                        <span className="plus-min-box minus" onClick={() => this.percentExtract("minus")}>&#8722;</span>
                        <span className='plus-min-box percent'>
                    <input type='text' className='scorecard_percentStyle' value={this.state.percent} onChange={this.handleOnChangeInput}/>
                    %</span>
                        {/* <span className="plus-min-box percent" value={this.state.percent} onChange={this.handleOnChangeInput}></span> */}
                        <span className="plus-min-box plus" onClick={() => this.percentExtract("plus")}>&#43;</span>
                      </div>
                    </Col>
                    <Col md='4'>
                      {/* <Form.Group as={Col} xs='6' lg='6' md="6"> */}
                      <Form.Label></Form.Label>
                      <Alert className='alert-style'>
                        <Image src="/Images/ico-info-score-card.png" />
                        {/* <span>{caseTypeSc && caseTypePercentCount > 0 && caseTypePercentCount.toFixed(2) + '/100' }</span> */}
                        <span>{(caseTypeSc && caseTypePercentCount > 0) && (caseTypePercentCount) + '/' + (caseTypesCount)}</span>
                        <span className='right-align'>{caseTypeSc && caseTypesDetails}</span>
                      </Alert>
                    </Col>
                    <Col>
                      <Form.Label>Saved QC Result</Form.Label>
                      <Dropdown  options={sampleSetOptions}
                  onChange={this.onSampleChange} optionLabel= "value" 
                  value={channel} placeholder="-- Select --"
                  itemTemplate={this.sampleOption}
                  />
                  

                      {/* <Form.Control as='select' name='channel' value={channel} onChange={e => { this.setState({ channel: e.target.value }); this.props.getSampleName(e.target.value); this.loadSampledScorecards(e.target.value); }}>
                        <option value="">-- Select saved result--</option>
                        {sampleSetOptions}
                      </Form.Control> */}
                    </Col>
                  </Form.Row>

                  <Button variant="secondary" className='clear' onClick={() => this.handleOnClear(true)}>Clear</Button>
                  <Button variant="secondary" className='scorecardSearch button-color createSample' onClick={this.createSample}>Create Sample</Button>
                  {/* {channel.length !== 0? <Button variant="secondary" className='scorecardSearch button-color createSample' onClick={this.handleDeleteConfirmation} >Delete Sample</Button> : ''} */}
                </Form></Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Modal show={createSample} onHide={this.createSample}>
          <Modal.Header closeButton className='modalHeader pb-2 pt-2 '>
            <Modal.Title>SAVE QC RESULT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
              <Form.Group as={Col} controlId="qcName">
                <Form.Label className='align-lable'>Name of QC Result</Form.Label>
                {/* <Form.Control type='text' name='qcName' value={this.state.qcName} onKeyPress={this.onKeyPress}
                onChange={this.handleQCNameChange} /> */}
                <Form.Control type='text' name='qcName' value={this.state.qcName}
                  onChange={this.handleQCNameChange} />
                {errors.qcName && errors.qcName.length > 0 &&
                  <div className="error"><span>{errors.qcName}</span></div>}
              </Form.Group>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className='button-color' onClick={this.createSampleSet}>
              Save
      </Button>
          </Modal.Footer>
        </Modal>
        <ConfirmationModal showModal={this.state.deleteConfirmation}
        handleCloseModal={this.handleDeleteConfirmation}
        message={MessageConstant.CONFIRM_DELETE_SAMPLE(this.state.delOption)}
        navigate={this.handleSampleDelete} />
        
        <PopupModels showPopup={this.state.deleteModel} iconFlag="success" message={MessageConstant.DELETE_SAMPLE(this.state.delOption)} handleClosePopup={()=>this.handleDeletionClose(event)} />
        <PopupModels showPopup={this.state.deletefail} iconFlag="error" message={MessageConstant.DELETE_SAMPLE_FAIL} handleClosePopup={this.handleClose.bind(this)} />
        <PopupModels showPopup={this.state.noSampling} iconFlag="error" message={MessageConstant.NO_SAMPLING} handleClosePopup={this.handleErrorClose.bind(this)} />
      </div>
    )
  }
}

PerformSampling.propTypes = {
  getSearchResult: PropTypes.func,
  scorecards: PropTypes.array,
  getSampleName: PropTypes.func,
  getScorecardList: PropTypes.func,
}

export default PerformSampling;
