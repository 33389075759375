
import React, { Component } from 'react';
import './ScoreCard.css';
import { Col, Row, Button, Form, Modal,Card } from 'react-bootstrap'
import utils from './../../utils';
import RoleConstant from "./../../constants/RoleConstant";
import EditScoreCard from './EditScoreCard';
import PropTypes from 'prop-types';
import EndpointConstant from './../../constants/EndpointConstant';
import RouterConstant from '../../constants/RouterConstant';
import MessageConstant from './../../constants/MessageConstant';
import PopupModels from './../PopupModels/PopupModels';
import { withRouter } from "react-router-dom";
import Moment from 'react-moment';
import CommentHistory from './../ErrorList/CommentHistory';
import moment from 'moment';

class ViewScoreCardDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
       ...this.props.details[0],
      showEditScoreCard: false,
      countTrue: 0,
      countFalse: 0,
      percent: 0,
      adminValid: false,
      scorecardAdminErrorModel: false,
      newcomments: this.props.details[0].comments,      
      completeModelPopup: false,
      updateScorecard: false,
      details: this.props.details[0],
      comments: this.props.details[0].comments,
      editComment: false,
      commentSuccess: false,
      latestComments:null,
      showComment: false
    }
    this.goBack = this.goBack.bind(this);
    this.showEditScore = this.showEditScore.bind(this);
    this.handleChangeYesNo = this.handleChangeYesNo.bind(this);
    this.complete = this.complete.bind(this);
    this.routeForReview = this.routeForReview.bind(this);
    this.editComment = this.editComment.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.validationAmgenQC = this.validationAmgenQC.bind(this);
    this.showErrorDetails1 = this.showErrorDetails1.bind(this);
    this.showHistory = this.showHistory.bind(this);
    this.hideHistory = this.hideHistory.bind(this);
  }
  componentDidMount() {
    this.aeidMatch();
    this.AE_Cap_IRPC_Match();
    this.AE_Q2_HCP();
    this.AE_Q3_IPRC();
    this.PCID_Q2_Missed();
    this.PC_Q1_IPRC_Match();
    this.caliculation();
    this.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC();
  }

  


  showEditScore() {
    this.setState({ showEditScoreCard: !this.state.showEditScoreCard })
  }
  goBack() {
    if (this.props.scorecard === 'old') {
        this.props.hideDetails(); 
     // window.location.reload(false)
      // this.showEditScore()
    } else {
      if (this.props.scoreDetails === 'new') {
        //console.log("Testing hideView", this.props.hideView)
        if (this.props.hideView === true) {
          this.props.hidenDetails();
        } else {
          const path = this.props.location.pathname.slice(1);
          if (path === RouterConstant.HOME_LINK.slice(1)) {
            utils.gotoPage(RouterConstant.SCORE_CARD_LINK)
          } else {
            window.location.reload(false)
          }
        }
      }
      else {
        window.location.reload(false)
      }
    }
    // this.props.hideDetails();
  }
  caliculation() {
    let count = {
      trueCount: 0,
      falseCount: 0
    }
    //  let trueCount=0
    //  let falseCount=0
    if (this.state.AEID_Q2_AE_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.AEID_Q2_AE_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.AE_Cap_IRPC_Match_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.AE_Cap_IRPC_Match_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.AE_Q2_HCP_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.AE_Q2_HCP_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.AE_Q3_IPRC_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.AE_Q3_IPRC_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.PCID_Q2_Missed_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.PCID_Q2_Missed_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.PC_Q1_IPRC_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.PC_Q1_IPRC_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    if (this.state.bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation === 'True') {
      count.trueCount = ++count.trueCount
    }
    if (this.state.bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation === 'False') {
      count.falseCount = ++count.falseCount
    }
    return count;
  }
  aeidMatch() {
    if (this.state.AEID_Q2_AE_Missed_QualityMonitorQC !== undefined && this.state.AEID_Q2_AE_Missed_Amgen !== '' && this.state.AEID_Q2_AE_Missed_Amgen !== undefined) {
      if ((this.state.AEID_Q2_AE_Missed_QualityMonitorQC === this.state.AEID_Q2_AE_Missed_Amgen)) {
        this.setState({ AEID_Q2_AE_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ AEID_Q2_AE_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ AEID_Q2_AE_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  AE_Cap_IRPC_Match() {
    if (this.state.AE_Cap_IRPC_Match_QualityMonitorQC !== undefined && this.state.AE_Cap_IRPC_Match_Amgen !== '' && this.state.AE_Cap_IRPC_Match_Amgen !== undefined) {
      if ((this.state.AE_Cap_IRPC_Match_QualityMonitorQC === this.state.AE_Cap_IRPC_Match_Amgen)) {
        this.setState({ AE_Cap_IRPC_Match_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ AE_Cap_IRPC_Match_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ AE_Cap_IRPC_Match_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  AE_Q2_HCP() {
    if (this.state.AE_Q2_HCP_QualityMonitorQC !== undefined && this.state.AE_Q2_HCP_Amgen !== '' && this.state.AE_Q2_HCP_Amgen !== undefined) {
      if ((this.state.AE_Q2_HCP_QualityMonitorQC === this.state.AE_Q2_HCP_Amgen)) {
        this.setState({ AE_Q2_HCP_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ AE_Q2_HCP_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ AE_Q2_HCP_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  AE_Q3_IPRC() {
    if (this.state.AE_Q3_IPRC_QualityMonitorQC !== undefined && this.state.AE_Q3_IPRC_Amgen !== '' && this.state.AE_Q3_IPRC_Amgen !== undefined) {
      if ((this.state.AE_Q3_IPRC_QualityMonitorQC === this.state.AE_Q3_IPRC_Amgen)) {
        this.setState({ AE_Q3_IPRC_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ AE_Q3_IPRC_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ AE_Q3_IPRC_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  PCID_Q2_Missed() {
    if (this.state.PCID_Q2_Missed_QualityMonitorQC !== undefined && this.state.PCID_Q2_Missed_Amgen !== '' && this.state.PCID_Q2_Missed_Amgen !== undefined) {
      if ((this.state.PCID_Q2_Missed_QualityMonitorQC === this.state.PCID_Q2_Missed_Amgen)) {
        this.setState({ PCID_Q2_Missed_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ PCID_Q2_Missed_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ PCID_Q2_Missed_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  PC_Q1_IPRC_Match() {
    if (this.state.PC_Q1_IPRC_Match_QualityMonitorQC !== undefined && this.state.PC_Q1_IPRC_Match_Amgen !== '' && this.state.PC_Q1_IPRC_Match_Amgen !== undefined) {
      if ((this.state.PC_Q1_IPRC_Match_QualityMonitorQC === this.state.PC_Q1_IPRC_Match_Amgen)) {
        this.setState({ PC_Q1_IPRC_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ PC_Q1_IPRC_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ PC_Q1_IPRC_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  bioconnect_Match_capturedIn_probing_decisionTree_amgenQC() {
    if (this.state.bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC !== undefined && this.state.bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC !== '' && this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC !== '' && this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC !== undefined) {
      if ((this.state.bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC === this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC)) {
        this.setState({ bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation: 'True' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
      else {
        this.setState({ bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation: 'False' }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount })
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage })
          }
        })
      }
    } else {
      this.setState({ bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation: '-' }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount })
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage })
        }
      })
    }
  }
  handleChangeYesNo(e) {
    e.preventDefault();
    let name = e.target.name
    // let param=e
    if (e.target.value === '') {
      e.target.className = 'form-control form-control-sm scoreError'
    }
    //console.log("attasdfks name", e.target.name);
    this.setState({
      [e.target.name]: e.currentTarget.value
     }, (param) => {
    // }, () => {
      switch (name) {
        case 'AEID_Q2_AE_Missed_Amgen':
          // this.aeidMatch();
          this.aeidMatch(param);
          break;
        case 'AE_Cap_IRPC_Match_Amgen':
          this.AE_Cap_IRPC_Match();
          break;
        case 'AE_Q2_HCP_Amgen':
          this.AE_Q2_HCP();
          break;
        case 'AE_Q3_IPRC_Amgen':
          this.AE_Q3_IPRC();
          break;
        case 'PCID_Q2_Missed_Amgen':
          this.PCID_Q2_Missed();
          break;
        case 'PC_Q1_IPRC_Match_Amgen':
          this.PC_Q1_IPRC_Match();
          break;
        case 'bioconnect_Match_capturedIn_probing_decisionTree_amgenQC':
          this.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC();
          break;
        default:
          break;
      }

    });

  }
  complete() {
    if (this.validationAmgenQC()) {
      let data = this.state;
      let viewData = this.props.details[0];
      let userName='';
    {utils.hasSessionItem("firstName") ? userName=utils.getSessionItem("firstName")+" "+ utils.getSessionItem("lastName") : ''}
    
      data.type = 'ADMIN_ASSIGN_TO_ADMIN';
      data.assignedTo = RoleConstant.Admin;
      data.Quality_Percentage_INT = parseFloat(data.Quality_Percentage_INT).toFixed(2);
      let self = this
      /* if (utils.getSessionItem("role") === RoleConstant.Admin && this.state.AE_Cap_IRPC_Match_Amgen !== '' &&
        this.state.AE_Cap_IRPC_Match_Error_Reconciliation && this.state.AEID_Q2_AE_Missed_Amgen &&
        this.state.AEID_Q2_AE_Error_Reconciliation && this.state.AE_Q2_HCP_Amgen && this.state.AE_Q2_HCP_Error_Reconciliation
        && this.state.AE_Q3_IPRC_Amgen && this.state.AE_Q3_IPRC_Error_Reconciliation && this.state.PCID_Q2_Missed_Amgen &&
        this.state.PCID_Q2_Missed_Error_Reconciliation && this.state.PC_Q1_IPRC_Match_Amgen && this.state.PC_Q1_IPRC_Error_Reconciliation &&
        this.state.bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation && this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC) { */
      // this.setState({adminValid:true})
      utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
        if (result.error === false || result.error === "false") {
          self.setState({ completeModelPopup: true },()=>{
            self.props.updateIntercations(viewData,userName,data.assignedTo,null)
          })
        }
      });
    } else {
      this.setState({ scorecardAdminErrorModel: true })
    }
  }
  handleErrorClose() {
    this.setState({ scorecardAdminErrorModel: false })
  }
  handleCompleteClose() {
    this.setState({ completeModelPopup: false});
    this.props.hideDetails();
  }
  handleRouteClose() {
    this.setState({updateScorecard: false });
    this.props.hideDetails(); 
  }
  validationAmgenQC() {
    let check = true;
    const { AEID_Q2_AE_Error_Reconciliation, AE_Cap_IRPC_Match_Error_Reconciliation, AE_Q2_HCP_Error_Reconciliation, AE_Q3_IPRC_Error_Reconciliation, PCID_Q2_Missed_Error_Reconciliation, PC_Q1_IPRC_Error_Reconciliation, bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation } = this.state;
    let qcField = {
      AEID_Q2_AE_Error_Reconciliation: AEID_Q2_AE_Error_Reconciliation,
      AE_Cap_IRPC_Match_Error_Reconciliation: AE_Cap_IRPC_Match_Error_Reconciliation,
      AE_Q2_HCP_Error_Reconciliation: AE_Q2_HCP_Error_Reconciliation,
      AE_Q3_IPRC_Error_Reconciliation: AE_Q3_IPRC_Error_Reconciliation,
      PCID_Q2_Missed_Error_Reconciliation: PCID_Q2_Missed_Error_Reconciliation,
      PC_Q1_IPRC_Error_Reconciliation: PC_Q1_IPRC_Error_Reconciliation,
      bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation: bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation
    }
    Object.values(qcField).forEach(
      (val) => (val === '' || val === '-') && (check = false)
    );
    return check;
  }
  routeForReview() {
    //console.log("this.state", this.state);
    //console.log("this.validation", this.validationAmgenQC());
    if (this.validationAmgenQC()) {
      
      let data = this.state;
      let viewData = this.props.details[0];
      data.type = 'ADMIN_ASSIGN_TO_QUALITYMONITOR';
      data.assignedTo = RoleConstant.QualityMonitor;
      data.stage='2'
      data.Quality_Percentage_INT = parseFloat(data.Quality_Percentage_INT).toFixed(2);
      let self = this
      utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
        if (result.error === false || result.error === "false") {
          self.setState({
            updateScorecard: true
          },()=>{
            self.props.updateIntercations(viewData,null,data.assignedTo,null)
          });
        }
      });
    }
    else {
      this.setState({
        scorecardAdminErrorModel: true
      });
    }
  }
  handleCommentChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

 

  updateAdminComment(){
    let self = this
    // console.log("details",this.state.details[0]);
    let data = {
      id: this.state.id,
      comments: this.state.latestComments,
      type: 'SCORECARD_COMMENT_UPDATE',
      interactionId: this.state.interactionId,
    }
    utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
      //console.log('resultttttt',result);
      
      if (result.error === false || result.error === "false") {
        self.loading = false;
        self.setState({ editComment: false, commentSuccess: true,id:result.scorecardId,comments:result.comments});
        let dataDetail={
          id:result.scorecardId,
                    
        }
        utils.makeRequest(this, EndpointConstant.SCORECARD_DETAILS, dataDetail, function (resultDetail) {
          //console.log("scorecardDetailsresult",result);
          const errors = resultDetail.scorecardDetails[0];
          self.loading = false;
          self.setState({ details: errors });
          self.setState({ show: true, latestComments:null });
        });
      }
    });
  }

  updateComment() {
    // let self = this
   
    if(this.state.latestComments=== '' || this.state.latestComments=== null) {
      this.setState({ editComment: false });
      // this.setState({ editComment: false })
    }
    else {
      this.updateAdminComment();
    }
  }
  // updateComment() {
  //   let self = this
  //   let data = {
  //     id: this.state.id,
  //     interactionId: this.state.interactionId,
  //     comments: this.state.newcomments,
  //     type: 'SCORECARD_COMMENT_UPDATE'
  //   }

  //   utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
  //     if (result.error === false || result.error === "false") {
  //       //self.props.hideDetails()
  //       self.editComment();
  //       self.setState({
  //         comments: result.comments
  //       })
  //     }
  //   })
  // }

  showErrorDetails1(){
    this.setState({commentSuccess: false})
  }

  editComment() {
    this.setState({ editComment: !this.state.editComment })
  }

  hideEditComment() {
    this.setState({ editComment: false, comments: this.props.details[0].comments })
  }
  showHistory() {
    this.setState({showComment: true});
  }
  hideHistory() {
    this.setState({showComment:false});
  }
  render() {
    const role = utils.getSessionItem("role");
    const userId = utils.getSessionItem("userId");
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    let scoreDetails = this.state;
    //console.log("view score details",scoreDetails)
    const { showEditScoreCard } = this.state;
   

    let commentHist = this.state.comments;
    //let commentHist = this.state.latestComments;
    //console.log("sort",commentHist);
    // const commentEditData = this.state.errorDetails.comments[0].comments;
    let commentEditData;
    if(Array.isArray(commentHist) ) {
    commentHist.sort(function (a, b) {
      return b.modifiedAt - a.modifiedAt;
    });
    //console.log("sort",commentHist);
  }
  let commentHistory = '';
    if(Array.isArray(commentHist) && commentHist.length > 0){
     // [commentHist[commentHist.length-1],commentHist[commentHist.length-2]]=[commentHist[commentHist.length-2],commentHist[commentHist.length-1]]
      commentHistory =  commentHist.map((data,index)=>{
        //console.log(index,'index')
        if(index<3){
            return (
                <Card className="mb-2 commentHistory">
                    <div className="cmt">
                        <span className="updatedBy">Updated By: {data.modifiedBy}</span>
                        <span className="updatedAt">Updated At: <Moment unix format="MM/D/YYYY">{data['modifiedAt']}</Moment></span>
                        </div>
                    <Card.Body>{data.comments}</Card.Body>
                </Card>
            )
        }
        }); 
    } else if (commentHist.length > 0){
      commentEditData = commentHist;
      //console.log("commentEditData",commentEditData)
    }
    else {
        commentHistory = (
            <Card className="mb-2">
                <Card.Body>Comments Not Available</Card.Body>
            </Card>
        );
    }


    const backButton = <Button variant="secondary" className="mt-4 clear follow-up-back mr-2" onClick={this.goBack}>
      <img src="Images/icon-arrow-left.png" className="btn-back" alt='Amgen' />Back</Button>
    const EditScoreCardButton = <Button onClick={this.showEditScore} className='button-color edit-score-card'>Edit Score Card</Button>
    const completeButton = <Button onClick={this.complete} className='mb-4 button-color follow-up-back btn btn-primary score-complete'>Complete</Button>
    const dropdown = (text, value) => {
      return (<Form.Control as='select' size="sm" disabled={(this.state.assignedTo === RoleConstant.Admin && this.state.amgenQCBy !== '') || (this.state.assignedTo !== RoleConstant.Admin && this.state.amgenQCBy === '')} name={text} value={value} onChange={this.handleChangeYesNo}>
        <option value=''>-Select-</option>
        <option value='NA'>NA</option>
        <option value='Yes'>Yes</option>
        <option value='No'>No</option>
      </Form.Control>)
    }
    return (
      <div>
        <Row>
          {showEditScoreCard ? '' : <Col md={12}><h3 className="heading-interaction">Score Card Details</h3></Col>}
        </Row>
        {showEditScoreCard ? <EditScoreCard hideDetails={this.goBack} scorecardDetails={scoreDetails} details={scoreDetails} hideEditScoreCard={this.showEditScore} hideViewDetails={this.props.hideDetails} updateIntercations={this.props.updateIntercations} scoreCard="editquality" /> :
          <div className="content-layout">
            <div className="scorecard-details">
              <div className="score-details-layout">
                <div className="score-card">
                  <Row className='lable-width'>
                    <Col md={4}><label>Sub-Case Number </label><span> {scoreDetails.subcaseNumber.toUpperCase()}</span></Col>
                    <Col md={4}><label>Dataset </label><span> {scoreDetails.datasetName}</span></Col>  
                    <Col md={4}><label>Case Type </label><span> {scoreDetails.caseType}</span></Col>
                  </Row>
                  <Row className='lable-width'> 
                    <Col md={4}><label>Channel </label><span> {scoreDetails.channel}</span></Col>                   
                    <Col md={4}><label>Attachment Included?</label><span>{scoreDetails.attachmentIncluded}</span></Col>
                    <Col md={4}><label>Assigned To</label><span> {scoreDetails.assignedTo}</span></Col> 
                  </Row>
                  <Row className='lable-width'>  
                  <Col md={4}><label>QM Associate</label><span>{scoreDetails.QMAssociate}</span></Col>                 
                  <Col md={4}><label>Quality Monitor QC By</label><span>{scoreDetails.qualityMonitorQCBy}</span></Col>
                  <Col md={4}><label>Amgen QC By </label><span> {scoreDetails.amgenQCBy ? scoreDetails.amgenQCBy : '-'}</span></Col>
                  
                  </Row>
                  <Row className='lable-width'>  
                    <Col md={4}><label>Modified On</label><span>{scoreDetails.modifiedAt ? moment.utc(scoreDetails.modifiedAt).format('MM/DD/YYYY hh:mm A') : ''}</span></Col>                                                     
                  </Row>
                  {/* <Row className='row-end-border'>

                  </Row> */}
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th className='original-qm-width'>Original QM Score</th>
                        <th className='qc-error-width'>Quality Monitor QC</th>
                        <th className='qc-error-width'>Amgen QC</th>
                        <th className='qc-error-width'>Error Reconciliation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.AEID_Q2_AE_Missed}</b></td>
                        <td>{scoreDetails.AEID_Q2_AE_Missed}</td>
                        <td>{scoreDetails.AEID_Q2_AE_Missed_QualityMonitorQC}</td>
                        <td>{role === RoleConstant.Admin ? dropdown('AEID_Q2_AE_Missed_Amgen', this.state.AEID_Q2_AE_Missed_Amgen) : scoreDetails.AEID_Q2_AE_Missed_Amgen || '-'}</td>
                        <td>{this.state.AEID_Q2_AE_Error_Reconciliation ? this.state.AEID_Q2_AE_Error_Reconciliation : '-'}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.AE_Cap_IRPC_Match}</b></td>
                        <td>{scoreDetails.AE_Cap_IRPC_Match}</td>
                        <td>{scoreDetails.AE_Cap_IRPC_Match_QualityMonitorQC}</td>
                        <td>{role === RoleConstant.Admin ? dropdown('AE_Cap_IRPC_Match_Amgen', scoreDetails.AE_Cap_IRPC_Match_Amgen) : scoreDetails.AE_Cap_IRPC_Match_Amgen || '-'}</td>
                        <td>{this.state.AE_Cap_IRPC_Match_Error_Reconciliation ? this.state.AE_Cap_IRPC_Match_Error_Reconciliation : '-'}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.AE_Q2_HCP}</b></td>
                        <td>{scoreDetails.AE_Q2_HCP}</td>
                        <td>{scoreDetails.AE_Q2_HCP_QualityMonitorQC}</td>
                        <td>{role === RoleConstant.Admin ? dropdown('AE_Q2_HCP_Amgen', scoreDetails.AE_Q2_HCP_Amgen) : scoreDetails.AE_Q2_HCP_Amgen || '-'}</td>
                        <td>{this.state.AE_Q2_HCP_Error_Reconciliation ? this.state.AE_Q2_HCP_Error_Reconciliation : '-'}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.AE_Q3_IPRC}</b></td>
                        <td>{scoreDetails.AE_Q3_IPRC}</td>
                        <td>{scoreDetails.AE_Q3_IPRC_QualityMonitorQC}</td>
                        <td>{role === RoleConstant.Admin ? dropdown('AE_Q3_IPRC_Amgen', scoreDetails.AE_Q3_IPRC_Amgen) : scoreDetails.AE_Q3_IPRC_Amgen || '-'}</td>
                        <td>{this.state.AE_Q3_IPRC_Error_Reconciliation ? this.state.AE_Q3_IPRC_Error_Reconciliation : '-'}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.PCID_Q2_Missed}</b></td>
                        <td>{scoreDetails.PCID_Q2_Missed}</td>
                        <td>{scoreDetails.PCID_Q2_Missed_QualityMonitorQC}</td>
                        <td>{role === RoleConstant.Admin ? dropdown('PCID_Q2_Missed_Amgen', scoreDetails.PCID_Q2_Missed_Amgen) : scoreDetails.PCID_Q2_Missed_Amgen || '-'}</td>
                        <td>{this.state.PCID_Q2_Missed_Error_Reconciliation ? this.state.PCID_Q2_Missed_Error_Reconciliation : '-'}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.PC_Q1_IPRC_Match}</b></td>
                        <td>{scoreDetails.PC_Q1_IPRC_Match}</td>
                        <td>{scoreDetails.PC_Q1_IPRC_Match_QualityMonitorQC}</td>
                        <td>{role === RoleConstant.Admin ? dropdown('PC_Q1_IPRC_Match_Amgen', scoreDetails.PC_Q1_IPRC_Match_Amgen) : scoreDetails.PC_Q1_IPRC_Match_Amgen || '-'}</td>
                        <td>{this.state.PC_Q1_IPRC_Error_Reconciliation ? this.state.PC_Q1_IPRC_Error_Reconciliation : '-'}</td>
                      </tr>
                      <tr>
                        <td><b>{siteinfo.ScorecardLabelsQuestions.bioconnect_Match_capturedIn_probing_decisionTree_originalQM}</b></td>
                        <td>{scoreDetails.bioconnect_Match_capturedIn_probing_decisionTree_originalQM}</td>
                        <td>{scoreDetails.bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC}</td>
                        <td>{role === RoleConstant.Admin ? dropdown('bioconnect_Match_capturedIn_probing_decisionTree_amgenQC', scoreDetails.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC) : scoreDetails.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC || '-'}</td>
                        <td>{this.state.bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation ? this.state.bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation : '-'}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='score-card-total'>
                    <Row>
                      <Col sm={10}>Total Error Reconciliation (Amgen QC vs Quality Monitor QC) </Col>
                      <Col>{this.state.Total_Error_Reconciliation ? this.state.Total_Error_Reconciliation : '0'}</Col>
                    </Row>
                    <Row>
                      <Col sm={10}>Quality Percentage of the INT </Col>
                      <Col>{this.state.Quality_Percentage_INT ? parseFloat(this.state.Quality_Percentage_INT).toFixed(2) : '0'}%</Col>
                    </Row>
                  </div>
                </div>
                <div className="layout-2">
                  <Row>
                    <Col md={12} sm={12}><label className="mb-2">Comments
                    {(utils.getSessionItem("role") === RoleConstant.Admin && scoreDetails.amgenQCBy === '') ||
                        (utils.getSessionItem("role") === RoleConstant.QualityMonitor && scoreDetails.amgenQCBy === '') ?
                        <img className="edit-case-owner" onClick={this.editComment} src="/Images/ico-edit.png" alt='Amgen' /> : ''}
                    </label>
                      {/* <div className='scroll'><span>{scoreDetails.comments}</span></div> */}

                      {/* <div className='agent-notes'>{!Array.isArray(commentHist) && <span className='scroll'>{commentEditData}</span>}</div>
               */}
                    </Col>
                  </Row>

                  <Row>
                <Col md={12} sm={12}>
                  {commentHistory}
                  {Array.isArray(commentHist) && commentHist.length >=3 && <span onClick={this.showHistory} className="showCmts">Show Comment History</span>}
                </Col>
              </Row>


                </div>
              </div>
              {/* <div className="row"><div className="col-xs-12 col-md-12"> 
      <button className="col-md-1 offset-11 btn-goback mt-4 mb-4 pull-right" onClick={this.goBack}>Back</button></div>
    </div> */}
              <div className="row pull-right"><div className="col-xs-12 col-md-12 buttongroup">
                {/* <Button type="submit" onClick={this.showFollowUp} className='mt-4 button-color follow-up-back'>Follow Up</Button> */}
                {(utils.getSessionItem('role') === RoleConstant.Admin && (scoreDetails.sampleCreatedBy === '' || userId === scoreDetails.sampleCreatedBy) && this.state.amgenQCBy === '' && this.state.assignedTo === RoleConstant.Admin)&&(scoreDetails.sampleSet!=='') ?
                  <span> {backButton} <button className='btn-goback mt-4 mb-4' onClick={this.routeForReview}>Route For review</button>
                    {completeButton} </span> : <span>{utils.getSessionItem('role') === RoleConstant.Admin ? backButton : ''}</span>}
                {utils.getSessionItem('role') === RoleConstant.QualityMonitor ?
                  <span>{backButton}{this.state.assignedTo === RoleConstant.QualityMonitor && ((scoreDetails.createdBy===userId && scoreDetails.stage==='1')||(scoreDetails.createdBy!==userId && scoreDetails.stage==='2') && (scoreDetails.QualitysampleSet!=='' && scoreDetails.QualitysampleCreatedBy===userId)) ? EditScoreCardButton : ''}</span> : ''}
              </div>
              </div>

            </div>
          </div>}

          <PopupModels showPopup={this.state.commentSuccess} iconFlag="success" message={MessageConstant.COMMENT_EDIT_SUCCESS} handleClosePopup={this.showErrorDetails1} />
        
        <Modal
          dialogClassName="rejectionCommentModal"
          show={this.state.editComment}
          onHide={this.hideEditComment.bind(this)}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header closeButton className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Add Comment
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Form.Group controlId="rejectionComment">
              <Form.Label>Comment</Form.Label>
              <Form.Control as='textarea' name='latestComments' value={this.state.latestComments} onChange={this.handleCommentChange} />
              {/* {this.state.errorMessage.length > 0 && 
                <div className="error"><span>{this.state.errorMessage}</span></div>} */}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.updateComment}>
              Ok
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
        size="lg"
        show={this.state.showComment}
        onHide={this.hideHistory}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Comment History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollcommentpopup">
          <CommentHistory commentHist={commentHist}/>
        </Modal.Body>
      </Modal>

        <PopupModels showPopup={this.state.scorecardAdminErrorModel} iconFlag="error" message={MessageConstant.SCORECARD_ERROR} handleClosePopup={this.handleErrorClose.bind(this)} />
        <PopupModels showPopup={this.state.completeModelPopup} iconFlag="success" message={MessageConstant.COMPLETED_SCORECARD} handleClosePopup={this.handleCompleteClose.bind(this)} />
        <PopupModels showPopup={this.state.updateScorecard} iconFlag="success" message={MessageConstant.UPDATE_SCORECARD} handleClosePopup={this.handleRouteClose.bind(this)} />
      </div>
    )
  }
}
ViewScoreCardDetails.propTypes = {
  details: PropTypes.array,
  hideDetails: PropTypes.func,
  scoreDetails: PropTypes.string,
  scorecard: PropTypes.string,
  location: PropTypes.object

}
export default withRouter(ViewScoreCardDetails);