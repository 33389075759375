import React from 'react';
const MessageConstant = {};

MessageConstant.REG_MESSAGE_FOR_USER = 'Your registration request has been sent for approval and you will be notified via email about the decision. You can start using the website once your request is approved.'
MessageConstant.ADMIN_REGISTRATION_MESSAGE = (data) => {
	return (
		<div>Invitation has been sent to <b>{data}</b>. Registration process will be complete once user accepts the invite.</div>
	)
};
MessageConstant.USER_ALREADY_EXISTS = (data) => {
	return (
		<div><b>{data}</b> is already a registered user of GIMS, please ask the user to login to the website using their Amgen credentials</div>
	)
};
MessageConstant.USER_ALREADY_EXISTS_NON_ADMIN = 'You are already a registered user of GIMS, please login to the website using your Amgen credentials';
MessageConstant.REG_FIRSTNAME_VALIDATION = 'Please enter your First Name';
MessageConstant.REG_LASTNAME_VALIDATION = 'Please enter your Last Name';
MessageConstant.REG_ROLE_VALIDATION = 'Please select your role';
MessageConstant.REG_TEAM_VALIDATION = 'Please select a team';
MessageConstant.REG_EMAIL_VALIDATION = 'Please enter your Amgen email address';
MessageConstant.REG_EMAIL_NOTVALID = 'Enter valid email address';
MessageConstant.REG_SPECIAL_NOT_ALLOWED = 'Special characters are not allowed';
MessageConstant.REG_JUSTIFICATION_VALIDATION = 'Please provide Justification for access';

MessageConstant.REG_MAX_LENGTH_FIELD = 16;
MessageConstant.REG_MAX_LENGTH_TEXTAREA = 250;
MessageConstant.USER_DELETETION = (data) => {
	return (
		<div><span>Do you really want to remove</span> <b> {data} </b><span>from GIMS?</span></div>)
};
MessageConstant.QM_USER_DELETE = (data) => {
	return (
		<div><span> All assigned subcases to Quality Monitor will be unassigned. Are you sure you want to remove</span><b> {data}</b><span> from GIMS? </span></div>)
};

MessageConstant.TV_USER_DELETE = (data) => {
	return (
		<div><span> All assigned subcases to Translation Vendor will be unassigned. Are you sure you want to remove</span><b> {data}</b><span> from GIMS? </span></div>)
};

MessageConstant.CCA_DELETE = (data, team) => {
	return (
		<div><span> All assigned subcases to </span><span title={team}><u>Call Center Admin team</u></span> <span>will be unassigned. Are you sure you want to remove</span><b> {data}</b><span> from GIMS? </span></div>)
};

MessageConstant.QM_USER_EDIT = (data) => {
	return (
		<div><span> All assigned subcases to Quality Monitor will be unassigned. Are you sure you want to update</span><b> {data}</b><span> role in GIMS? </span></div>)
};
MessageConstant.TV_USER_EDIT = (data) => {
	return (
		<div><span> All assigned subcases to Translation Vendor will be unassigned. Are you sure you want to update</span><b> {data}</b><span> role in GIMS? </span></div>)
};
MessageConstant.CCA_EDIT = (team) => {
	return (
		<div><span> All assigned subcases to </span><span title={team}><u>Call Center Admin team</u></span> <span>will be unassigned. Are you sure you want to update? </span></div>)
};
MessageConstant.USER_EDIT = (data) => {
	return (
		<div><span>Are you sure you want to update </span> <b>{data}</b><span>'s Role?</span></div>)
};
MessageConstant.USER_DELETION_SUCCESS = 'User has been successfully removed from GIMS.';
MessageConstant.USER_REJECCTION = 'Do you really want to reject the user?';
MessageConstant.USER_NOTIFICATION = 'User Notified Successfully';
MessageConstant.USER_COMMENT = 'Please provide the comment';
MessageConstant.ADD_USER_SUCCESS = 'User Added Successfully to the system';
MessageConstant.TEAM_ASSIGN_SUCCESS = "Team has been assigned successfully";
MessageConstant.QM_ASSIGN_SUCCESS = "Interaction has been assigned successfully";
MessageConstant.QUALITYMONITOR_ASSIGN_TO_TRANSLATIONVENDOR = "Interaction has been assigned to Translation Vendor for review";
MessageConstant.ASSIGN_TO_QUALITYMONITOR = "Interaction has been assigned to Quality Monitor for review";
MessageConstant.EDIT_ERROR = "Error has been edited successfully";
MessageConstant.ADD_ERROR = "Error has been created successfully";
MessageConstant.UPDATE_SCORECARD = "Score Card has been modified successfully.";
MessageConstant.DELETING_ERROR = 'Do you really want to delete the record?';
MessageConstant.ERROR_DELETED = 'Record has been deleted successfully';
MessageConstant.LOGOUT_CONFIRMATION = 'Are you sure, Do you really want to logout from GIMS';
MessageConstant.LOGOUT_MESSAGE = 'You have been logged out.';
MessageConstant.USER_DOESNT_EXISTS_NON_ADMIN = 'Your are not part of Amgen, only amgen users can register themselves into GIMS';
MessageConstant.DELETING_DATASET = 'Are you sure you want to delete the Dataset?';
MessageConstant.DATASET_DELETED = 'Dataset has been deleted successfully';
MessageConstant.DELETE_CONFIRMATION = 'Are you sure you want to delete the Attachment?';
MessageConstant.ATTACHMENT_DELETED = 'Attachment has been deleted successfully';
MessageConstant.DEPLOY_PROD_CONFIRMATION = 'Do you really want to deploy model into production environment?';
MessageConstant.RECENT_TRAINING_ERR_CONFIRMATION = 'Training results show that the models didn\'t meet the acceptence criteria to move to production, Do you still want to want to deploy model into production environment';
MessageConstant.COMMENT_EDIT_SUCCESS = 'Comments has been edited successfully';
MessageConstant.SCORECARD_ERROR = 'Please provide all the fields required';
MessageConstant.COMMENT_EDIT_SUCCESS = 'Comments has been edited successfully';
MessageConstant.EMAIL_SUCCESS_MESSAGE = 'Email Sent Successfully';
MessageConstant.EMAIL_FAILED_MESSAGE = 'Email Sending Failed';
MessageConstant.ADD_SCORECARD = 'Score Card has been added successfully';
MessageConstant.COMPLETED_SCORECARD = 'Score Card completed successfully';
MessageConstant.INVALID_DATE = 'Invalid Date range';
MessageConstant.START_DATE_EMPTY = "Please provide the Start date";
MessageConstant.END_DATE_EMPTY = "Please provide the End date";
MessageConstant.DATASET_ERRORS = "Dataset has error, you cannot delete it.";
MessageConstant.DATASET_SCORCARDS = "Dataset has scorecard, you cannot delete it.";
MessageConstant.NO_SAMPLING = 'No scorecards available for Sampling';
MessageConstant.DELETE_ERROR_PROCESSING = 'Deletion not allowed while processing';
MessageConstant.CCU_400 = 'No user found in the team';
MessageConstant.ERROR_FAIL = 'Please provide the required fields';
MessageConstant.ATTACHMENT_ERROR_FAIL = 'Please provide the required Attachment';
MessageConstant.QUALITYMONITOR_ASSIGN_TO_CALLCENTERADMIN = 'Interaction has been assigned to Call Center Admin for review';
MessageConstant.UPDATE_USER_SUCCESS = "User has been updated successfully";
MessageConstant.UPDATE_USER_ERROR = "User updation failed";
MessageConstant.DATASET_EXTRACTED_MESSAGE = "Your data set has been uploaded and processed successfully. You will be able to see new set of master cases in the home screen.";
MessageConstant.ACCEPTANCE_CRITERIA_UPDATE = "Acceptance Criteria is updated successfully";
MessageConstant.NO_ATTACH_PERMISSION = "No attach permission";
MessageConstant.INT_ALREADY_ASSIGNED = 'Interaction has been already assigned';
MessageConstant.E_ALREADY_CREATED = 'Error has been already Created';
MessageConstant.S_ALREADY_CREATED = 'Scorecard has been already created';
MessageConstant.S_USER_NOT_EXIST = 'User Not availbale';
MessageConstant.DELETE_SAMPLE = (data) => {
	return (
		<div><span> Sample set "</span><b>{data}</b><span>" has been deleted successfully </span></div>)
};
MessageConstant.CONFIRM_DELETE_SAMPLE = (data) => {
	return (
		<div><span> Are you sure you want to delete the Sample set "</span><b>{data}</b><span>" ? </span></div>)
};
MessageConstant.DELETE_SAMPLE_FAIL = 'You are not allowed to delete this Sample set';
MessageConstant.USER_UPDATE_CONFIRMATION = "Are you sure you want to continue?"
MessageConstant.DOWNLOAD_INTERACTIONS = 'Download link sent to your mail, please check your mail and download the subcases'
MessageConstant.DOWNLOADED_INTERACTION = 'Sub-Cases have been downloaded successfully';
MessageConstant.CANCEL_DOWNLOAD = 'Are you sure you want to cancel? All Downloading Progress will be aborted';
MessageConstant.DELETING_REPORT = 'Are you sure you want to delete the Report?';
MessageConstant.REPORT_DELETED = 'Report has been deleted successfully';
MessageConstant.REPORT_ERRORS = "Report deletion failed";
export default MessageConstant;
