import React, { Component } from 'react';
import { HashRouter, Route, Redirect } from 'react-router-dom';
// import { Security, ImplicitCallback } from '@okta/okta-react';
import { Home, Login, Registration, Users, Dataset, ViewDetails, AdminApprovalPending, AddUser, SSOCallback, LandingScreen, PageNotFound, GimsReports, DataModelReports, ErrorList, ScoreCard, ViewScoreCardDetails, RedirectedUnapprovedUser, Logout, DownloadAttachment, DownloadErrorAttachment } from '..';
import RouterConstant from '../../constants/RouterConstant';
import utils from './../../utils';

/**
 * A class that specify routes */
class RouterComp extends Component {
  /**
   *renders the ui
   *@return {Object} .
    */
  render() {
    return (
      <HashRouter>
        <Route path={RouterConstant.HOME_LINK} component={Home} />
        <Route exact path={RouterConstant.REG_LINK}
          render={() => <Registration adminRegister={true} adminUpdate={false} />} />
        <Route exact path={RouterConstant.REGISTRATION_LINK}
          render={() => <Registration adminRegister={false} adminUpdate={false} />} />
        <Route exact path={RouterConstant.USERS_LINK} component={Users} />
        <Route exact path={RouterConstant.FILEUPLOAD_LINK}
          component={Dataset} />
        <Route exact path={RouterConstant.VIEWDETAILS_LINK}
          component={ViewDetails} />
        <Route exact path={RouterConstant.ADMINAPPROVALPENDING_LINK}
          component={AdminApprovalPending} />
        <Route exact path={RouterConstant.ADDUSER_LINK}
          component={AddUser} />
        <Route exact path={RouterConstant.RESET_AUTH}
          component={SSOCallback} />
        <Route exact path={RouterConstant.GIMS_REPORTS_LINK}
          component={GimsReports} />
        <Route exact path={RouterConstant.DATA_MODEL_REPORTS_LINK}
          component={DataModelReports} />
        <Route exact path={RouterConstant.ERROR_LIST_LINK}
          component={ErrorList} />
        <Route exact path={RouterConstant.SCORE_CARD_LINK}
          component={ScoreCard} />
        <Route exact path={RouterConstant.SCORE_CARD_DETAILS_LINK}
          component={ViewScoreCardDetails} />
        <Route exact path={RouterConstant.PAGE_NOT_FOUND}
          component={PageNotFound} />
        <Route exact path={RouterConstant.REDIRECTED_UNAPPROVED_USER} component={RedirectedUnapprovedUser} />
        <Route path={RouterConstant.LANDING_SCREEN} component={LandingScreen} />
        <Route path={RouterConstant.LOGIN_LINK} component={Login} />
        <Route exact path={RouterConstant.LOGOUT} component={Logout} />
        
        <Route exact path={RouterConstant.DOWNLOAD+'/:name/:attachment'} render={({match})=> <DownloadAttachment match={match} />} />
        <Route exact path={RouterConstant.DOWNLOAD_ERROR+'/:name/:attachment'} render={({match})=> <DownloadErrorAttachment match={match} />} />
        <Route exact path="/" component={() => (utils.sessionValidityCheck() && utils.hasSessionItem("userId")) ? <Redirect to={RouterConstant.HOME_LINK} /> : <Redirect to={RouterConstant.LANDING_SCREEN} />} />
      </HashRouter>

    );
  }
}

export default RouterComp;
