var EndpointConstant = {};

EndpointConstant.GET_USER_DETAILS_FOR_REGISTRATION = 'GetUserDetailsForRegistration';
EndpointConstant.RESET_USER_AUTH = 'ResetAuthUser';
EndpointConstant.ADD_USER = 'AddUser';
EndpointConstant.GET_USER_LIST = 'GetUserList';
EndpointConstant.USER_STATUS_UPDATE = 'UserStatusUpdate';
EndpointConstant.GET_INTERACTION_LIST = 'InteractionList';
EndpointConstant.GET_INTERACTION_FROM_JSON = 'InteractionFromJson';
EndpointConstant.GET_INTERACTION_DETAILS = 'InteractionDetails';
EndpointConstant.FILTER_INTERACTIONS = 'FilterInteraction';
EndpointConstant.FILTER_USERS = 'FilterUsers';
EndpointConstant.GET_SITE_INFO = 'GetSiteInfo';
EndpointConstant.USER_AUTH = 'UserAuth';
EndpointConstant.GET_DATASET_LIST = 'DataSetList';
EndpointConstant.UPLOAD_DATASET = 'UploadDataset';
EndpointConstant.DOWNLOAD_DATASET = 'DownloadDataset';
EndpointConstant.RESEND_INVITATION = 'ResendInvitation';
EndpointConstant.GET_LDAP_USERS = 'GetLdapUsers';
EndpointConstant.ADD_SCORE_CARD = 'AddScorecard';
EndpointConstant.LOG_UI_ERROS = 'LogUIErrors';
EndpointConstant.GET_ERROR_LIST = 'ErrorList';
EndpointConstant.GET_ERROR_DETAILS = 'ErrorDetails';
EndpointConstant.FILTER_ERRORS = 'FilterError';
EndpointConstant.CREATE_ERRORS = 'CreateError';
EndpointConstant.ERRORS_LIST = 'ErrorList';
EndpointConstant.ERROR_DETAILS = 'ErrorDetails';
EndpointConstant.UPDATE_INTERACTION = 'UpdateInteraction';
EndpointConstant.SCORECARD_LIST = 'ScorecardList';
EndpointConstant.SCORECARD_DETAILS = 'ScorecardDetails';
EndpointConstant.UPDATE_SCORECARD = 'UpdateScorecard';
EndpointConstant.UPDATE_ERROR = 'UpdateError';
EndpointConstant.ADD_ATTACHMENT = 'AddAttachment';
EndpointConstant.ADD_ERROR_ATTACHMENT ='AddErrorAttachment';
EndpointConstant.DELETE_ERROR = 'DeleteError';
EndpointConstant.DOWNLOAD_ATTACHMENT = 'DownloadAttachment';
EndpointConstant.DOWNLOAD_ERROR_ATTACHMENT = 'DownloadErrorAttachment';
EndpointConstant.DELETE_ATTACHMENT = 'DeleteAttachment';
EndpointConstant.DELETE_ERROR_ATTACHMENT = 'DeleteErrorAttachment';
EndpointConstant.GET_DATA_MODEL_REPORTS = 'GetDataModelReport';
EndpointConstant.FILTER_SCORECARD = 'FilterScorecard';
EndpointConstant.LOGOUT = 'LogOut';
EndpointConstant.UPDATE_DATASET_NAME = 'UpdateDatasetName';
EndpointConstant.FILTER_DATA_MODEL = 'FilterDataModelReport';
EndpointConstant.GET_CASE_TYPES_FOR_DATASET = 'GetCasetypesForDataset';
EndpointConstant.DELETE_DATASET = 'DeleteDataSet';
EndpointConstant.GIMS_REPORT = 'GIMSReport';
EndpointConstant.GIMS_REPORT_FROM_FILE = 'GIMSReportFromFile';
EndpointConstant.SCORECARDS_LISTS_FROM_DATASET = 'ScorecardsListsFromDataset';
EndpointConstant.CREATE_SAMPLE = 'CreateSample';
EndpointConstant.COUNTRY_TOTALS = 'CountryTotals';
EndpointConstant.FILTER_COUNTRYTOTALS = 'filterCountryTotals';
EndpointConstant.START_MODEL_TRAINING = 'StartModelTraining';
EndpointConstant.START_MODEL_DEPLOYMENT = 'StartModelDeployment';
EndpointConstant.SCORECARDS_FROM_SAMPLESET = 'ScorecardsFromSampleset';
EndpointConstant.GET_SAMPLE_SETS = 'GetSampleSets';
EndpointConstant.DOWNLOAD_SCORECARD = 'DownloadFilteredScorecard';
EndpointConstant.DOWNLOAD_COUNTRY_TOTALS = 'DownloadCountryTotals';
EndpointConstant.GIMS_REPORT_SEND_EMAIL = 'SendEmail';
EndpointConstant.SAMPLING_DATASET = 'SamplingDataset';
EndpointConstant.DOWNLOAD_INTERACTION = 'DownloadInteraction';
EndpointConstant.BIOCONNECT_EXTRACT = 'BioconnectExtract';
EndpointConstant.ACCEPTANCE_CRITERIA = 'AcceptanceCriteria';
EndpointConstant.SCORECARDS_LIST_FROM_SAMPLESET = 'scorecardsListFromSampleset';
EndpointConstant.DOWNLOAD_INTERACTION_STATUS = 'DownloadInteractionStatus';
EndpointConstant.GET_SCORECARD_FROM_JSON = 'ScorecardsListsFromJson';
EndpointConstant.EVALUATION_REPORT = 'EvaluationReport';
EndpointConstant.EVALUATION_REPORT_STATUS = 'EvaluationReportStatus';
EndpointConstant.EVALUATION_GIMS_REPORT = 'EvaluationGIMSReport';
EndpointConstant.EVALUATION_GIMS_REPORT_STATUS = 'EvaluationGIMSReportStatus';
EndpointConstant.GET_REPORT_LIST = 'ReportList';
EndpointConstant.UPLOAD_REPORT = 'ReportUpload';
EndpointConstant.DOWNLOAD_REPORT = 'ReportDownload';
EndpointConstant.DELETE_REPORT = 'ReportDelete';
export default EndpointConstant;
