import React, { Component } from 'react';
import './GimsReportCharts.css';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

/*
 * A class to generate Gims Error Criticality Charts
 */
export class GimsErrorCriticalityCharts extends Component {

    drawCharts(args) {
        let { width, height, data } = args ? args : this.props;
        //console.log("GimsReportcharts", );
        let margin = { top: 10, right: 70, bottom: 70, left: 50 };

        var sample = data;
        //var margin = {top: 10, right: 70, bottom: 70, left: 50};
        d3.select(`#${this.props.id}`).selectAll('svg').remove();
        d3.select(`#${this.props.id}`).selectAll('canvas').remove();
        const svg = d3.select(`#${this.props.id}`).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160));

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(sample, (d) => d.count) + 100]);

        const makeYLines = () => d3.axisLeft()
            .scale(yScale);

        chart.append('g')
            .attr('class', 'opacityBlack')
            .call(d3.axisLeft(yScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    // .style('fill-opacity', 1)
                    .style('fill', 'black')
            });

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(sample.map((s) => s.errorTitle))
            .padding(0.6)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    //.style('fill-opacity', 1)
                    .style('fill', 'black')
            })
            .selectAll(".tick text")
            .attr("y", 0)
            .attr("x", -10)
            .attr("dy", ".25em")
            .attr("transform", "rotate(-90)")
            .style("text-anchor", "end");
        /* .selectAll("text")
        .style("font-size", "10px")
        .style("font-weight", 600)
        .style('fill-opacity', 1)
        .style('color', '#666666') */

        chart.append('g')
            .attr('class', 'grid')
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat('')
            )

        
        var tooltip = d3.select("body").append("div")
        .attr("class", "toolTip")
        .style("display", "none");    

        const barGroups = chart.selectAll()
            .remove()
            .exit()
            .data(sample)
            .enter()
            .append('g')
            .on("mouseover", function(d){tooltip.text(d.count); return tooltip.style("display", "inline");})
            .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
            .on("mouseout", function(){return tooltip.style("display", "none");});

        barGroups
            .append('rect')
            .attr('x', (g) => xScale(g.errorTitle))
            .attr('y', (g) => yScale(g.count))
            .attr('height', (g) => height - yScale(g.count))
            .attr('width', xScale.bandwidth())
            //.attr('width', 50)
            .style('fill', (d) => {
                return d.color;
            })
            .style('cursor', 'pointer')
            .style('fill-opacity', 1);
        barGroups
            .append('text')
            .attr('class', 'value')
            .attr('x', (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
            .attr('y', (g) => yScale(g.count) - 3)
            .attr('text-anchor', 'middle')
            .text((g) => `${g.count}`);

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", margin.top)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("Sub-case count");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("y", -30)
            .attr("transform", "translate(" + ((width / 2) + margin.left) + " ," + (height + (sample.length === 0 ? 60 : 220)) + ")")
            .style("text-anchor", "middle")
            .text("Error Criticality");
        svg.exit().remove();
    }

    componentDidMount() {
        this.drawCharts(null);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("next Props", nextProps)
        this.drawCharts(nextProps);
    }
    render() {
        //this.drawCharts();
        return (
            <div className="gims-data-chart" id={this.props.id}></div>
        );
    }

}

/*
 * GimsErrorCriticalitychart proptypes
 */
GimsErrorCriticalityCharts.propTypes = {
    id: PropTypes.string,
}

/*
 * A class to generate Gims Error Type Criticality charts
 */
export class GimsErrorTypeCriticalityCharts extends Component {

    drawCharts(args) {
        let { width, height, data } = args ? args : this.props;
        //console.log("GimsReportcharts");
        let margin = { top: 10, right: 70, bottom: 70, left: 50 };

        var sample = data;
        //var margin = {top: 10, right: 70, bottom: 70, left: 50};
        d3.select(`#${this.props.id}`).selectAll('svg').remove();
        const svg = d3.select(`#${this.props.id}`).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160));

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(sample, (d) => d.count) + 1]);

        const makeYLines = () => d3.axisLeft()
            .scale(yScale)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .call(d3.axisLeft(yScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    // .style('fill-opacity', 1)
                    .style('color', 'black')
            });

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(sample.map((s) => s.errorTitle))
            .padding(dynamicBars(sample));

        chart.append('g')
            .attr('class', 'opacityBlack')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            /* .selectAll("text")
            .attr("y", 10)
            .attr("x", 0)
            .attr("dx", "-.2em")
            .attr("dy", ".25em")
            .attr("transform", "rotate(-45)")
            .style("text-anchor", "end") */
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    //  .style('fill-opacity', 1)
                    .style('color', 'black')
            })
            .selectAll(".tick text")
            .attr("y", 0)
            .attr("x", -10)
            .attr("dy", ".25em")
            .attr("transform", "rotate(-90)")
            .style("text-anchor", "end");

        chart.append('g')
            .attr('class', 'grid')
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat('')
            )

        var tooltip = d3.select("body").append("div")
            .attr("class", "toolTip")
            .style("display", "none");    
    
            const barGroups = chart.selectAll()
                .data(sample)
                .enter()
                .append('g')
                .on("mouseover", function(d){tooltip.text(d.count); return tooltip.style("display", "inline");})
                .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
                .on("mouseout", function(){return tooltip.style("display", "none");});

        barGroups
            .append('rect')
            .attr('x', (g) => xScale(g.errorTitle))
            .attr('y', (g) => yScale(g.count))
            .attr('height', (g) => height - yScale(g.count))
            .attr('width', xScale.bandwidth())
            .style('fill', (d) => {
                return d.color;
            })
            .style('cursor','pointer')
            .style('fill-opacity', 1);
         barGroups
            .append('text')
            .attr('class', 'value')
            .attr('x', (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
            .attr('y', (g) => yScale(g.count) - 3)
            .attr('text-anchor', 'middle')
            .text((g) => `${g.count}`); 

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", margin.top)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("Sub-case count");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("y", -30)
            .attr("transform", "translate(" + ((width / 2) + margin.left) + " ," + (height + (sample.length === 0 ? 60 : 220)) + ")")
            .style("text-anchor", "middle")
            .text("Error Type");
    }

    componentDidMount() {
        this.drawCharts(null);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("next Props 1", nextProps);
        this.drawCharts(nextProps);
    }

    render() {
        return (
            <div className="gims-data-chart" id={this.props.id}></div>
        );
    }
}

/*
 * GimsErrorTypeCriticalityCharts proptypes
 */
GimsErrorTypeCriticalityCharts.propTypes = {
    id: PropTypes.string,
}

/*
 * A class to generate Gims subcase count owner Team 
 */
export class GimsSubcaseCountOwnerTeam extends Component {

    drawCharts(args) {
        let { width, height, data } = args ? args : this.props;
        //console.log("GimsReportcharts");
        let margin = { top: 10, right: 70, bottom: 70, left: 50 };

        var sample = data;
        //var margin = {top: 10, right: 70, bottom: 70, left: 50};
        d3.select(`#${this.props.id}`).selectAll('svg').remove();
        const svg = d3.select(`#${this.props.id}`).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160));

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(sample, (d) => d.count) + 10]);

        const makeYLines = () => d3.axisLeft()
            .scale(yScale)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .call(d3.axisLeft(yScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    //    .style('fill-opacity', 1)
                    .style('color', 'black')
            });

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(sample.map((s) => s.errorTitle))
            .padding(0.1)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    // .style('fill-opacity', 1)
                    .style('color', 'black')
            })
            .selectAll(".tick text")
            .attr("y", 0)
            .attr("x", -10)
            .attr("dy", ".25em")
            .attr("transform", "rotate(-90)")
            .style("text-anchor", "end");
        /* .selectAll(".tick text")
        .call(wrap, xScale.bandwidth()) */

        chart.append('g')
            .attr('class', 'grid')
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat('')
            )

        var tooltip = d3.select("body").append("div")
            .attr("class", "toolTip")
            .style("display", "none");     
    
            const barGroups = chart.selectAll()
                .data(sample)
                .enter()
                .append('g')
                .on("mouseover", function(d){tooltip.text(d.count); return tooltip.style("display", "inline");})
                .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
                .on("mouseout", function(){return tooltip.style("display", "none");});

        barGroups
            .append('rect')
            .attr('x', (g) => xScale(g.errorTitle))
            .attr('y', (g) => yScale(g.count))
            .attr('height', (g) => height - yScale(g.count))
            .attr('width', xScale.bandwidth())
            .style('fill', (d) => {
                return d.color;
            })
            .style('cursor','pointer')
            .style('fill-opacity', 1);
         barGroups
            .append('text')
            .attr('class', 'value')
            .attr('x', (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
            .attr('y', (g) => yScale(g.count) - 3)
            .attr('text-anchor', 'middle')
            .text((g) => `${g.count}`);

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", margin.top)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("Unique Count");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", 25)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("(Sub-case count)");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "translate(" + ((width / 2) + margin.left) + " ," + (height + (sample.length === 0 ? 60 : 220)) + ")")
            .style("text-anchor", "middle")
            .text("Associated Team");
    }

    componentDidMount() {
        this.drawCharts(null);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("next Props 2", nextProps);
        this.drawCharts(nextProps);
    }
    render() {
        return (
            <div className="gims-data-chart" id={this.props.id}></div>
        );
    }
}

/*
 * GimsSubcaseCountOwnerTeam proptypes
 */
GimsSubcaseCountOwnerTeam.propTypes = {
    id: PropTypes.string,
}

/*
 * A class to generate Gims ErrorType subcase count owner team
 */
export class GimsErrorTypeSubcaseCountOwnerTeam extends Component {

    drawCharts(args) {
        let { width, height, data } = args ? args : this.props;
        //console.log("GimsErrorTypeSubcaseCountOwnerTeam");
        let margin = { top: 10, right: 70, bottom: 70, left: 50 };

        var sample = data;
        var longest = 0;
        if(sample.length>0){
            let sampleData= sample.slice();
            longest = sampleData.sort(
                function (a, b) {
                    return b['errorTitle'].length - a['errorTitle'].length;
                }
            )[0];
        }
        //console.log("sample errorTitle lenght",longest['errorTitle'].length)
        //var margin = {top: 10, right: 70, bottom: 70, left: 50};
        d3.select(`#${this.props.id}`).selectAll('svg').remove();
        const svg = d3.select(`#${this.props.id}`).append("svg")
            .attr("width", width + margin.left + margin.right)
            //.attr("height", height + margin.top + margin.bottom);
            .attr("height", height + margin.top + margin.bottom + (sample.length === 0 ? 0 : 160));

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            //.domain([0, 8]);
            .domain([0, d3.max(sample, (d) => d.count) + 4]);

        const makeYLines = () => d3.axisLeft()
            .scale(yScale)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .call(d3.axisLeft(yScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    //.style('fill-opacity', 1)
                    .style('color', 'black')
            });

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(sample.map((s) => s.errorTitle))
            .padding(dynamicBars(sample))

        chart.append('g')
            .attr('class', 'opacityBlack')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    // .style('fill-opacity', 1)
                    .style('color', 'black')
            })
            .selectAll("text")
             .attr("y", 0)
             .attr("x", -10)
             .attr("dy", ".25em")
             .attr("transform", "rotate(-90)")
             .style("text-anchor", "end") 
            /*.selectAll(".tick text")
            .call(wrap, xScale.bandwidth())*/

        chart.append('g')
            .attr('class', 'grid')
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat('')
            )

            /*var tooltip = svg
            .data(sample)
            .enter()
            .append("div")
            .style("position", "absolute")
            .style("z-index", "10")
            .attr("class","toolTip")
            .text((g)=>`${g.count}`);*/
            
        var tooltip = d3.select("body").append("div")
            .attr("class", "toolTip")
            .style("display", "none");
    
            const barGroups = chart.selectAll()
                .data(sample)
                .enter()
                .append('g')
                .on("mouseover", function(d){tooltip.text(d.count); return tooltip.style("display", "inline");})
                .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
                .on("mouseout", function(){return tooltip.style("display", "none");});
        barGroups
            .append('rect')
            .attr('x', (g) => xScale(g.errorTitle))
            .attr('y', (g) => yScale(g.count))
            .attr('height', (g) => height - yScale(g.count))
            .attr('width', xScale.bandwidth())
            .style('fill', (d) => {
                return d.color;
            })
            .style('cursor','pointer')
            .style('fill-opacity', 1);
         barGroups
            .append('text')
            .attr('class', 'value')
            .attr('x', (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
            .attr('y', (g) => yScale(g.count) - 3)
            .attr('text-anchor', 'middle')
            .text((g) => `${g.count}`); 

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", margin.top)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("Unique Count");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", 25)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("(Sub-case count)");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "translate(" + ((width / 2) + margin.left) + " ," + (height + (sample.length === 0 ? 60 : longest != 0?longest['errorTitle'].length + (height*2.5)/4 : 240)) + ")")
            .style("text-anchor", "middle")
            .text("Associated Team");
    }

    componentDidMount() {
        this.drawCharts(null);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("next Props 3", nextProps);
        this.drawCharts(nextProps);
    }
    render() {
        return (
            <div className="gims-data-chart" id={this.props.id}></div>
        );
    }
}

/*
 * GimsErrorTypeSubcaseCountOwnerTeam proptypes
 */
GimsErrorTypeSubcaseCountOwnerTeam.propTypes = {
    id: PropTypes.string,
}

/*
 * A class to generate Gims SubCase Count By Type
 */
export class GimsSubCaseCountByType extends Component {

    drawCharts(args) {
        let { width, height, data } = args ? args : this.props;
        //console.log("GimsReportcharts");
        let margin = { top: 10, right: 70, bottom: 70, left: 50 };

        var sample = data;
        //var margin = {top: 10, right: 70, bottom: 70, left: 50};
        d3.select(`#${this.props.id}`).selectAll('svg').remove();
        const svg = d3.select(`#${this.props.id}`).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom );

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(sample, (d) => d.count) + 100]);

        const makeYLines = () => d3.axisLeft()
            .scale(yScale)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .call(d3.axisLeft(yScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    // .style('fill-opacity', 1)
                    .style('color', 'black')
            });

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(sample.map((s) => s.errorTitle))
            .padding(dynamicBars(sample))

        chart.append('g')
            .attr('class', 'opacityBlack')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    //.style('fill-opacity', 1)
                    .style('color', 'black')
            });

        chart.append('g')
            .attr('class', 'grid')
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat('')
            )

        var tooltip = d3.select("body").append("div")
            .attr("class", "toolTip")
            .style("display", "none");     

        const barGroups = chart.selectAll()
            .data(sample)
            .enter()
            .append('g')
            .on("mouseover", function(d){tooltip.text(d.count); return tooltip.style("display", "inline");})
            .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
            .on("mouseout", function(){return tooltip.style("display", "none");});

        barGroups
            .append('rect')
            .attr('x', (g) => xScale(g.errorTitle))
            .attr('y', (g) => yScale(g.count))
            .attr('height', (g) => height - yScale(g.count))
            .attr('width', xScale.bandwidth())
            .style('fill', (d) => {
                return d.color;
            })
            .style('cursor','pointer')
            .style('fill-opacity', 1);
         barGroups
             .append('text')
             .attr('class', 'value')
             .attr('x', (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
             .attr('y', (g) => yScale(g.count) - 3)
             .attr('text-anchor', 'middle')
             .text((g) => `${g.count}`); 

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", margin.top)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("Sub-case count");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "translate(" + ((width / 2) + margin.left) + " ," + (height + 60) + ")")
            .style("text-anchor", "middle")
            .text("Case Types");
    }

    componentDidMount() {
        this.drawCharts(null);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("next Props 4", nextProps);
        this.drawCharts(nextProps);
    }
    render() {
        return (
            <div className="gims-data-chart" id={this.props.id}></div>
        );
    }
}

/*
 * GimsSubCaseCountByType proptypes
 */
GimsSubCaseCountByType.propTypes = {
    id: PropTypes.string,
}

/*
 * A class to generate Gims Intake Channel Count 
 */
export class GimsIntakeChannelCount extends Component {

    drawCharts(args) {
        let { width, height, data } = args ? args : this.props;
        //console.log("GimsReportcharts");
        let margin = { top: 10, right: 70, bottom: 70, left: 50 };

        var sample = data;
        //var margin = {top: 10, right: 70, bottom: 70, left: 50};
        d3.select(`#${this.props.id}`).selectAll('svg').remove();
        const svg = d3.select(`#${this.props.id}`).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom  + (sample.length === 0 ? 0 : 80));

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(sample, (d) => d.count) + 100]);

        const makeYLines = () => d3.axisLeft()
            .scale(yScale)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .call(d3.axisLeft(yScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    // .style('fill-opacity', 1)
                    .style('color', 'black')
            });

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(sample.map((s) => s.errorTitle))
            //.padding(dynamicBars(sample))
            .padding(0.5)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    //.style('fill-opacity', 1)
                    .style('color', 'black')
            })
            /*  .selectAll("text")
             .attr("y", 10)
             .attr("x", -10)
             .attr("dy", ".25em")
             .attr("transform", "rotate(-45)")
             .style("text-anchor", "end") */
            .selectAll(".tick text")
            .attr("y", 0)
             .attr("x", -10)
             .attr("dy", ".25em")
             .attr("transform", "rotate(-90)")
             .style("text-anchor", "end")
            //.call(wrap, xScale.bandwidth())

        chart.append('g')
            .attr('class', 'grid')
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat('')
            );
        
        var tooltip = d3.select("body").append("div")
        .attr("class", "toolTip")
        .style("display", "none");     

        const barGroups = chart.selectAll()
            .data(sample)
            .enter()
            .append('g')
            .on("mouseover", function(d){tooltip.text(d.count); return tooltip.style("display", "inline");})
	        .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
	        .on("mouseout", function(){return tooltip.style("display", "none");});

        barGroups
            .append('rect')
            .attr('x', (g) => xScale(g.errorTitle))
            .attr('y', (g) => yScale(g.count))
            .attr('height', (g) => height - yScale(g.count))
            .attr('width', xScale.bandwidth())
            .style('fill', (d) => {
                return d.color;
            })
            .style('cursor','pointer')
            .style('fill-opacity', 1);
        barGroups
            .append('text')
            .attr('class', 'value')
            .attr('x', (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
            .attr('y', (g) => yScale(g.count) - 3)
            .attr('text-anchor', 'middle')
            .text((g) => `${g.count}`);

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", margin.top)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("Sub-case count");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "translate(" + ((width / 2) + margin.left) + " ," + (height + (sample.length === 0 ? 70 : 155)) + ")")
            .style("text-anchor", "middle")
            .text("Channels");
    }

    componentDidMount() {
        this.drawCharts(null);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("next Props 5", nextProps);
        this.drawCharts(nextProps);
    }
    render() {
        return (
            <div className="gims-data-chart" id={this.props.id}></div>
        );
    }
}
/*
 * GimsIntakeChannelCount proptypes
 */
GimsIntakeChannelCount.propTypes = {
    id: PropTypes.string,
}



/*
 * A class to generate Gims Empty agent notes count and agent notes with content count
 */
export class GimsAgentNotes extends Component {

    drawCharts(args) {
        let { width, height, data } = args ? args : this.props;
        //console.log("GimsReportcharts");
        let margin = { top: 10, right: 70, bottom: 70, left: 50 };

        var sample = data;
        //var margin = {top: 10, right: 70, bottom: 70, left: 50};
        d3.select(`#${this.props.id}`).selectAll('svg').remove();
        const svg = d3.select(`#${this.props.id}`).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom);

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(sample, (d) => d.count) + 100]);

        const makeYLines = () => d3.axisLeft()
            .scale(yScale)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .call(d3.axisLeft(yScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    // .style('fill-opacity', 1)
                    .style('color', 'black')
            });

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(sample.map((s) => s.errorTitle))
            .padding(dynamicBars(sample))

        chart.append('g')
            .attr('class', 'opacityBlack')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    //.style('fill-opacity', 1)
                    .style('color', 'black')
            })
            /*  .selectAll("text")
             .attr("y", 10)
             .attr("x", -10)
             .attr("dy", ".25em")
             .attr("transform", "rotate(-45)")
             .style("text-anchor", "end") */
            .selectAll(".tick text")
            .call(wrap, xScale.bandwidth())

        chart.append('g')
            .attr('class', 'grid')
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat('')
            )

        var tooltip = d3.select("body").append("div")
        .attr("class", "toolTip")
        .style("display", "none");     
            

        const barGroups = chart.selectAll()
            .data(sample)
            .enter()
            .append('g')
            .on("mouseover", function(d){tooltip.text(d.count); return tooltip.style("display", "inline");})
	        .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
	        .on("mouseout", function(){return tooltip.style("display", "none");});

        barGroups
            .append('rect')
            .attr('x', (g) => xScale(g.errorTitle))
            .attr('y', (g) => yScale(g.count))
            .attr('height', (g) => height - yScale(g.count))
            .attr('width', xScale.bandwidth())
            .style('fill', (d) => {
                return d.color;
            })
            .style('cursor','pointer')
            .style('fill-opacity', 1);
        barGroups
            .append('text')
            .attr('class', 'value')
            .attr('x', (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
            .attr('y', (g) => yScale(g.count) - 3)
            .attr('text-anchor', 'middle')
            .text((g) => `${g.count}`); 

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", margin.top)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("Agent Notes and ML count");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "translate(" + ((width / 2) + margin.left) + " ," + (height + 70) + ")")
            .style("text-anchor", "middle")
            .text("Agent Notes");
    }

    componentDidMount() {
        this.drawCharts(null);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("next Props 5", nextProps);
        this.drawCharts(nextProps);
    }
    render() {
        return (
            <div className="gims-data-chart" id={this.props.id}></div>
        );
    }
}
/*
 * GimsAgentNotes proptypes
 */
GimsAgentNotes.propTypes = {
    id: PropTypes.string,
}

/*
 * A class to generate Gims ML completed count and manual count
 */
export class GimsMLDetails extends Component {

    drawCharts(args) {
        let { width, height, data } = args ? args : this.props;
        //console.log("GimsReportcharts");
        let margin = { top: 10, right: 70, bottom: 70, left: 50 };

        var sample = data;
        //var margin = {top: 10, right: 70, bottom: 70, left: 50};
        d3.select(`#${this.props.id}`).selectAll('svg').remove();
        const svg = d3.select(`#${this.props.id}`).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom);

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(sample, (d) => d.count) + 100]);

        const makeYLines = () => d3.axisLeft()
            .scale(yScale)

        chart.append('g')
            .attr('class', 'opacityBlack')
            .call(d3.axisLeft(yScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    // .style('fill-opacity', 1)
                    .style('color', 'black')
            });

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(sample.map((s) => s.errorTitle))
            .padding(dynamicBars(sample))

        chart.append('g')
            .attr('class', 'opacityBlack')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            .call((g) => {
                g.selectAll('text')
                    .style("font-size", "10px")
                    .style("font-weight", 600)
                    //.style('fill-opacity', 1)
                    .style('color', 'black')
            })
            /*  .selectAll("text")
             .attr("y", 10)
             .attr("x", -10)
             .attr("dy", ".25em")
             .attr("transform", "rotate(-45)")
             .style("text-anchor", "end") */
            .selectAll(".tick text")
            .call(wrap, xScale.bandwidth())

        chart.append('g')
            .attr('class', 'grid')
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat('')
            )

        var tooltip = d3.select("body").append("div")
        .attr("class", "toolTip")
        .style("display", "none");     
            

        const barGroups = chart.selectAll()
            .data(sample)
            .enter()
            .append('g')
            .on("mouseover", function(d){tooltip.text(d.count); return tooltip.style("display", "inline");})
	        .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
	        .on("mouseout", function(){return tooltip.style("display", "none");});

        barGroups
            .append('rect')
            .attr('x', (g) => xScale(g.errorTitle))
            .attr('y', (g) => yScale(g.count))
            .attr('height', (g) => height - yScale(g.count))
            .attr('width', xScale.bandwidth())
            .style('fill', (d) => {
                return d.color;
            })
            .style('cursor','pointer')
            .style('fill-opacity', 1);
        barGroups
            .append('text')
            .attr('class', 'value')
            .attr('x', (g) => xScale(g.errorTitle) + xScale.bandwidth() / 2)
            .attr('y', (g) => yScale(g.count) - 3)
            .attr('text-anchor', 'middle')
            .text((g) => `${g.count}`); 

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "rotate(-90)")
            .attr("y", margin.top)
            .attr("x", 0 - (height / 2))
            .style("text-anchor", "middle")
            .text("AE, PC, OSF Count");

        svg.append("text")
            .attr("class", "xy-labels")
            .attr("transform", "translate(" + ((width / 2) + margin.left) + " ," + (height + 70) + ")")
            .style("text-anchor", "middle")
            .text("Subcase");
    }

    componentDidMount() {
        this.drawCharts(null);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("next Props 5", nextProps);
        this.drawCharts(nextProps);
    }
    render() {
        return (
            <div className="gims-data-chart" id={this.props.id}></div>
        );
    }
}
/*
 * GimsMLDetails proptypes
 */
GimsMLDetails.propTypes = {
    id: PropTypes.string,
}

/*
 * Functionality to wrap the text
 * based on the data and bar size
 */
function wrap(text, width) {
    text.each(function () {
        var texts = d3.select(this),
        
            words = texts.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = texts.attr("y"),
            dy = parseFloat(texts.attr("dy")),
            tspan = texts.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
            //console.log("textsonar",texts)
        // eslint-disable-next-line 
        while (word = words.pop()) {
            line.push(word)
            tspan.text(line.join(" "))
            if (tspan.node().getComputedTextLength() > width) {
                line.pop()
                tspan.text(line.join(" "))
                line = [word]
                tspan = texts.append("tspan").attr("x", 0).attr("y", y).attr("dy", `${++lineNumber * lineHeight + dy}em`).text(word)
            }
        }
    })
}
function dynamicBars(data) {
    let len = data.length;
    // let paddingData = 0.6;
    let paddingData;
    switch (len) {
        case 1:
            paddingData = 0.8;
            break;
        case 2:
            paddingData = 0.7;
            break;
        case 3:
            paddingData = 0.6;
            break;
        case 4:
            paddingData = 0.5;
            break;
        case 5:
            paddingData = 0.4;
            break;
        case 6:
            paddingData = 0.3;
            break;
        case 7:
            paddingData = 0.2;
            break;
        case 8:
            paddingData = 0.1;
            break;
        case 9:
            paddingData = 0.1;
            break;
        case 10:
            paddingData = 0.1;
            break;
        case 11:
            paddingData = 0.1;
            break;
        case 12:
            paddingData = 0.1;
            break;
        default:
            paddingData = 0.6;
            break;
    }
    // data.length > 6 ? 0.2 : 0.7;
    return paddingData;
}
