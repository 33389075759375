const CommonService = {
	viewDetails: {},


	setViewDetails: (data) => {
		CommonService.viewDetails = data;
	},
	getViewDetails: () => {
		return CommonService.viewDetails;
	}
}
CommonService.roles = [{ name: '-- Select Role --', id: 0, value: '' },
{ name: 'Admin', id: 1, value: 'Admin' },
{ name: 'Call Center Admin', id: 2, value: 'Call Center Admin' },
{ name: 'Data Scientist', id: 3, value: 'Data Scientist' },
{ name: 'Quality Monitor', id: 4, value: 'Quality Monitor' },
{ name: 'Reviewer', id: 5, value: 'Reviewer' },
{ name: 'RWS-translation Vendor', id: 6, value: 'RWS-translation Vendor' }];

CommonService.interactionStatus = [
{ name: 'Assigned', id: 0, value: 'Assigned' },
{ name: 'Completed(Manual)', id: 1, value: 'Completed' },
{ name: 'Completed(Model)', id: 2, value: 'Completed(Model)' },
{ name: 'Error', id: 3, value: 'Error' },
{ name: 'Unassigned', id: 4, value: 'Unassigned' }]

CommonService.assignedTo = [{ name: '--Select--', id: 0, value: '' },
{ name: 'Admin', id: 1, value: 'Admin' },
{ name: 'Call Center Admin', id: 2, value: 'Call Center Admin' },
{ name: 'Model', id: 3,value:'Model' },
{ name: 'Quality Monitor', id: 4, value: 'Quality Monitor' },
{ name: 'Translation Vendor', id: 5, value: 'Translation Vendor' }]

CommonService.associatedTeam = [{ name: '--Select--', id: 0, value: '' },
{ name: 'Team 1', id: 1, value: 'Team 1' },
{ name: 'Team 2', id: 2, value: 'Team 2' },
{ name: 'Global Safty', id: 3, value: 'Global Safty' },
{ name: 'Team 4', id: 4, value: 'Team 4' },
{ name: 'Team 5', id: 5, value: 'Team 5' }];

CommonService.sortRole = (a, b) => {
	if (a.props.children < b.props.children) {
		return -1;
	}
	if (a.props.children > b.props.children) {
		return 1;
	}
	return 0;
}
CommonService.ifrType = [
	{ value: 'AE', id: 1 },
	{ value: 'MI', id: 2 },
	{ value: 'PC', id: 3 },
	{ value: 'RF', id: 4 }]
CommonService.channel = [
	{ value: 'Align', id: 1 },
	{ value: 'Astellas', id: 2 },
	{ value: 'Congress', id: 3 },
	{ value: 'Email', id: 4 },
	{ value: 'eMIR', id: 5 },
	{ value: 'Face-to-Face', id: 6 },
	{ value: 'Fax', id: 7 },
	{ value: 'Mobile Field App', id: 8 },
	{ value: 'Out of Hours', id: 9 },
	{ value: 'PDF Form', id: 10 },
	{ value: 'Phone', id: 11 },
	{ value: 'RAVE', id: 12 },
	{ value: 'Safety Reporting Portal', id: 13 },
	{ value: 'Web Form', id: 14 }]
CommonService.modelTypes = [
	{ value: 'AE', id: 1 },
	{ value: 'OSF', id: 2 },
	{ value: 'PC', id: 3 }]
CommonService.dataModelStatus = [
	{ value: 'active', id: 1 },
	{ value: 'deployed', id: 2 }]
CommonService.countries = [
	{ value: 'Australia', id: 1 },
	{ value: 'Brazil', id: 2 },
	{ value: 'Canada', id: 3 },
	{ value: 'Colombia', id: 4 },
	{ value: 'France', id: 5 },
	{ value: 'Germany', id: 6 },
	{ value: 'Mexico', id: 7 },
	{ value: 'Netherlands', id: 8 },
	{ value: 'Puerto Rico', id: 9 },
	{ value: 'Singapore', id: 10 },
	{ value: 'Spain', id: 11 },
	{ value: 'United Kingdom', id: 12 },
	{ value: 'United States of America', id: 13 },]
export default CommonService;