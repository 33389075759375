import React, { Component } from 'react';
import { Image, ListGroup, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

class Pagination extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      todosPerPage: 10,
      upperPageBound: 3,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      pageBound: 3,
      data: this.props.data,
      pageNumbers: []
    }
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.firstclick = this.firstclick.bind(this);
    this.lastClick = this.lastClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    this.setCurrentTodos = this.setCurrentTodos.bind(this);
    // this.updateCurrentPageNumber = this.updateCurrentPageNumber.bind(this);
  }
  componentDidMount() {
    if (this.props.data && this.props.data.length !== 0) {
      let pageNumbers = []
      for (let i = 1; i <= Math.ceil(this.props.data.length / this.state.todosPerPage); i++) {
        pageNumbers.push(i);
      }
      this.setState({ pageNumbers: pageNumbers })
      const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
      const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
      const currentTodos = this.props.data.slice(indexOfFirstTodo, indexOfLastTodo);
      let totalPage = Math.ceil(this.state.data.length / this.state.todosPerPage);
      if (totalPage === this.state.currentPage) {
        this.setState({ isNextBtnActive: 'disabled' })
      }
      this.props.onChangePage(currentTodos, this.state.currentPage);
    }
  }
  componentDidUpdate(prevProps) {
    // only update chart if the data has changed
    if (prevProps.data !== this.props.data) {
      // this.setState({currentPage:1})
      let pageNumbers = []
      for (let i = 1; i <= Math.ceil(this.props.data.length / this.state.todosPerPage); i++) {
        pageNumbers.push(i);
      }
      this.setState({ pageNumbers: pageNumbers })
      // let totalPage = Math.ceil(this.state.data.length / this.state.todosPerPage);

      this.setState({ currentPage: 1, upperPageBound: 3, lowerPageBound: 0 }, () => {
        const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
        const currentTodos = this.props.data.slice(indexOfFirstTodo, indexOfLastTodo);
        this.setPrevAndNextBtnClass(this.state.currentPage);
        this.props.onChangePage(currentTodos, this.state.currentPage);
      })
    }

  }
  setCurrentTodos() {
    const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
    const currentTodos = this.props.data.slice(indexOfFirstTodo, indexOfLastTodo);
    this.props.onChangePage(currentTodos, this.state.currentPage);
  }
  handleClick(event) {
    let listid = Number(event.target.innerText);
    this.setState({
      currentPage: listid
    }, () => { this.setCurrentTodos() });
    this.setPrevAndNextBtnClass(listid);
  }

  setPrevAndNextBtnClass(listid) {
    let totalPage = Math.ceil(this.props.data.length / this.state.todosPerPage);
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isPrevBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: '' });
    }
    else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
    }
    else if (totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
      this.setState({ isPrevBtnActive: '' });
    }
  }
  firstclick() {
    // this.setState({ currentPage: 1 });
    // this.setState({ upperPageBound: 3 })
    this.setState({ currentPage: 1, upperPageBound: 3, lowerPageBound: 0 }, () => { this.setCurrentTodos() })
    this.setPrevAndNextBtnClass(1);
  }
  lastClick() {
    let totalpages = Math.ceil(this.props.data.length / this.state.todosPerPage)
    this.setState({ currentPage: totalpages });
    this.setState({ upperPageBound: totalpages })
    this.setState({ lowerPageBound: totalpages - 3 }, () => { this.setCurrentTodos() })
    this.setPrevAndNextBtnClass(totalpages);
  }
  btnIncrementClick() {
    this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid }, () => { this.setCurrentTodos() });
    this.setPrevAndNextBtnClass(listid);

  }
  btnDecrementClick() {
    this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid }, () => { this.setCurrentTodos() });
    this.setPrevAndNextBtnClass(listid);

  }
  btnPrevClick() {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - 1 });
      this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound }, () => { this.setCurrentTodos() });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid }, () => { this.setCurrentTodos() });
    this.setPrevAndNextBtnClass(listid);

  }
  btnNextClick() {
    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
      this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid }, () => {
      const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
      const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
      const currentTodos = this.props.data.slice(indexOfFirstTodo, indexOfLastTodo);
      this.props.onChangePage(currentTodos, this.state.currentPage);
    });
    this.setPrevAndNextBtnClass(listid);

  }

  // updateCurrentPageNumber() {
  //   this.firstclick();
  // }

  render() {
    const { currentPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive, pageNumbers } = this.state;
    // const indexOfLastTodo = currentPage * todosPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    // const currentTodos = this.state.data.slice(indexOfFirstTodo, indexOfLastTodo);
    // const pageNumbers = [];
    // for (let i = 1; i <= Math.ceil(data.length / todosPerPage); i++) {
    //   pageNumbers.push(i);
    // }

    const renderPageNumbers = pageNumbers.map(number => {
      if (number === 1 && currentPage === 1) {
        return (
          <ListGroup.Item key={number} className='active list-item-padding' id={number} onClick={this.handleClick}><a id={number} >{number}</a></ListGroup.Item>
        )
      }
      else if ((number < upperPageBound + 1) && number > lowerPageBound) {
        return (
          <ListGroup.Item key={number} className={number === currentPage ? 'active list-item-padding' : 'list-item-padding'} id={number} onClick={this.handleClick}><a id={number} >{number}</a></ListGroup.Item>
        )
      }
      return null;
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = <ListGroup.Item className='list-item-padding' onClick={this.btnIncrementClick}><a > &hellip; </a></ListGroup.Item>
    }
    let pageDecrementBtn = null;

    if (lowerPageBound >= 1) {
      pageDecrementBtn = <ListGroup.Item className='list-item-padding' onClick={this.btnDecrementClick}><a> &hellip; </a></ListGroup.Item>
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === 'disabled') {
      renderPrevBtn = <ListGroup.Item className={isPrevBtnActive}><span id="btnPrev"> <Image src="/Images/ico-arrow-left-disabled.png" className='arrow-left-right'></Image> </span></ListGroup.Item>
    }
    else {
      renderPrevBtn = <ListGroup.Item className={isPrevBtnActive} onClick={this.btnPrevClick}><a id="btnPrev" ><Image src="/Images/ico-arrow-left.png" className='arrow-left-right'></Image> </a></ListGroup.Item>
    }
    let renderNextBtn = null;
    if (isNextBtnActive === 'disabled') {
      renderNextBtn = <ListGroup.Item className={isNextBtnActive}><span id="btnNext"> <Image src="/Images/ico-arrow-right-disabled.png" className='arrow-left-right'></Image> </span></ListGroup.Item>
    }
    else {
      renderNextBtn = <ListGroup.Item className={isNextBtnActive} onClick={this.btnNextClick}><a id="btnNext"> <Image src="/Images/ico-arrow-right.png" className='arrow-left-right'></Image> </a></ListGroup.Item>
    }
    let frstpage = null;
    if (isPrevBtnActive === 'disabled') {
      frstpage = <ListGroup.Item className={isPrevBtnActive}><span id="btnNext"><Image src="/Images/ico-arrow-left-end-disabled.png" className='arrow-left-right-end'></Image> </span></ListGroup.Item>
    } else {
      frstpage = <ListGroup.Item className={isPrevBtnActive} onClick={this.firstclick}><a> <Image src="/Images/ico-arrow-left-end.png" className='arrow-left-right-end'></Image> </a></ListGroup.Item>
    }
    let lastpage = null;
    if (isNextBtnActive === 'disabled') {
      lastpage = <ListGroup.Item className={isNextBtnActive}><span id="btnNext"> <Image src="/Images/ico-arrow-right-end-disabled.png" className='arrow-left-right-end'></Image> </span></ListGroup.Item>
    } else {
      lastpage = <ListGroup.Item className={isNextBtnActive} onClick={this.lastClick}><a ><Image src="/Images/ico-arrow-right-end.png" className='arrow-left-right-end'></Image> </a></ListGroup.Item>
    }
    return (
      <div>
        <Row className='padding-top'>
          <Col><ListGroup className="pageNumbers">
            {frstpage}
            {/* {renderfirst} */}
            {renderPrevBtn}
            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            {renderNextBtn}
            {lastpage}
          </ListGroup></Col>
          {/* <Col ><span className='verticalAlign'>Page {this.state.currentPage} of {Math.ceil(this.state.interactions.length / this.state.todosPerPage)} ({errors.length} Records)</span></Col> */}

        </Row>
      </div>
    )
  }
}
Pagination.propTypes = {
  data: PropTypes.array,
  onChangePage: PropTypes.func


}

export default Pagination;