

import React, { Component } from 'react';
import './DataModelLineChart.css';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
// import moment from 'moment';

class DataModelLineChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rendered: false,
      check: {},

    };
  }

  compare(a, b) {
    if (a.timestamp < b.timestamp) {
      return -1;
    }
    if (a.timestamp > b.timestamp) {
      return 1;
    }
    return 0;
  }

  minLine(data, Limit) {
    let minData = d3.min(data, (d) => d.value);
    let minLowerLimit = d3.min(Limit.lowerLimit, (d) => d.value);
    //console.log("minData", minData);
    //console.log("minLowerLimit", minLowerLimit);
    let details = Math.min(minData,minLowerLimit)
    if(details<1.0) {
      return 0.0;
    } else {
      return details-0.1
    }
    
  }
  maxLine(data) {
    let maxData = d3.max(data, (d) => d.value);
    //console.log("maxData", maxData);
    if (maxData < 1.0) {
      return 1.1;
    }
    return maxData + 0.1;
  }
  lineChart(args) {
    //console.log("this.props",this.props);
    // 2. Use the margin convention practice 
    let { data, width, height } = args ? args : this.props
    // console.log(JSON.stringify(this.props),"checkdata");
    var margin = { top: 10, right: 70, bottom: 70, left: 50 };
    //   data.forEach(function(d) {
    //     d.date = new Date(d.date * 1000);
    // });
    // The number of datapoints
    // var n = 21;
    d3.select(`#${this.props.id}`).selectAll('svg').remove();
    // d3.select(".datamodel-charts").selectAll("div").remove();
    d3.select(`#${this.props.id}`).selectAll('canvas').remove();
    //console.log(JSON.stringify("data : " + data));uy6
    // 5. X scale will use the index of our data
    var xScale = d3.scaleTime()
      .range([0, width])
      .domain(d3.extent(data, function (d) { return (d.timestamp); }))
    // .domain([0, n-1]) // input
    // output

    // 6. Y scale will use the randomly generate number 
    var yScale
    if(this.props.lowerLimit.length>0) {
      //console.log("props check",this.props)
     yScale = d3.scaleLinear()
      //.domain([0, 1.5])
      // .domain([0, 1]) // input 
      .range([height, 0])
      .domain([this.minLine(data,this.props),this.maxLine(data)])
      // .domain([d3.min(data, (d) => d.value) > 0 ? d3.min(data, (d) => d.value) - 0.1 : 0, this.maxLine(data)])
    } else if(args && args.lowerLimit.length>0) {
       yScale = d3.scaleLinear()
      //.domain([0, 1.5])
      // .domain([0, 1]) // input 
      .range([height, 0])
      .domain([this.minLine(data,args),this.maxLine(data)])
    }

    // 7. d3's line generator.value
    var line = d3.line()
      .x(function (d) { return xScale(d.timestamp); }) // set the x values for the line generator
      .y(function (d) { return yScale(d.value); }) // set the y values for the line generator 
      .curve(d3.curveMonotoneX) // apply smoothing to the line

    // 8. An array of objects of length N. Each object has key -> value pair, the key being "y" and the value is a random number
    var dataset = data;

    // 1. Add the SVG to the page and employ #2
    var svg = d3.select(`#${this.props.id}`).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    var div = d3.select(".datamodel-charts").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    // 3. Call the x axis in a group tag
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale).tickFormat(this.generateXaxisLabel))
      .selectAll("text")
      .attr("transform", "rotate(30)")
      .style("font-size", "10px")
      .style("text-anchor", "start"); // Create an axis component with d3.axisBottom

    // 4. Call the y axis in a group tag
    svg.append("g")
      .attr("class", "y axis")
      .call(d3.axisLeft(yScale)); // Create an axis component with d3.axisLeft

    // 9. Append the path, bind the data, and call the line generator 
    svg.append("path")
      .datum(dataset) // 10. Binds data to the line 
      .attr("class", "line") // Assign a class for styling 
      .style("stroke", this.props.stroke)
      .attr("d", line); // 11. Calls the line generator 

    // 12. Appends a circle for each datapoint 
    svg.selectAll(".dot")
      .data(dataset)
      .enter().append("circle") // Uses the enter().append() method
      .attr("class", "dot") // Assign a class for styling
      .attr("cx", function (d) { return xScale(d.timestamp) })
      .attr("cy", function (d) { return yScale(d.value) })
      .attr("r", 5)
      .style("fill", "white")
      .style("stroke", this.props.stroke)
      .style("stroke-width", "2px")
      .on("mouseover", function (d) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html(d.version + " - " + d.value)
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      })
      .on("mouseout", function () {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });

    // define the 2nd line
    // var valueline2 = d3.line()
    //   .x(function (d) { return xScale(d.timestamp); })
    //   .y(function (d) { return yScale(d.value); });

    /* svg.append("path")
      .data([this.props.lowerLimit])
      .attr("class", "line")
      .style("stroke", "#CCCCCC")
      .style("stroke-width", "1px")
      .attr("d", valueline2); */
      let checkprops;
      if(args && args.lowerLimit.length>0) {
        checkprops = args;
        svg.append("g")
        .attr("transform", "translate(0, "+yScale(checkprops.lowerLimit[0].value)+")")
        .append("line")
        .attr("x2", width)
        .attr("class", "line")
        .style("stroke", "#CCCCCC")
        .style("stroke-width", "1px");
      } else if(this.props.lowerLimit.length>0) {
       checkprops = this.props
     
      //  = args && args.lowerLimit.length>0 ? args: this.props.hasOwnProperty('sensitivity');
       svg.append("g")
      .attr("transform", "translate(0, "+yScale(checkprops.lowerLimit[0].value)+")")
      .append("line")
      .attr("x2", width)
      .attr("class", "line")
      .style("stroke", "#CCCCCC")
      .style("stroke-width", "1px");
    }
    /* svg.append("path")
      .data([this.props.upperLimit])
      .attr("class", "line")
      .style("stroke", "#CCCCCC")
      .style("stroke-width", "1px")
      .attr("d", valueline2); */
      let checkpropsupperLimit;
      if(args && args.upperLimit.length>0) {
        checkpropsupperLimit = args;
        svg.append("g")
        .attr("transform", "translate(0, "+yScale(checkpropsupperLimit.upperLimit[0].value)+")")
        .append("line")
        .attr("x2", width)
        .attr("class", "line")
        .style("stroke", "#CCCCCC")
        .style("stroke-width", "1px");
      } else if(this.props.upperLimit.length>0) {
        checkpropsupperLimit = this.props
     
      // let checkpropsupperLimit = args && args.upperLimit.length>0 ? args: this.props.hasOwnProperty('sensitivity');
         svg.append("g")
      .attr("transform", "translate(0, "+yScale(checkpropsupperLimit.upperLimit[0].value)+")")
      .append("line")
      .attr("x2", width)
      .attr("class", "line")
      .style("stroke", "#CCCCCC")
      .style("stroke-width", "1px");
    }
    /* svg.append("path")
      .data([this.props.median])
      .attr("class", "line")
      .style("stroke", "#CCCCCC")
      .style("stroke-width", "1px")
      .style("stroke-dasharray", "1,3")
      .attr("d", valueline2); */
      let checkpropsmedian;
      if(args && args.median.length>0) {
        checkpropsmedian = args;
        svg.append("g")
        .attr("transform", "translate(0, "+yScale(checkpropsmedian.median[0].value)+")")
        .append("line")
        .attr("x2", width)
        .attr("class", "line")
        .style("stroke", "#CCCCCC")
        .style("stroke-width", "1px")
        .style("stroke-dasharray", "1,3");
      } else if(this.props.median.length>0) {
        checkpropsmedian = this.props
        svg.append("g")
        .attr("transform", "translate(0, "+yScale(checkpropsmedian.median[0].value)+")")
        .append("line")
        .attr("x2", width)
        .attr("class", "line")
        .style("stroke", "#CCCCCC")
        .style("stroke-width", "1px")
        .style("stroke-dasharray", "1,3");
      }
      // let checkpropsmedian =args && args.median.length>0 ? args: this.props.hasOwnProperty('sensitivity');
       
      let upperLimit = args ? args.upperLimit: this.props.upperLimit;
    if (upperLimit.length > 0) {
      svg.append("text")
        .data([upperLimit])
        .attr("transform", "translate(" + (width + 10) + "," + yScale(upperLimit[0].value) + ")")
        .attr("text-anchor", "start")
        .style("font-size", "10px")
        .text("Upper Limit");
    }
    let lowerLimit = args ? args.lowerLimit: this.props.lowerLimit;
    if (lowerLimit.length > 0) {
      svg.append("text")
        .data([lowerLimit])
        .attr("transform", "translate(" + (width + 10) + "," + yScale(lowerLimit[0].value) + ")")
        .attr("text-anchor", "start")
        .style("font-size", "10px")
        .text("Lower Limit");
    }
    let median = args ? args.median: this.props.median;
    if (median.length > 0) {
      svg.append("text")
        .data([median])
        .attr("transform", "translate(" + (width + 10) + "," + yScale(median[0].value) + ")")
        .attr("text-anchor", "start")
        .style("font-size", "10px")
        .text("Median");
    }
    svg.append("text")
      .attr("class", "xy-labels")
      .attr("transform", "rotate(-90)")
      .attr("y", -40)
      .attr("x", 0 - (height / 2))
      //.attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Limit");
    svg.append("text")
      .attr("class", "xy-labels")
      .attr("y", 10)
      .attr("transform", "translate(" + (width / 2) + " ," + (height + 50) + ")")
      .style("text-anchor", "middle")
      .text(this.props.xAxisText);
      svg.exit().remove();
      // div.exit().remove();
  }
  generateXaxisLabel(value) {
    let date = new Date(value * 1000);
    // console.log("date : " + date);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear();
  }

  componentDidMount() {
    this.lineChart(null);
    // this.interval = setInterval(() => {this.lineChart(null) }), 1000);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // d3.selectAll(".datamodel-linecharts").remove();
    //console.log("next Props", nextProps);
    // if (this.props.upperLimit !== nextProps.upperLimit || this.props.lowerLimit !== nextProps.lowerLimit || this.props.median !== nextProps.median ) {
      this.lineChart(nextProps);
     

    // }
   
}
  render() {

    return (
      <div className="datamodel-linecharts" id={this.props.id}></div>
    )
  }
}
DataModelLineChart.propTypes = {
  xAxisText: PropTypes.string,
  data: PropTypes.object,
  median: PropTypes.array,
  lowerLimit: PropTypes.array,
  upperLimit: PropTypes.array,
  id: PropTypes.string,
  stroke: PropTypes,
  width: PropTypes,
  height: PropTypes,
}

export default DataModelLineChart;