import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
// import RoleConstant from './constants/RoleConstant';
// import utils from './utils';
import './index.css';

import { App } from './components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import './theme.css';

ReactDOM.render(<BrowserRouter><App/></BrowserRouter>, document.getElementById('root'));
