import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

class ConfirmationModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
        
    }

    render() {
       return(
        <React.Fragment>
        <Modal
          dialogClassName="deletionModal"
          show={this.props.showModal}
          onHide={this.props.handleCloseModal}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            <div>{this.props.message}</div>
          </Modal.Body>
          <Modal.Footer className='confirmation-footer'>
            <Button onClick={this.props.handleCloseModal} className='clear'>
              No
          </Button>
            <Button active onClick={this.props.navigate}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        </React.Fragment>

       ) ;
    }
}

ConfirmationModal.propTypes={
    message:PropTypes.string,
    showModal:PropTypes.bool,
    handleCloseModal:PropTypes.func,
    navigate:PropTypes.func
}

export default ConfirmationModal;