import React, { Component } from 'react';
import './ViewDetails.css';
import { Row, Col, Modal, Form, Button,Image } from 'react-bootstrap';
import { Growl } from 'primereact/growl';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
import { FileUploader } from '..';
import RoleConstant from './../../constants/RoleConstant';
import MessageConstant from './../../constants/MessageConstant';
import PropTypes from 'prop-types';
import PopupModels from './../PopupModels/PopupModels';
import ConfirmationModal from './../PopupModels/ConfirmationModal'
import AgentNotesPopup from './AgentNotesPopup';
import EditErrorDetails from '../ErrorList/EditErrorDetails';
import EditScoreCard from '../ScoreCard/EditScoreCard';
import RouterConstant from '../../constants/RouterConstant';
import Moment from 'react-moment';
import MultiSelect from "@khanacademy/react-multi-select";
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';





// import { Header } from '../Header/Header';
// import {Header } from '..';


// function goBack(props) {
//     console.log(props);
//      props.history.push('/home');

// }
/**
* A class that can return some html
*@return {Object} .
*@param {object} props
*/
class ViewDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.interactiondetails[0],
      subcaseNumber: this.props.interactiondetails[0].subcaseNumber,
      AE_Cap_IRPC_Match: '',
      AE_Cap_IRPC_Match_QualityMonitorQC: '',
      AEID_Q2_AE_Missed: '',
      AEID_Q2_AE_Missed_QualityMonitorQC: '',
      AE_Q2_HCP: '',
      AE_Q2_HCP_QualityMonitorQC: '',
      AE_Q3_IPRC: '',
      AE_Q3_IPRC_QualityMonitorQC: '',
      PCID_Q2_Missed: '',
      PCID_Q2_Missed_QualityMonitorQC: '',
      AE_Cap_IRPC_Match_Amgen: '',
      AE_Cap_IRPC_Match_Error_Reconciliation: '',
      AEID_Q2_AE_Missed_Amgen: '',
      AEID_Q2_AE_Error_Reconciliation: '',
      AE_Q2_HCP_Amgen: '',
      AE_Q2_HCP_Error_Reconciliation: '',
      AE_Q3_IPRC_Amgen: '',
      AE_Q3_IPRC_Error_Reconciliation: '',
      PCID_Q2_Missed_Error_Reconciliation: '',
      PC_Q1_IPRC_Match_Amgen: '',
      PCID_Q2_Missed_Amgen: '',
      comments: '',
      qualityMonitorQCBy: '',
      QMAssociate: '',
      show: false,
      team: '',
      addTeamModel: false,
      routeForReviewModel: false,
      routeToCallCenterModel: false,
      translateModel: false,
      createError: false,
      addScoreCard: false,
      caseType: this.props.interactiondetails[0].caseType,
      editscorecard: false,
      scorecardDetails: {},
      viewAttachment: this.props.interactiondetails[0].attachments,
      viewStatus: this.props.interactiondetails[0].status,
      assignTeam: this.props.interactiondetails[0].assignedTo,
      hide: true,
      showAddUserMsg: false,
      errorDetails: {},
      editError: false,
      agentNotesPopupModel: false,
      translateConfirmation: false,
      deleteConfirmation : false,
      deleteModel: false,
      adminConfirmation: false,
      routeConfirmation:false,
      translate:false,

      showDatasetNameInput: false,
      datasetName: [],
      datasetType: '',
      role: '',
      messageForAttachment: '',
      translationType: false,
      anChecked: false,
      attachmentChkd: false,
      selected: [],
      attachmentData:[],
      showIntMsg: false,
    }
    this.isFormValid = false;
    this.goBack = this.goBack.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.changeAssociatedTeam = this.changeAssociatedTeam.bind(this);
    this.handleAddTeamShow = this.handleAddTeamShow.bind(this);
    this.onDrop = this.onDrop.bind(this);

    this.showDatasetNameInput = this.showDatasetNameInput.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleDatasetNameInputClose = this.handleDatasetNameInputClose.bind(this);
    this.performFileUpload = this.performFileUpload.bind(this);
    this.hideDatasetNameInput = this.hideDatasetNameInput.bind(this);

    this.onTranslate = this.onTranslate.bind(this);
    this.onRouteForReview = this.onRouteForReview.bind(this);
    this.showCreateError = this.showCreateError.bind(this);
    this.showAddScoreCard = this.showAddScoreCard.bind(this);
    this.showEditScoreCard = this.showEditScoreCard.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.showEditError = this.showEditError.bind(this);
    this.hideEditError = this.hideEditError.bind(this);
    this.handleAgentNotes = this.handleAgentNotes.bind(this);
    // this.showAgentHtmlText = this.showAgentHtmlText.bind(this);
    this.handleAdminConfirmation = this.handleAdminConfirmation.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleRouteConfirmation=this.handleRouteConfirmation.bind(this);
    this.handleDeleteConfirmation=this.handleDeleteConfirmation.bind(this);
    this.deleteAttachment=this.deleteAttachment.bind(this);
    this.handleTranslationTypeClose = this.handleTranslationTypeClose.bind(this);
    this.onTranslationConfirm = this.onTranslationConfirm.bind(this);
    this.onSelectTypeChanged = this.onSelectTypeChanged.bind(this);
    this.onTeamChange = this.onTeamChange.bind(this);
    this.handleAddTeamClose=this.handleAddTeamClose.bind(this);
    this.handleTranslateClose=this.handleTranslateClose.bind(this);
    this.handleDeletionClose=this.handleDeletionClose.bind(this);
    this.handleRouteReviewClose=this.handleRouteReviewClose.bind(this);
    
  }


  onTeamChange(e) {
    e.preventDefault();
  //  let teams= this.state.team;
  //  teams= e.target.value;
   this.setState({ team: e.target.value});

   if (this.state.team !== '') this.isFormValid = true;
   else this.isFormValid = false;

  }

  handleAgentNotes(){
    this.setState({
      agentNotesPopupModel: true
     })
  }
  goBack() {
    let homeUrl = utils.getURL(RouterConstant.HOME_LINK);
    window.location.hash = homeUrl;
    this.props.hideViewDetails();
    //window.location.reload();
  }
  downloadDataset(name,filename) {
    const data = {
      attachmentName: name,
      filename:filename
    };
    utils.makeRequest(this, EndpointConstant.DOWNLOAD_ATTACHMENT, data, function (result) {

      //console.log(JSON.stringify(result));

      window.open(result.url, 'Download');

    });
  }

  deleteAttachment(attachmentId) {
    const data = {
      id: attachmentId
    };
    const { viewAttachment } = this.state;
    let self = this;
    utils.makeRequest(this, EndpointConstant.DELETE_ATTACHMENT, data, function (result) {
      //console.log(result);
      // if(result.error===false)
      // {
      //   let attachments=result.attachmentList
      //   self.setState({viewAttachment:attachments});

      // }
      let attachments = viewAttachment.filter(attachment => attachment.id !== attachmentId);
      //console.log("delete result", attachments);
      self.setState({ viewAttachment: attachments , deleteConfirmation:false, deleteModel:true})

    });
  }


  handlesamplingClose(){
    this.setState({agentNotesPopupModel: false});
  }

  changeAssociatedTeam(event) {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
    if (value !== '') this.isFormValid = true;
    else this.isFormValid = false;
  }

  handleClose() {
    this.setState({
      show: false
    });
  }

  handleShow() {
    this.setState({
      show: true,
      adminConfirmation:false
    });
  }

  handleAdminConfirmation(id) {
    this.setState({ adminConfirmation: !this.state.adminConfirmation, id: id })

  }

  handleAddTeamShow(event) {
    event.preventDefault();
    let self = this;
    let viewData = this.props.interactiondetails[0];
    let role=this.state.role;
    let data = {
      id: viewData.id

    }
    if (role==="CallCenterAdmin") {
      data.type = 'ADMIN_ASSIGN_TO_CALLCENTERADMIN'
      data.assignedTo = this.state.team.value
    }
    if (role==="QualityMonitor") {
      data.type = 'ADMIN_ASSIGN_TO_QUALITYMONITOR'
      data.assignedTo = this.state.role
    }

    //console.log("viewData", data);
    utils.makeRequest(this, EndpointConstant.UPDATE_INTERACTION, data, function (result) {
      if (result.hasOwnProperty("errorCode") && result.errorCode === 'CCU_400') {
        self.setState({ showAddUserMsg: true });
        return;
      }
      if (result.hasOwnProperty("errorCode") && result.errorCode === 'INT_ALREADY_ASSIGNED') {
        self.setState({ showIntMsg: true, show: false});
        // return;
      }
      //console.log("resultsss", result);
      if (result.error === false) {
        // self.refs.header.showAlertDialog(MessageConstant.USER_NOTIFICATION, popupTitle);
        self.setState({ show: false, addTeamModel: true, viewStatus: 'Assigned', assignTeam: data.assignedTo }, () => {
          self.props.updateIntercations(viewData, self.state.team.value || self.state.role)
        });
      }

    });
  }


  handleAddTeamClose(event) {
    event.preventDefault();
    let viewData = { ...this.state.viewData };
    viewData.assignedTo = this.state.team;
    this.setState({
      addTeamModel: false,
      viewData
    });
  }

  onDrop(result) {
    //console.log("onDrop", result);
    this.setState({ viewAttachment: result })
  }

  changeHandler(e,i) {
    e.preventDefault();
    let datasetName=this.state.datasetName;
    datasetName[i]=e.target.value;
    this.setState({ datasetName });
  }
  showDatasetNameInput(id, files, fileType) {
    let fileName = [];
    if (fileType) {

     fileName = files.map((data)=>{
        return (
          data.name.split('.')[0]
        )
      });
      this.setState({
        datasetName: fileName,
        showDatasetNameInput: true,
        currentDatasetInput: 'upload',
        files: files,
        fileType,
        id: id,
      });
    } else {
      this.setState({
        datasetName: files.split('.')[0],
        datasetType: files.split('.')[1],
        showDatasetNameInput: true,
        currentDatasetInput: 'editName',
        datasetId: id,

      });
    }
  }

  handleDatasetNameInputClose() {
    let self = this;
    let fileUpload = true;
    // datasetName = this.state.datasetName;
    this.state.datasetName.forEach((emptyFile)=>{
      if (emptyFile.length === 0 || emptyFile==undefined) {
        // self.refs.header.showAlertDialog("Please enter File name");
        //alert("Please enter File name");
        this.growl.show({severity:'error', summary: 'Error', detail:`Please enter File name`, life: 5000});
        fileUpload = false;
        return;
      }
    });

    let datasetNameExist = false;
  if(fileUpload){
    this.state.viewAttachment.forEach((data) => {
      // datasetName.push(data.attachmentPath.split('.')[0]);
      const datasetName = data.attachmentName;
      self.state.datasetName.forEach((datasetDetails)=>{
        if (datasetName.toString().toLowerCase() === datasetDetails.toLowerCase()) {
          datasetNameExist = true;
        }
      })


    });
  }
    if (datasetNameExist) {
      // self.refs.header.showAlertDialog("File name already exist!");
      //alert("File name already exist!");
      this.growl.show({severity:'error', summary: 'Error', detail:`File name already exist!`, life: 5000});
      return;
    }
    if (this.state.currentDatasetInput === 'upload') {
      if(fileUpload) {
      this.performFileUpload(this.state.files, this.state.fileType, this.state.id);
    }

    } else {
      const data = {
        id: this.state.datasetId,
        name: this.state.datasetName + '.' + this.state.datasetType
      };
      this.setState({
        showDatasetNameInput: false,
      });
      utils.makeRequest(this, EndpointConstant.UPDATE_DATASET_NAME, data, function (result) {
        //console.log(JSON.stringify(result));
        self.onDrop(result.attachmentList);
        self.setState({
          successDialog: true,
          successMessageContent: 'Your Dataset name has been updated successfully',
          datasetName: ''
        });
      });
    }
  }

  performFileUpload(files, fileType, id) {
    let self = this;
    this.setState({
      showDatasetNameInput: false,
    });
    files.forEach((fileDetails,index)=>{
      fileType.forEach((fileTypesData,i)=>{
        if(i===index) {
        utils.uploadFiles(this, fileDetails, fileTypesData, id, function (result) {
          //console.log("viewdetailsattachment",result);
          self.onDrop(result.attachmentList);
          if(i===fileType.length-1) {
            self.setState({
              successDialog: true,
              successMessageContent: 'Your File is uploaded successfully',
            });
          }
        }, this.state.datasetName[i], EndpointConstant.ADD_ATTACHMENT);
      }
      });

    });

  }

  hideDatasetNameInput() {
    this.setState({ showDatasetNameInput: false })
  }

  closeDialogBox() {
    this.setState({
      successDialog: false,
    });
  }

  enableWorkflow() {
    this.setState({
      workflow: true
    })
  }

  closeWorkFlowBox() {
    this.setState({
      workflow: false,
    });
  }

  onTranslationConfirm(event){
    event.preventDefault();
    this.setState({
      translateConfirmation: false,
      translationType: true
    });
  }

  onTranslate(event) {
    //console.log("on translate");
    event.preventDefault();
    let self = this;
    let viewData = this.props.interactiondetails[0];
    const {viewAttachment, attachmentData} = this.state;
    let attachedData = [];
    if(viewAttachment.length > 0){
      viewAttachment.map((transData)=>{
        if(attachmentData.indexOf(transData.id) > -1) {
          attachedData.push(transData)
        }
      })
    }
    let data = {
      id: viewData.id,
      type: 'QUALITYMONITOR_ASSIGN_TO_TRANSLATIONVENDOR',
      assignedTo: RoleConstant.TranslationVendor,
      isTranslationNeeded: true,
      translationList: {
        isAgentNotes: this.state.anChecked,
        attachments: attachedData
      }
    }
    //console.log("viewData", data);
    utils.makeRequest(this, EndpointConstant.UPDATE_INTERACTION, data, function (result) {
      //console.log("resultsss", result);
      if (result.error === false) {
        self.setState({ translationType: false, translateModel: true,translateConfirmation: false, assignTeam: data.assignedTo },()=>{
          self.props.updateIntercations(viewData,self.state.assignTeam)
        });
      }

    });
  }

  handleConfirmation(id) {
    this.setState({ translateConfirmation: !this.state.translateConfirmation, id: id })
  }

  handleDeleteConfirmation(id) {
    this.setState({ deleteConfirmation: !this.state.deleteConfirmation, deleteId: id })

  }

  handleTranslationTypeClose() {
    this.setState({ translationType: !this.state.translationType, anChecked: false, attachmentChkd: false })
  }


  handleRouteConfirmation(id) {
    this.setState({ routeConfirmation: !this.state.routeConfirmation, id: id })

  }

  handleDeleteClose() {
    this.setState({ errorDeleteSuccess: false })
  }

  handleTranslateClose(event) {
    event.preventDefault();
    /* let viewData = { ...this.state.viewData };
    viewData.assignedTo = this.state.team; */
    this.setState({
      translateModel: false
    });
  }

  onSelectTypeChanged(event) {
    //event.preventDefault();
    const {name,checked} = event.target;
    //console.log("event",event.target.checked)
    this.setState({
      [name]:checked
    },()=>{
      //console.log("after update state",this.state.anChecked, this.state);
    })
  }


  handleDeletionClose(event) {
    event.preventDefault();
    this.setState({
    deleteModel: false
    });
  }

  onRouteForReview(event) {
    //console.log("on route for review");
    //CALLCENTERADMIN_ASSIGN_TO_QUALITYMONITOR
    //QUALITYMONITOR_ASSIGN_TO_TRANSLATIONVENDOR
    //TRANSLATIONVENDOR_ASSIGN_TO_QUALITYMONITOR
    event.preventDefault();
    let self = this;
    let viewData = this.props.interactiondetails[0];
    let data = {
      id: viewData.id,
      // type: utils.getSessionItem('role') === RoleConstant.CallCenterAdmin ? 'CALLCENTERADMIN_ASSIGN_TO_QUALITYMONITOR' : 'TRANSLATIONVENDOR_ASSIGN_TO_QUALITYMONITOR',
      // assignedTo: RoleConstant.QualityMonitor
    }
    if (utils.getSessionItem('role') === RoleConstant.CallCenterAdmin) {
      data.type = 'CALLCENTERADMIN_ASSIGN_TO_QUALITYMONITOR'
      data.assignedTo = RoleConstant.QualityMonitor
    }
    if (utils.getSessionItem('role') === RoleConstant.TranslationVendor) {
      data.type = 'TRANSLATIONVENDOR_ASSIGN_TO_QUALITYMONITOR'
      data.assignedTo = RoleConstant.QualityMonitor
    }
    if (utils.getSessionItem('role') === RoleConstant.QualityMonitor) {
      data.type = 'QUALITYMONITOR_ASSIGN_TO_CALLCENTERADMIN'
      data.assignedTo = RoleConstant.CallCenterAdmin
    }
    // console.log("viewData", data);
    utils.makeRequest(this, EndpointConstant.UPDATE_INTERACTION, data, function (result) {
      //console.log("resultsss", result);
      if (result.error === false) {
        self.setState({ routeForReviewModel: true,routeConfirmation: false, hide: false, assignTeam: data.assignedTo },()=>{
          self.props.updateIntercations(viewData,self.state.assignTeam)
         });
      }

      if (result.error === true && result.errorCode === "CCU_400") {
        self.setState({ routeToCallCenterModel: true, routeConfirmation: false, messageForAttachment:MessageConstant.CCU_400 })
      } else if(result.error === true && result.errorCode === "C_NO_ATTACHMENT_PERMISSION") {
        self.setState({ routeToCallCenterModel: true, routeConfirmation: false ,messageForAttachment:MessageConstant.NO_ATTACH_PERMISSION});
      }


    });
  }

  handleRouteReviewClose(event) {
    event.preventDefault();
    /* let viewData = { ...this.state.viewData };
    viewData.assignedTo = this.state.team; */
    this.setState({
      routeForReviewModel: false
    });
  }

  handleCallCenterClose() {
    /* let viewData = { ...this.state.viewData };
    viewData.assignedTo = this.state.team; */
    this.setState({
      routeToCallCenterModel: false,
      showIntMsg: false
    });
  }




  hideEditError() {
    this.setState({ editError: false }, () => {
      this.props.setLinkInactive('error');
    })
  }
  showCreateError() {
    this.setState({ createError: !this.state.createError }, () => {
      if (this.state.createError) {
        this.props.setLinkActive('error');
      } else {
        this.props.setLinkInactive('error');
      }
    })
  }
  showAddScoreCard() {
    this.setState({ addScoreCard: !this.state.addScoreCard }, () => {
      if (this.state.addScoreCard) {
        this.props.setLinkActive('scorecard');
      } else {
        this.props.setLinkInactive('scorecard');
      }
    })

  }
  showUserRegistration() {
    let addUserURL = utils.getURL(RouterConstant.USERS_LINK) + '?showAddUser';
    window.location.hash = addUserURL;
  }
  showDetails() {

    this.setState({ editscorecard: !this.state.editscorecard })
  }
  showEditScoreCard(scorecardId) {
    let data = {
      id: scorecardId
    }
    let self = this
    // this.setState({show:true})scorecardId
    utils.makeRequest(this, EndpointConstant.SCORECARD_DETAILS, data, function (result) {
      const scorecardDetails = result.scorecardDetails[0];
      scorecardDetails.datasetName=self.state.datasetNameView;
      // self.loading = false;
      self.setState({ scorecardDetails: scorecardDetails, editscorecard: true }, () => self.props.setLinkActive())

    })
    // this.setState({editscorecard:!this.state.editscorecard})
  }
  showEditError() {
    let self = this
    let data = {
      id: this.state.errorId
    }
    // this.setState({show:true})
    utils.makeRequest(this, EndpointConstant.ERROR_DETAILS, data, function (result) {
      const errors = result.errorsDetails[0];
      self.loading = false;
      self.setState({ errorDetails: errors }, () => {
        self.props.setLinkActive('error')
        self.setState({ editError: true })

      }
      )

    })
  }

  handleRoleValue(role)
  {
    switch(role)
    {
      case 'SYSTEM':
        return 'System';
      case 'ADMIN':
        return 'Admin';
      case 'QUALITYMONITOR':
        return 'Quality Monitor';
      case 'CALLCENTERADMIN':
        return 'Call Center Admin';
      case 'TRANSLATIONVENDOR' :
        return 'Translation Vendor';
      case 'DATASCIENTIST':
        return 'Data Scientist';
      default:
        return {};
    }
  }

  componentDidMount() {
    let viewData = this.props.interactiondetails[0];
    //console.log("viewData",viewData);
    if(viewData.agentNotes!==''){
      var iframeAgentNotes = viewData.agentNotes === 'null' ? '' : viewData.agentNotes
      document.querySelector('iframe')
      .contentDocument.write(iframeAgentNotes);
      if(iframeAgentNotes != '' && iframeAgentNotes != null){
        var iframeStyle = document.getElementById('style-iframe');
        iframeStyle.contentDocument.body.style.fontFamily = "Helvetica";
        iframeStyle.contentDocument.body.style.fontSize = "15px";
        iframeStyle.contentDocument.body.style.color= '#333333';
      }
    }
  }
  render() {
    //console.log("tttttttttttttext", this.state);
   const{role}=this.state;
    const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    let callCenterAttachPermission = [];
    const currentUserId = utils.getSessionItem("userId");
    const isoLangs = siteInfo.isoLangs;
    let predictedLanguageName = '';
    // const teamOptions = Object.keys(siteInfo.CallCenterTeamWithUploadAttachPermission).map((team, i) => {
    //   return (
    //     <option key={i} value={siteInfo.CallCenterTeamWithUploadAttachPermission[team]}>{siteInfo.CallCenterTeamWithUploadAttachPermission[team]}</option>
    //   );
    // });


     //const teamOptions = [];

     //let num= '';
          
      let auditLen = this.props.interactiondetails[0].hasOwnProperty('userAuditDetails') ? this.props.interactiondetails[0].userAuditDetails.length : '';
      let userLen = 0;
     let chart =  this.props.interactiondetails[0].hasOwnProperty('userAuditDetails') && this.props.interactiondetails[0].userAuditDetails.map(d => {
      ++ userLen;
      if(d.action.length>1){
        //let actionVal = 0;
    return (
      // d.action.map(val=>{
      //   ++actionVal;
      //   if(num !== val )
      //   {
      //     num=val;
      //   return(
      
      <div key={d.key}>
        <div className="no1">
          <a className="roleValue">{this.handleRoleValue(d.action[0])}</a>          
          <div className="rootInfo">
            
          <span className="flowCreatedBy"><b>Routed by:</b><br/>{d.createdBy} <br/><b>Routed to:</b><br/>{this.handleRoleValue(d.action[1])}</span>  
              
            <span className="flowCreatedAt"><b>Routed at:</b><br/>{moment.utc(d.createdAt).format('MM/DD/YYYY hh:mm A')}</span> 
          </div>          
        </div>
        
        { (auditLen === userLen)  ? d.end = true :''}
        {!d.end && (
          <React.Fragment>
            <div className="line1" />
            <div className="arrow-down" />
          </React.Fragment>
        )
        }                

      </div>     

    // )}})
    );}

    else
    {
      return (
        <div key={d.key}>
          <div className="no1">
            <a className="roleValue">{d.action}</a>
            <div className="rootInfo">
              <span className="flowCreatedBy"><b>Updated by:</b><br/>{d.createdBy}</span>
              <span  className="flowCreatedAt"><b>Updated at:</b><br/>{moment.utc(d.createdAt).format('MM/DD/YYYY hh:mm A')}</span>
            </div>
          </div>

          {!d.end && (
            <React.Fragment>
              <div className="line1" />
              <div className="arrow-down" />
            </React.Fragment>
          )}

          </div>
      );
    }
  }); 



    const teamOptions= Object.keys(siteInfo.CallCenterTeamWithUploadAttachPermission).map((team, i) => {
      return ({key:i, name:siteInfo.CallCenterTeamWithUploadAttachPermission[team], value:siteInfo.CallCenterTeamWithUploadAttachPermission[team] });
    });
    const roleOptions = Object.keys(siteInfo.UserRoles).sort().map((roles, i) => {
      return (
        (roles==="CallCenterAdmin" || roles==="QualityMonitor")?
        <option key={i} value={roles}>{siteInfo.UserRoles[roles]}</option>: ""
      );
    });
    let fileNames;
    // if(this.state.fileType.length>0 && this.state.datasetName) {
   fileNames = this.state.datasetName.map((data,index)=>
    {
      return(
        <div className="dataset-name-entry-content scrollFileNames" key={index}>
        <label>Files Name{index+1}</label><br />
        <input type="text" className="datasetname-input" name="datasetName" onChange={()=>this.changeHandler(event,index)} value={data}></input>
      </div>
      )
    });
  // }
    //console.log("filename",this.state.datasetName);
    //console.log("isoLang",siteInfo.isoLangs);

    let isEmpty = Object.keys(this.props.interactiondetails).length;
    if (isEmpty !== 0) {
      const viewData = this.state;
      callCenterAttachPermission = Object.values(siteInfo.CallCenterTeamWithUploadAttachPermission).indexOf(viewData.assignedTo) > -1;
      //console.log("getViewDetails", viewData);
      if(viewData.hasOwnProperty('predictedLanguage')){
        if(isoLangs.hasOwnProperty(viewData.predictedLanguage)){
          predictedLanguageName = isoLangs[viewData.predictedLanguage].name;
        }
        else {
          predictedLanguageName = viewData.predictedLanguage;
        }
      }
      const attachmentOptions = [];
    if(this.state.viewAttachment.length > 0){
      this.state.viewAttachment.map((attachment) => {
        //console.log("attachmet", attachment)
        return attachmentOptions.push({ id: attachment, label: attachment.attachmentName, value: attachment.id });
      });
    }
    let attachList = [];
    if(viewData.hasOwnProperty('isTranslationNeeded') && viewData.hasOwnProperty('translationList')) {
      //if(viewData.isTranslationNeeded){
        if(viewData.translationList.attachments.length > 0) {
          attachList = viewData.translationList.attachments.map((file)=>{
            return <li key={file.id}>{file.attachmentName}</li>;
          });
        }
      //}
    }
      return (
        <div>
           {/* <Header ref={`header`} /> */}
          {/* <Header />
      <div className="sidebar">
      <Sidebar />
      </div> */}
          <Growl ref={el => (this.growl = el)} />

          {this.state.createError || this.state.addScoreCard || this.state.editError ? '' : <Row>
            <Col md={12}><h3 className="heading-interaction">Case Details</h3></Col>
          </Row>}
          <div className="content-layout interaction-details-screen">
            {this.state.addScoreCard ? <EditScoreCard details={viewData} scoreCard='new' hideDetails={this.showAddScoreCard} /> : <div>
              {this.state.createError ? <EditErrorDetails errorDetails={viewData} error='new' showEditError={this.showCreateError} /> : <div>
                {this.state.editscorecard ? <EditScoreCard scoreCard='edit' details={this.state.scorecardDetails} hideDetails={this.showDetails} /> : <div>
                  {this.state.editError ? <EditErrorDetails error='edit' errorDetails={this.state.errorDetails} datasetName={viewData} hideEditError={this.hideEditError} /> : <div>
                    <div className="details-layout">
                      <div className="details-row">
                        <Row>
                          <Col md={4} ><label>Case Number</label><span>{viewData.interactionId.toUpperCase()}</span></Col>
                          {/*  */}
                          <Col md={4} ><label> Country</label><span>{viewData.interactionCountry}</span></Col>
                          {/*  */}
                          <Col md={4} ><label>Contact Preferred</label><span>{viewData.contactPreferredLanguage}</span></Col>
                        </Row>
                        <Row>
                          <Col md={4} ><label>Sub-Case Number</label><span>{viewData.subcaseNumber.toUpperCase()}</span></Col>
                          <Col md={4} ><label>Created On</label><span>{moment.utc(viewData.interactionCreatedOn).format('MM/DD/YYYY hh:mm A')}</span></Col>
                          <Col md={4} ><label>Contact Country Default</label><span>{viewData.contactCountryDefaultLanguage}</span></Col>
                        </Row>
                        <Row>
                          <Col md={4} ><label>Final Team</label><span>{viewData.finalTeam}</span></Col>
                          {/* <Col md={4} ><label>Modified On</label><span><Moment format="MM/D/YYYY hh:mm A">{viewData.interactionModifiedOn}</Moment></span></Col> */}
                          <Col md={4} ><label>Modified On</label><span>{moment.utc(viewData.interactionModifiedOn).format('MM/DD/YYYY hh:mm A')}</span></Col>
                          <Col md={4} ><label>External Reference Id</label><span>{viewData.externalInteractionId}</span></Col>


                        </Row>
                        <Row>
                          <Col md={4} ><label>Created By</label><span>{viewData.interactionCreatedByName}</span></Col>
                          <Col md={4} ><label>Case Type</label><span>{viewData.caseType}</span></Col>
                          <Col md={4} ><label>Status</label><span className="status">{viewData.mlResult === true ? viewData.viewStatus + '(Model)' : (viewData.viewStatus == "Completed" ? viewData.viewStatus + '(Manual)' : viewData.viewStatus)}</span></Col>
                          {/*  */}
                          {/* <Col md={4} className="col-row-2"><label>AEID</label><span>{viewData.AEID}</span></Col> */}

                        </Row>
                        <Row>
                          <Col md={4} ><label>Associated Team</label><span>{viewData.associatedTeam}</span></Col>
                          <Col md={4} ><label>Channel</label><span>{viewData.channel}</span></Col>
                          {/*  */}
                          {/* <Col md={4} className="col-row-2"><label>PCMRID</label><span>{viewData.PCMRID}</span></Col> */}
                          <Col md={4} >
                            <label>Assigned To</label>
                            <span>{siteInfo.AssignedTo[this.state.assignTeam] || 'Call Center Team'}
                              {utils.getSessionItem("role") === RoleConstant.Admin && this.state.viewStatus === "Unassigned" &&
                                <img className="edit-case-owner" onClick={this.handleAdminConfirmation} src="/Images/ico-edit.png" alt='Amgen' />}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={4} ><label>Owner</label><span>{viewData.interactionOwner}</span></Col>
                          <Col md={4} ><label>Contact</label><span>{viewData.IRPCContactName}</span></Col>
                          <Col md={4} ><label>Study Protocol Number</label><span>{viewData.studyProtocolNumber}</span></Col>

                        </Row>
                        <Row>
                          {/* <Col md={4} className="col-row-1"><label>Case Owner</label><span>{viewData.interactionOwner}</span></Col> */}
                          <Col md={4}><label>Owner Team</label><span>{viewData.interactionOwnerTeam}</span></Col>
                          <Col md={4} ><label>Dataset</label><span>{viewData.datasetNameView}</span></Col>
                          <Col md={4} ><label>Language</label><span>{predictedLanguageName}</span> </Col>
                          {/* <Col md={4} className="col-row-2"><label>REFID</label><span>{viewData.REFID}</span></Col> */}

                        </Row>

                        <Row>
                          <Col md={4}><label>Workflow</label>
                          {this.props.interactiondetails[0].hasOwnProperty('userAuditDetails') ? (
                          
                          <span className="workflowBtn" onClick={this.enableWorkflow.bind(this)}>Click to see the workflow</span>) : <span> -</span>  } </Col>
                        </Row>
                      </div>

                      <div className="layout-2">
                        <Row>

                          <Col md={12} sm={12}><label className="mb-2 text-left">Files</label>

                            <div className="fileLayout">

                              {this.state.viewAttachment.length ?

                                <div className={(utils.getSessionItem("role") === RoleConstant.CallCenterAdmin || utils.getSessionItem("role") === RoleConstant.TranslationVendor || utils.getSessionItem("role") === RoleConstant.QualityMonitor)
                                  ? "file" : "max-file"} >
                                  {
                                    this.state.viewAttachment.map((data, index) => {
                                      //console.log("sdfdsf", data);
                                      // let split=data.attachmentPath.split('.')[0];
                                      return (
                                        <div className="file-boxes" key={index}>
                                          <span className=" file-box">
                                            <p className="file-name">{data.attachmentName}</p>
                                            <span className="file-download"><img src="Images/ico-download.png" alt='Amgen' onClick={() => this.downloadDataset(data.attachmentPath,data.attachmentName)} />
                                            {((utils.getSessionItem('role') === data.role && utils.getSessionItem('role') === RoleConstant.CallCenterAdmin && callCenterAttachPermission) || (utils.getSessionItem('role') === data.role && utils.getSessionItem('role') === RoleConstant.TranslationVendor) || ((utils.getSessionItem('role') === data.role || data.role === null) && utils.getSessionItem('role') === RoleConstant.QualityMonitor)) &&
                                                <i className="fa fa-trash" aria-hidden="true" onClick={() => this.handleDeleteConfirmation(data.id)}></i>}
                                            </span>
                                          </span>
                                        </div>
                                      )
                                    })
                                  }
                                </div>

                                : <h5 className="upload mt-3 ml-2">No Attachments</h5>}

                              {
                                ((utils.getSessionItem("role") === RoleConstant.CallCenterAdmin && callCenterAttachPermission) || utils.getSessionItem("role") === RoleConstant.TranslationVendor || (utils.getSessionItem("role") === RoleConstant.QualityMonitor && this.props.interactiondetails[0].assignedTo==='QualityMonitor' && this.props.interactiondetails[0].status!='Error')&& this.props.interactiondetails[0].status!='Completed') &&
                                <div className="select" style={{ "float": "right" }}><FileUploader onDrop={this.onDrop} self={this} id={viewData.id} showDatasetNameInput={this.showDatasetNameInput} hideDatasetInput ={this.hideDatasetNameInput} maxFileSize="70"/></div>
                              }
                            </div>

                          </Col>
                        </Row>
                        {
                          //((utils.getSessionItem("role") === RoleConstant.QualityMonitor || utils.getSessionItem("role") === RoleConstant.TranslationVendor) && viewData.isTranslationNeeded) &&
                          (viewData.hasOwnProperty('isTranslationNeeded')) &&
                        <Row>
                          <Col md={12} sm={12}><label className="mb-2 text-left">Translation Required</label>
                          <div className="fileLayout translationLayout">
                          <Row><Col md={12} ><label>Translation required for Agent Notes?</label><span>{viewData.translationList.isAgentNotes?'Yes':'No'}</span></Col></Row>
                          <Row><Col md={12} ><label>Translation required for Attachments?</label><span>{viewData.translationList.attachments.length>0?'Yes':'No'}</span></Col></Row>
                          {
                          viewData.translationList.attachments.length>0 &&
                          <Row><Col md={12} ><label>List of Attachment for Translation:</label>
                            <ol type="1" className="orderedAttach">{attachList}</ol></Col>
                          </Row>
                          }
                          </div>
                          </Col>

                        </Row>
                        }
                        <Row>
                          <Col md={12} sm={12}><label className="mb-2 text-left">Agent Notes</label>
                          <Button className="expand-agentNotes"  onClick={() => this.handleAgentNotes()}><img className="expand-action" src="/Images/expand.png" alt="expand"/></Button>
                            <div className="agent-notes">
                              {/* <div className='scroll'>{viewData.agentNotes}</div> */}

                           <iframe id="style-iframe" className="agentNotes-iframe"></iframe>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12} sm={12}><label className="mb-2 text-left">MIR Description</label>
                            <div className="mir-desc">
                              <span className='description'>{viewData.MIRDescription}</span>
                            </div>
                          </Col>
                        </Row>
                      </div>

                    </div>
                    <div className="row"><div className="col-xs-12 col-md-12">
                      <span>
                        {(utils.getSessionItem("role") === RoleConstant.CallCenterAdmin || utils.getSessionItem("role") === RoleConstant.TranslationVendor) &&
                          this.state.hide && <button className="interactionSearch button-color btn btn-secondary mt-4 mb-4 pull-right" onClick={this.handleRouteConfirmation}>Route for Review</button>
                        }
                      </span>
                      {this.state.assignTeam === RoleConstant.QualityMonitor && this.props.interactiondetails[0].status !== 'Completed' &&
                        <span>
                          {utils.getSessionItem("role") === RoleConstant.QualityMonitor &&
                            <div>
                              {(viewData.status === 'Completed' && viewData.scorecardExist) && <button className="interactionSearch button-color btn btn-secondary mt-4 mb-4 pull-right" onClick={this.showAddScoreCard}>Add Score Card</button>}

                              {(viewData.scorecardExist && ((viewData.scorecardCreatedBy===currentUserId && viewData.stage==="1")||(viewData.scorecardCreatedBy!==currentUserId && viewData.stage==="2"))) && <button className="interactionSearch button-color btn btn-secondary mt-4 mb-4 pull-right" onClick={() => this.showEditScoreCard(viewData.scorecardId)}>Edit Score Card</button>}
                              {(!viewData.scorecardExist) && <button className="interactionSearch button-color btn btn-secondary mt-4 mb-4 pull-right" onClick={this.showAddScoreCard}>Add Score Card</button>}
                              {(viewData.status === 'Error' && viewData.errorExist) && <button className="interactionSearch button-color btn btn-secondary mt-4 mb-4 mr-2 pull-right" onClick={this.showEditError}>Edit Error</button>}
                              {(viewData.status === 'Assigned') && <button className="btn-goback mt-4 mb-4 pull-right" onClick={this.showCreateError}>Create Error</button>}

                              {/* <button className="btn-goback mt-4 mb-4 pull-right" onClick={this.onTranslate}>Translate</button>*/}
                              <button className="btn-goback mt-4 mb-4 pull-right"  onClick={this.handleConfirmation }>Translate</button>

                            </div>
                          }
                          {(utils.getSessionItem("role") === RoleConstant.QualityMonitor) &&
                            this.state.hide && <button className="clear btn-goback mt-4 mb-4 pull-right" onClick={this.handleRouteConfirmation}>Route to Call Center</button>
                          } </span>}
                      <button className="btn-goback mt-4 mb-4 pull-right" onClick={this.goBack}>
                        <img src="Images/icon-arrow-left.png" className="back_btn" alt='Amgen' />Back</button>

                    </div>
                    </div>
                  </div>}</div>}</div>}</div>}
          </div>

          <ConfirmationModal showModal={this.state.adminConfirmation}
        handleCloseModal={this.handleAdminConfirmation.bind(this)}
        message={siteInfo.ConfirmationText.AdminToCallCenterTeam}
        navigate={this.handleShow} />

          <Modal show={this.state.show} onHide={this.handleClose} className="model-content-assign">
            <Modal.Header closeButton className='add-user-header'>ASSIGN TO
          </Modal.Header>
            <Modal.Body>
              <Form.Row>
              <Form.Group controlId="team" className="team-form-group">
                  <Form.Label className='align-lable'>Role</Form.Label>
                  <Form.Control as='select' name='role' value={role}
                    onChange={e => { this.setState({ role: e.target.value }) }}>
                      {role==="QualityMonitor"?(this.isFormValid=true):(this.state.team?this.isFormValid=true:this.isFormValid=false)}
                    <option value="">--- Select ---</option>
                    {roleOptions}
                  </Form.Control>
                </Form.Group>
                {role==="CallCenterAdmin" ?

                  <div className="dropdown-demo">
                    <div className="dropdown-team">Team</div>

                  <Dropdown  options={teamOptions}
                  onChange={this.onTeamChange} optionLabel= "value" filter
                  filterBy="value" filterMatchMode="equals" value={this.state.team} placeholder=" --- Select Team --- "
                  />
            </div>

                // <Form.Group controlId="team" className="team-form-group">
                //   <Form.Label className='align-lable'>Team</Form.Label>
                //   <Form.Control as='select' name='team' value={this.state.team}
                //     onChange={this.changeAssociatedTeam}>
                //     <option value="">--- Select Team ---</option>
                //      {teamOptions}
                //   </Form.Control>
                //         </Form.Group>
                : "" }
                {this.state.showAddUserMsg ? (<Form.Group className="team-form-group" >
                  <div className="no-team-available-msg"><img src="Images/ico-info.png" alt="" />&nbsp;&nbsp;No user associated to this team.
                   <span onClick={this.showUserRegistration.bind(this)}>Click here</span>  to add OKTA user.</div>
                </Form.Group>) : null}
                <Form.Group controlId="btnaction" className="team-form-button">
                  <Button variant="secondary" type="submit" className='text-btn-color' disabled={!this.isFormValid} onClick={this.handleAddTeamShow}>
                    Assign
                    </Button>
                </Form.Group>
              </Form.Row>
            </Modal.Body>
            {/* <Registration adminRegister={true} getUser={this.getUsersList} closePopup={this.handleClose}/> */}
          </Modal>
          <PopupModels showPopup={this.state.addTeamModel} iconFlag="success" message={role==="CallCenterAdmin"? MessageConstant.TEAM_ASSIGN_SUCCESS:MessageConstant.QM_ASSIGN_SUCCESS} handleClosePopup={()=>this.handleAddTeamClose(event)} />
           {/* <PopupModels showPopup={this.state.routeToCallCenterModel} iconFlag="error" message={utils.getSessionItem("role") === RoleConstant.QualityMonitor?this.state.messageForAttachment:this.state.messageForAttachment} handleClosePopup={this.handleCallCenterClose.bind(this)} /> */}
           <PopupModels showPopup={this.state.routeToCallCenterModel} iconFlag="error" message={this.state.messageForAttachment} handleClosePopup={this.handleCallCenterClose.bind(this)} />
           {/* <PopupModels showPopup={this.state.showIntMsg} iconFlag="error" message={utils.getSessionItem("role") === RoleConstant.Admin?MessageConstant.INT_ALREADY_ASSIGNED:MessageConstant.INT_ALREADY_ASSIGNED} handleClosePopup={this.handleCallCenterClose.bind(this)}/> */}
           <PopupModels showPopup={this.state.showIntMsg} iconFlag="error" message={MessageConstant.INT_ALREADY_ASSIGNED} handleClosePopup={this.handleCallCenterClose.bind(this)}  />

           <Modal dialogClassName="deletionModal" show={this.state.successDialog} aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'></Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Image src="/Images/ico-success.png" className='success-img'></Image>
            <div className='success-styles'>Success!</div>
            {this.state.successMessageContent}
          </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.closeDialogBox.bind(this)} className='user-deletion-cancel'>Ok</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showDatasetNameInput} onHide={this.hideDatasetNameInput} className="dataset-list-screen-dialog">
          <Modal.Header closeButton>
            <Modal.Title>Files</Modal.Title>
          </Modal.Header>

          <Modal.Body className="scrollFileNames">
           {fileNames}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleDatasetNameInputClose}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <ConfirmationModal showModal={this.state.translateConfirmation}
        handleCloseModal={this.handleConfirmation.bind(this)}
        message={siteInfo.ConfirmationText.QualityMonitorToTranslationVendor}
        navigate={this.onTranslationConfirm} />


        <PopupModels showPopup={this.state.translateModel} iconFlag="success" message={MessageConstant.QUALITYMONITOR_ASSIGN_TO_TRANSLATIONVENDOR} handleClosePopup={()=>this.handleTranslateClose(event)} />

        <Modal show={this.state.translationType} onHide={this.handleTranslationTypeClose} className="model-content-translate">
            <Modal.Header closeButton className='add-user-header translate-header'>Translation Type
          </Modal.Header>
            <Modal.Body>
              <Form.Row>
              <Form.Group className="team-form-group"><strong>Select the type of translation:</strong></Form.Group>
                  <Form.Group className="team-form-group selectType">
                    <Form.Label>
                      <input type="checkbox" name="anChecked"  onChange={this.onSelectTypeChanged}
                       defaultChecked={this.state.anChecked}/><span>Agent Notes</span>
                    </Form.Label>
                    <Form.Label>
                      <input type="checkbox" name="attachmentChkd" onChange={this.onSelectTypeChanged}
                      defaultChecked={this.state.attachmentChkd} disabled={this.state.viewAttachment.length == 0} /><span>Attachments</span>
                    </Form.Label>
                </Form.Group>
             {this.state.attachmentChkd?this.state.viewAttachment.length > 0?(<Form.Group className="team-form-group col-md-11">
                    <Form.Label>Attachment List</Form.Label>
                    <MultiSelect
                      options={attachmentOptions}
                      selected={this.state.attachmentData}
                      disableSearch={true}
                      hasSelectAll={true}
                      onSelectedChanged={selected => {
                        //console.log("selected values", selected);
                        this.setState({ attachmentData : selected },()=>{
                          //console.log("attachment details select",this.state);
                        });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />

                </Form.Group>):(<strong className="mb-2">No attachment Found</strong>):null }
                <Form.Group controlId="btnaction" className="team-form-button col-md-9">
                  <Button variant="secondary" type="submit" className='text-btn-color'
                  disabled={(!this.state.anChecked && !this.state.attachmentChkd) ||
                   (this.state.attachmentChkd && this.state.viewAttachment.length==0) ||
                   (this.state.attachmentChkd && this.state.attachmentData.length==0)} onClick={this.onTranslate}>
                    Translate
                    </Button>
                </Form.Group>
              </Form.Row>
            </Modal.Body>
            {/* <Registration adminRegister={true} getUser={this.getUsersList} closePopup={this.handleClose}/> */}
          </Modal>

        <ConfirmationModal showModal={this.state.deleteConfirmation}
        handleCloseModal={this.handleDeleteConfirmation.bind(this)}
        message={MessageConstant.DELETE_CONFIRMATION}
        navigate={()=>this.deleteAttachment(this.state.deleteId)} />

        <PopupModels showPopup={this.state.deleteModel} iconFlag="success" message={MessageConstant.ATTACHMENT_DELETED} handleClosePopup={()=>this.handleDeletionClose(event)} />

        <ConfirmationModal showModal={this.state.routeConfirmation}
        handleCloseModal={this.handleRouteConfirmation.bind(this)}
        message={utils.getSessionItem("role") === RoleConstant.QualityMonitor ?siteInfo.ConfirmationText.QualityMonitorToCallCenter
        :(utils.getSessionItem("role") === RoleConstant.CallCenterAdmin ?siteInfo.ConfirmationText.CallCenterAdminToQualityMonitor:siteInfo.ConfirmationText.TranslationVendorToQualityMonitor)}
        navigate={this.onRouteForReview} />



        <PopupModels showPopup={this.state.routeForReviewModel} iconFlag="success"
            message={utils.getSessionItem("role") === RoleConstant.QualityMonitor ? MessageConstant.QUALITYMONITOR_ASSIGN_TO_CALLCENTERADMIN : MessageConstant.ASSIGN_TO_QUALITYMONITOR} handleClosePopup={()=>this.handleRouteReviewClose(event)} />

          {this.state.agentNotesPopupModel?<AgentNotesPopup showPopup={this.state.agentNotesPopupModel} viewData={viewData} handleClosePopup={this.handlesamplingClose.bind(this)}/>:''}


          <Modal show={this.state.workflow} onHide={this.closeWorkFlowBox.bind(this)} className="audit-assign">
            <Modal.Header closeButton className='add-user-header audit-header'>User Subcase Audit
          </Modal.Header>
          <Modal.Body className="flowScrollPopup">
              {/* <Flow /> */}
              <div id="container">{chart}</div>
          </Modal.Body>
          {/* <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.closeWorkFlowBox.bind(this)} className='user-deletion-cancel'>Ok</Button>
          </Modal.Footer> */}
        </Modal>
        </div>
      );
    }
    else {
      return (
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <h5>Details not available</h5>
            <button className="col-md-1 offset-11 btn-goback mt-4 mb-4 pull-right" onClick={this.goBack}>
              <img src="Images/icon-arrow-left.png" className="back_btn" alt='Amgen' />Back</button>
          </div>
        </div>
      );
    }
  }
}
ViewDetails.propTypes = {
  interactiondetails: PropTypes.array,
  hideViewDetails: PropTypes.func,
  setLinkInactive: PropTypes.func,
  setLinkActive: PropTypes.func

}
export default ViewDetails;
