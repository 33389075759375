import NProgress from 'react-nprogress';
import CommonConstant from './constants/CommonConstant';
import EndpointConstant from './constants/EndpointConstant';
import RouterConstant from './constants/RouterConstant';
import config from './config';
// import { DataModelLineChart } from './components';
const issuer = window.okta.issuer;
const redirectUri = `${window.location.origin}${window.okta.logoutUri}`;

function gotoPage(path) {
  // NProgress.done();
  showLoader();
  setTimeout(function () {
    window.location.hash = path;
    // window.location = path;
    hideLoader();
  }, 500);
}

function gotoPageWithoutHistory(path) {
  NProgress.done();
  setTimeout(function () {
    // let url = window.location.href;
    // url = url.replace(/\/[^\/]*$/, path);
    // window.location.replace(url);
    window.location.hash = path;
  }, 500);
}

function getURL(path) {
  // return path;
  return '#' + path;
}

function goBack() {
  window.history.back();
}

function gotoPageWithoutWait(path) {
  NProgress.done();
  window.location = path;
}

function makeRequestInBackground(self, endpointName, data, callback, headerData) {

  let headers = {
    'Content-Type': 'application/json',
    'x-api-key': config.api.key
  };

  if (headerData) {
    headers = Object.assign(headers, headerData);
  }

  if (hasSessionItem('userId')) {
    headers['userId'] = getSessionItem('userId');
  }
  if (hasSessionItem('sessionToken')) {
    headers['sessionToken'] = getSessionItem('sessionToken');
  }

  // debugger
  fetch(config.aws.apiRoot + endpointName, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  })
    .then(
      function (response) {
        //console.log(JSON.stringify(response.status));
        if (response.status !== 200) {
          return false;
        }
        return response.json();
      }
    )
    .then((responseJson) => {
      //callback(responseJson);
      if (responseJson.hasOwnProperty('result')) {
        if (responseJson.result.errorCode === "401") {
          gotoPage(RouterConstant.PAGE_NOT_FOUND);
          return;
        }
        else if (responseJson.result.errorCode === "SE_401") {
          const idToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
          window.localStorage.clear();

          // Clear remote session
          window.location.href = `${issuer}/v1/logout?id_token_hint=${idToken.idToken.idToken}&post_logout_redirect_uri=${redirectUri}`;
          return;
        } else if (responseJson.result.hasOwnProperty('errorCode')) {
          const error = getErrorFromErrCode(responseJson.result.errorCode);
          self.refs.header.showAlertDialog(error);
          callback(responseJson.result);
          return;
        }
        callback(responseJson.result);
      }
      else {
        if (responseJson.errorCode === "401") {
          // gotoPage(RouterConstant.LOGOUT);
          return;
        }
        callback(responseJson);
      }
    })
    .catch((error) => {
      //console.log(error);
    });
}

function uploadAcceptanceCriteriaFiles(self, data, contentType, id, acceptanceData,callback) {
  showLoader();
  //let imageKey = getPageInfoContent('imageKey');

  let header = {
    'Content-Type': contentType,
    userId: getSessionItem('userId'),
    sessionToken: getSessionItem('sessionToken'),
    'x-api-key': config.api.key
  };
  console.log("acceptanceData check dat",acceptanceData);
  // if (id) {
  //   header['interactionId'] = id;
  // }
  // if (fileName) {
  //   header.filename = fileName;
  // }
  // data = btoa(data);

  //console.log(JSON.stringify(data));

  var reader = new FileReader();
  reader.onload = function (readerEvt) {
    let binaryString = readerEvt.target.result;
    //console.log("reader Evt", base64ArrayBuffer(binaryString));
    //let data = btoa(binaryString);
    // let data ={};
    let data = base64ArrayBuffer(binaryString);
    
    // let newObject = {}
    // newObject = acceptanceData;
    // newObject['base64'] = data;
    acceptanceData.base64 = data;
    let endpoint = EndpointConstant.ACCEPTANCE_CRITERIA;

    console.log(JSON.stringify(acceptanceData),"newObject");

    fetch(config.aws.apiRoot + endpoint, {
      method: 'POST',
      headers: header,
      body:JSON.stringify(acceptanceData)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        hideLoader();

        if (responseJson.result.hasOwnProperty('errorCode')) {
          const error = getErrorFromErrCode(responseJson.result.errorCode);
          //console.log(JSON.stringify(error));
          self.refs.header.showAlertDialog(error);
          return;
        }
        callback(responseJson['result']);
      })
      .catch((error) => {
        console.error(error);
        hideLoader();
      });
  };

  //reader.readAsBinaryString(data);
  reader.readAsArrayBuffer(data);
}

function uploadFiles(self, data, contentType, id, callback, fileName, endpoint) {
  showLoader();
  //let imageKey = getPageInfoContent('imageKey');

  let header = {
    'Content-Type': contentType,
    userId: getSessionItem('userId'),
    sessionToken: getSessionItem('sessionToken'),
    'x-api-key': config.api.key
  };

  if (id) {
    header['interactionId'] = id;
  }
  if (fileName) {
    header.filename = fileName;
  }  

  console.log("Files",data.size);
  var fileSize = Math.ceil((data.size/1024)/1024);
  console.log("Filesize",fileSize);
  let filesizeBoundary = 5
  if(endpoint === EndpointConstant.UPLOAD_DATASET) { 
    filesizeBoundary = 6
  }
  if(fileSize >= filesizeBoundary){
    let inputData = {};
    inputData.isSignedUrlRequired = true;
      //console.log(JSON.stringify(inputData));

      fetch(config.aws.apiRoot + endpoint, {
        method: 'POST',
        headers: header,
        body:JSON.stringify(inputData)
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //hideLoader();

          if (responseJson.result.hasOwnProperty('errorCode')) {
            const error = getErrorFromErrCode(responseJson.result.errorCode);
            //console.log(JSON.stringify(error));
            self.refs.header.showAlertDialog(error);
            return;
          }
          //console.log("response s3",responseJson);
          if(responseJson['result'].hasOwnProperty('error')){
            if(responseJson['result'].error != true){
               const {url, fields} = responseJson['result'].data;
               const uploadData = {
                bucket: fields.bucket,
                ...fields,
                'Content-Type': data.type,
                  file: data,
                };
          
                const formData  = new FormData();
                for (const key in uploadData) {
                  formData.append(key, uploadData[key]);
                }
                fetch(url, {
                  mode: 'cors',
                  headers: {
                      'Access-Control-Allow-Origin':'*'
                  },
                  method: 'POST',
                  body: formData,
                })
                  /* .then((s3Res) => s3Res.json()) */
                  .then((s3ResponseJson) => {
                    console.log("s3 response Json", s3ResponseJson);
                    console.log('tye', typeof s3ResponseJson);
                    console.log("status", s3ResponseJson.status);
                    //if(s3ResponseJson.hasOwnProperty('status')){
                      //console.log("status inside");
                      if(s3ResponseJson.status == 204){
                        console.log("console inside 204");
                        let updateData = {};
                        let s3FileName = fields.hasOwnProperty('key')?fields.key.split('/')[1]:"";
                        updateData.isUpdateRequired = true;
                        updateData.filePath = s3FileName;
                          //console.log(JSON.stringify(inputData));
                    
                          fetch(config.aws.apiRoot + endpoint, {
                            method: 'POST',
                            headers: header,
                            body:JSON.stringify(updateData)
                          })
                            .then((resUpdate) => resUpdate.json())
                            .then((responseUpdateJson) => {
                              //console.log("responseUpdateJson",responseUpdateJson)
                              hideLoader();
                              callback(responseUpdateJson['result']);
                            });
                      }
                    //}
                  })
        
            }
          }

          //callback(responseJson['result']);
        })
        .catch((error) => {
          console.error(error);
          hideLoader();
        });
  }
  else {
    var reader = new FileReader();
    reader.onload = function (readerEvt) {
      let binaryString = readerEvt.target.result;
      //console.log("reader Evt", base64ArrayBuffer(binaryString));
      //let data = btoa(binaryString);
      let data;
      if(endpoint === EndpointConstant.UPLOAD_DATASET) { 
        data = base64ArrayBuffer(binaryString);       
      } else {        
        data = {};
        data.base64=[base64ArrayBuffer(binaryString)];
      }
         

      //console.log(JSON.stringify(data));

      fetch(config.aws.apiRoot + endpoint, {
        method: 'POST',
        headers: header,
        body:endpoint === EndpointConstant.UPLOAD_DATASET ? data :JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((responseJson) => {
          hideLoader();

          if (responseJson.result.hasOwnProperty('errorCode')) {
            const error = getErrorFromErrCode(responseJson.result.errorCode);
            //console.log(JSON.stringify(error));
            self.refs.header.showAlertDialog(error);
            return;
          }
          callback(responseJson['result']);
        })
        .catch((error) => {
          console.error(error);
          hideLoader();
        });
    };

    //reader.readAsBinaryString(data);
    reader.readAsArrayBuffer(data);
  }

}

function makeRequest(self, endpointName, data, callback, headerData) {
  if(endpointName != 'DataSetList' && endpointName != 'CountryTotals' && endpointName != 'GetCasetypesForDataset' && endpointName !='GetSampleSets'){
  showLoader();
  }
  //console.log(JSON.stringify(data));

  let headers = {
    'Content-Type': 'application/json',
    'x-api-key': config.api.key,
    // 'x-apigw-api-id': 'ss1s5oj7ch',
    // 'Access-Control-Allow-Credentials': true
  };

  if (headerData) {
    headers = Object.assign(headers, headerData);
  }

  if (hasSessionItem('userId')) {
    headers['userId'] = getSessionItem('userId');
  }
  if (hasSessionItem('sessionToken')) {
    headers['sessionToken'] = getSessionItem('sessionToken');
  }

  // debugger
  fetch(config.aws.apiRoot + endpointName, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  })
    .then(
      function (response) {
        //console.log(JSON.stringify(response.status));
        if (response.status !== 200) {
          return false;
        }
        return response.json();
      }
    )
    .then((responseJson) => {
      if(endpointName != 'DataSetList' && endpointName != 'CountryTotals' && endpointName != 'GetCasetypesForDataset'  && endpointName !='GetSampleSets'){
        hideLoader();
        }

      //console.log(JSON.stringify(responseJson));
      console.log('entered', endpointName)
      if (!responseJson) {
        self.refs.header.showAlertDialog(CommonConstant.NETWORK_ERROR);
      }
      else {
        console.log('entered2', endpointName)
        if (responseJson.hasOwnProperty('result')) {
          if (responseJson.result.errorCode === "401") {
            gotoPage(RouterConstant.PAGE_NOT_FOUND);
            return;
          }
          else if (responseJson.result.errorCode === "SE_401") {
            const idToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
            window.localStorage.clear();

            // Clear remote session
            window.location.href = `${issuer}/v1/logout?id_token_hint=${idToken.idToken.idToken}&post_logout_redirect_uri=${redirectUri}`;
            return;
          } else if (responseJson.result.errorCode === "U102" || responseJson.result.errorCode === "U100" ||
            responseJson.result.errorCode === "CCU_400" || responseJson.result.errorCode === "C_NO_ATTACHMENT_PERMISSION" ||
            responseJson.result.errorCode === "INT_ALREADY_ASSIGNED") {
            // const error=MessageConstant.USER_DOESNT_EXISTS_NON_ADMIN
            // self.refs.header.showAlertDialog(error);
            // return;
          } else if (responseJson.result.errorCode === "D_IN_ERROR" || responseJson.result.errorCode === "E_ALREADY_CREATED" ||
          responseJson.result.errorCode === "S_ALREADY_CREATED" || responseJson.result.errorCode === "D_IN_SCORECARD" || responseJson.result.errorCode === 'U101' ||
          responseJson.result.errorCode ==='S_USER_NOT_EXIST') {
            callback(responseJson.result);
            return;
          } else if (responseJson.result.hasOwnProperty('errorCode')) {
            const error = getErrorFromErrCode(responseJson.result.errorCode);
            self.refs.header.showAlertDialog(error);
            return;
          }
          callback(responseJson.result);
        }
        else {
          if (responseJson.errorCode === "401") {
            // gotoPage(RouterConstant.LOGOUT);
            return;
          }
          callback(responseJson);
        }
        let currentTime = new Date().getTime();
        currentTime = Math.round((currentTime + 1 * 30 * 60 * 1000)/1000);
        setSessionItem('sessionValidity', currentTime);
      }
     
    })
    .catch((error) => {
      //console.log(error);
      self.refs.header.showAlertDialog(CommonConstant.NETWORK_ERROR);
      hideLoader();
    });
}

function getErrorFromErrCode(code) {
  const siteinfo = JSON.parse(getSessionItem("SiteInfo"));
  if (siteinfo.errorCodes.hasOwnProperty(code)) {
    return siteinfo.errorCodes[code];
  } else {
    return code;
  }
}

function setSessionItem(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function getSessionItem(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function removeSessionItem(key) {
  window.localStorage.removeItem(key);
}

function showLoader() {
  NProgress.start();
  if (document.getElementById('loader'))
    document.getElementById('loader').style.display = 'block';
}

function hideLoader() {
  NProgress.done();
  document.getElementById('loader').style.display = 'none';
}

function hasSessionItem(key) {
  if (getSessionItem(key) === null) {
    return false;
  }
  else {
    return true;
  }
}

function sessionValidityCheck() {
  if (hasSessionItem('sessionValidity')) {
    const current = Math.round(new Date().getTime()/1000);
    // console.log(current);console.log(getSessionItem('sessionValidity'));
    if (getSessionItem('sessionValidity') >= current) {
      return true;
    } else {
      return false;
    }
  }

  return true;
}

function base64ArrayBuffer(arrayBuffer) {
  var base64 = ''
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

  var bytes = new Uint8Array(arrayBuffer)
  var byteLength = bytes.byteLength
  var byteRemainder = byteLength % 3
  var mainLength = byteLength - byteRemainder

  var a, b, c, d
  var chunk

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i += 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
    d = chunk & 63               // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength]

    a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4 // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '=='
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

    a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2 // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '='
  }

  return base64
}

export default {
  goBack,
  gotoPage,
  gotoPageWithoutHistory,
  gotoPageWithoutWait,
  makeRequest,
  makeRequestInBackground,
  setSessionItem,
  getSessionItem,
  showLoader,
  hideLoader,
  removeSessionItem,
  hasSessionItem,
  uploadFiles,
  getURL,
  sessionValidityCheck,
  uploadAcceptanceCriteriaFiles
};