import React, { Component } from 'react';
import { Modal, Button, Image,Form } from 'react-bootstrap';
import './ReportsMangement.css';
import ReportList from './ReportList';
import ReportUploader from './ReportUploader';
import utils from '../../../utils';
import EndpointConstant from '../../../constants/EndpointConstant';
import { Sidebar, Header } from '../..';
import MessageConstant from '../../../constants/MessageConstant'
import PopupModels from '../../PopupModels/PopupModels';
import Pagination from '../../Pagination/Pagination';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


// import Files from './Files/Files';
// import {Redirect} from 'react-router-dom';
/**
 * A class that can return some html
 *@return {Object} .
 *@param {object} props
 *@deprecated 
 */
class Report extends Component {

  constructor(props) {
    super(props);    
    this.onDrop = this.onDrop.bind(this);    
    this.state = {
      report: [],
      dataSort: [],      
      showReportNameInput: false,
      reportName: '',
      reportType: '',
      reportDeleteSuccess: false,
      showpagination: false,  
      alertText: '',
    };
    this.onSortDataList = this.onSortDataList.bind(this);
    this.handleReportNameInputClose = this.handleReportNameInputClose.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.showReportNameInput = this.showReportNameInput.bind(this);
    this.performFileUpload = this.performFileUpload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.hideReportNameInput = this.hideReportNameInput.bind(this);
    this.deleteReport = this.deleteReport.bind(this);
    this.onChangePage = this.onChangePage.bind(this);          
    this.interval = null; 
  }
  componentDidMount() {
    this.getReportList();
  }
  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.showAlertDialog("");
    //console.log("It's here", e.target.value)
  }
  hideReportNameInput() {
    this.setState({ showReportNameInput: false })
  }
  downloadReport(id) {
    //console.log("downloadReport ++++++ " + id);
    const data = {
      id: id
    };
    utils.makeRequest(this, EndpointConstant.DOWNLOAD_REPORT, data, function (result) {
      window.open(result.url, 'Download');
    });
  }
  onDrop(result) {

    result.sort(this.compare);
    this.setState({
      report: result
    });
  }

  compare(a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    return 0;
  }

  getReportList() {

    //console.log("getReportList ++++++ ");
    utils.showLoader()
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_REPORT_LIST, data, function (result) {
      utils.hideLoader()
      result.reports.sort(self.compare);
      self.setState({
        report: result.reports,
        dataSort: result.reports
      }, () => self.setState({ showpagination: true }));

      //console.log(JSON.stringify(result));

    });
  }
  

  /*
  * on Sort functionality
  */
  sortDataList(prop, sortId) {
    return (a, b) => {
      if (sortId.sortId === 'sort') {
        return a[prop].toString().localeCompare(b[prop].toString());
      }
      else if (sortId.sortId === 'sort-up') {
        return b[prop].toString().localeCompare(a[prop].toString());
      }
      else if (sortId.sortId === 'sort-down') {
        return a;
      }
    }
  }
  onSortDataList(data, e) {
    e.preventDefault();
    let sortId = e.target.querySelector('i').id;
    // let className = e.target.querySelector('i').className;
    if (sortId === 'sort') {
      e.target.querySelector('i').id = 'sort-up';
      e.target.querySelector('i').className = 'fas fa-sort-up';
    }
    else if (sortId === 'sort-up') {
      e.target.querySelector('i').id = 'sort-down';
      e.target.querySelector('i').className = 'fas fa-sort-down';
    }
    else if (sortId === 'sort-down') {
      e.target.querySelector('i').id = 'sort';
      e.target.querySelector('i').className = 'fas fa-sort';
    }
    const { dataSort } = this.state;
    switch (data) {
      case 'name':
        {
          let reportName = [...dataSort].sort(this.sortDataList('reportName', { sortId: sortId }));
          this.setState({
            report: reportName
          });
        }
        break;
      case 'createdat': {
        let createdAt = [...dataSort].sort(this.sortDataList('createdAt', { sortId: sortId }));
        this.setState({
          report: createdAt
        });
      }
        break;
      case 'createdby': {
        let createdBy = [...dataSort].sort(this.sortDataList('createdBy', { sortId: sortId }));
        this.setState({
          report: createdBy
        });
      }
        break;      
      default:
        break;
    }
  }

  showReportNameInput(id, files, fileType) {
    this.showAlertDialog("");
    if (fileType) {
      this.setState({
        showReportNameInput: true,
        currentReportInput: 'upload',
        reportName: '',
        files: files,
        fileType,
        id: id,
      });
    } else {
      this.setState({
        reportName: files.split('.')[0],
        reportType: files.split('.')[1],
        showReportNameInput: true,
        currentReportInput: 'editName',
        reportId: id,

      });
    }
  }

  handleReportNameInputClose() {
    let self = this;
    let reportName = this.state.reportName;
    if (reportName.length === 0) {
      this.showAlertDialog("Please enter report name");
      return;
    }
    let reportNameExist = false;
    this.state.report.forEach((data) => {
      const reportNames = data.reportName.split('.')[0];
      if (reportNames.toString().toLowerCase() === self.state.reportName.toString().toLowerCase()) {
        reportNameExist = true;
      }
    });
    if (reportNameExist) {
      this.showAlertDialog("Report name already exists!");
      return;
    }
    if (this.state.currentReportInput === 'upload') {
      this.performFileUpload(this.state.files, this.state.fileType, this.state.id);
    } 
  }
  showAlertDialog(text) {
    this.setState({
      alertText: text,
    });
  }
 
  performFileUpload(files, fileType, id) {
    this.showAlertDialog("");
    let self = this;
    this.setState({
      showReportNameInput: false,
    });
    utils.uploadFiles(this, files[0], fileType, id, function (result) {
      //console.log(JSON.stringify(result.reportList));
      self.onDrop(result.reportList);
      self.setState({
        successDialog: true,
        successMessageContent: 'Report uploaded successfully',
      });
    }, this.state.reportName, EndpointConstant.UPLOAD_REPORT);
  }

  closeDialogBox() {
    this.setState({
      successDialog: false,
    });
  }
  deleteReport(data) {
    // let self=this
    data.sort(self.compare);
    this.setState({
      report: data,
      dataSort: data
    }, () => { this.setState({ reportDeleteSuccess: true }) });

    //console.log(JSON.stringify(data));
  }
  handleDeleteClose() {
    this.setState({ reportDeleteSuccess: false })
  }
  onChangePage(currentTodos) {
    this.setState({ currentTodos: currentTodos }, () => { this.setState({ showpagination: true }) })
  } 

  render() {   
    return (
      <div className="document-list-screen">
        <Modal dialogClassName="deletionModal" show={this.state.successDialog} aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'></Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Image src="/Images/ico-success.png" className='success-img'></Image>
            <div className='success-styles'>Success!</div>
            {this.state.successMessageContent}
          </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.closeDialogBox.bind(this)} className='user-deletion-cancel'>Ok</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showReportNameInput} onHide={this.hideReportNameInput} className="document-list-screen-dialog">
          <Modal.Header closeButton>
            <Modal.Title>REPORT NAME</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="document-name-entry-content">
              <label>Report Name</label><br />
              <input type="text" className="documentname-input" value={this.state.reportName} name="reportName" onChange={this.changeHandler}></input>
              <div className="alert-message">{this.state.alertText}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleReportNameInputClose}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <Header ref={`header`} /> */}
        {/* <div className="sidebar">
          <Sidebar />
        </div> */}
        {/* <div className="menu-content"> */}
        <div>
          <h4 className="document-title">Upload Files</h4>
          <div className="row file-row">
            <div className="col-md-12 pt-2 drag-drop">
              <ReportUploader onDrop={this.onDrop} self={this} showReportNameInput={this.showReportNameInput} maxFileSize={this.props.maxFileSize} />
            </div>                                     
          </div>
          <ReportList report={this.state.currentTodos} downloadReport={this.downloadReport} onSortDataList={this.onSortDataList} showReportNameInput={this.showReportNameInput} deleteReport={this.deleteReport} />
          {this.state.showpagination ? <Pagination data={this.state.report} onChangePage={this.onChangePage} /> : ''}
          <PopupModels showPopup={this.state.reportDeleteSuccess} iconFlag="success" message={MessageConstant.REPORT_DELETED} handleClosePopup={this.handleDeleteClose.bind(this)} />
        </div>    
      </div>
    );
  }
}

export default Report;
