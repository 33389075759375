import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Growl } from 'primereact/growl';
import '../FileUploader/FileUploader.css';
// import utils from '../../utils';
import PropTypes from 'prop-types';

class DatasetUploader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showUploadImage: false,
      highlightDragAndDrop: false,
      enableUpload: true
    };
    this.props = props;
    this.getExtension = this.getExtension.bind(this);
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  onDropFile(files) {
    //console.log("files", files);

    // let titleIcon = files[0].name;
    let id = this.props.id ? this.props.id : null;
    let fileExtension = this.getExtension(files[0].name);
    let fileType = null;
    if (!id) {
      if (fileExtension === 'xlsx' || fileExtension === 'csv' || fileExtension === 'xls')
        fileType = files[0].type;
      else {
        //alert('Please select a valid Excel file.');
        this.growl.show({severity:'error', summary: 'Error', detail:`Please select a valid Excel file.`, life: 5000});
        return;
      }
    }
    else {
      fileType = files[0].type;
    }
    if (fileExtension === 'xlsx') {
      fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (fileExtension === 'csv') {
      fileType = 'text/csv';
    } else if (fileExtension === 'xls') {
      fileType = 'application/vnd.ms-excel';
    }
    this.props.showDatasetNameInput(id, files, fileType);
  }

  onDragEnter() {
    this.setState({
      highlightDragAndDrop: true
    });
  }

  onDragLeave() {
    this.setState({
      highlightDragAndDrop: false
    });
  }

  handleImageErrored() {
    this.setState({
      showErrorImage: true
    });
  }

  render() {
    return (
      <React.Fragment>
        <Growl ref={el => (this.growl = el)} />
        <Dropzone
          className="dropzone"
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          onDrop={this.onDropFile.bind(this)}>
          <div className="drag-text">
            <img src="/Images/ico-cloud-dragNdrop.png" className="file-drop-icon" alt='Amgen' />
            <p className="select-file-title">Drop files here or click to <u>select files to upload</u></p>
          </div>
        </Dropzone>
      </React.Fragment>
    );
  }
}
DatasetUploader.propTypes = {
  id: PropTypes.string,
  showDatasetNameInput: PropTypes.func
}
export default DatasetUploader;