import React, { Component } from 'react';
import './GimsReportFilter.css';
import { Accordion, Card, Button, Form, Col, Image } from 'react-bootstrap';
import EndpointConstant from '../../../constants/EndpointConstant';
import utils from '../../../utils';
import MultiSelect from "@khanacademy/react-multi-select";
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import PopupModels from '../../PopupModels/PopupModels';
import MessageConstant from '../../../constants/MessageConstant';
import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * A class that filter gims report 
 **/
class GimsReportFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconName: 'icon-drop-up',
            searchresult: [],
            selected: [],
            dataSet: [],
            selectedId: [],
            startDate: '',
            endDate: '',
            datePopup: false,
            errorMsg: '',
            selectedDatasetCopy:[]
        }
        this.iconChange = this.iconChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleOnClear = this.handleOnClear.bind(this);
    }
    /*
    * Toggling functionality for changing icon
    * in search header while showing and hiding search option
    */
    iconChange() {
        if (this.state.iconName === 'icon-drop-up') {
            this.setState({ iconName: 'icon-drop-down' })
        } else {
            this.setState({ iconName: 'icon-drop-up' })
        }
    }

    /*
    * Reset all the search filter and 
    * call search api to get all the data
    */
    handleOnClear(event) {
        event.preventDefault();
        this.setState({
            // iconName: 'icon-drop-up',
            selected:[],
            startDate: '',
            endDate: ''
        }, () => {
            this.handleSearch(event);
        })
        this.getDataSet();
    }

    /*
    * Getting all the dataset dropdown option
    * by calling dataset API for search section
    */
    getDataSet() {
        let data = {};
        let self = this;
        utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
            //console.log("get dataset", result);
            let filterDataSet = [];
            let selectedDataset = [];
            let selectedDatasetCopy = [];
            const options = [];
            
            filterDataSet = result.datasets.filter(data => data.status === 'Complete');             
            selectedDataset = filterDataSet.map(ds => ds.datasetName && ds.datasetName.split('.')[0]); 
            selectedDatasetCopy = selectedDataset.slice(0);  
            
            filterDataSet.forEach((dataset) => {
                options.push({ id: dataset.id, label: dataset.datasetName && dataset.datasetName.split('.')[0], value: dataset.datasetName && dataset.datasetName.split('.')[0] });
            }); 
            options.sort(self.compareDataset);
            
            self.setState({
                dataSet: options,
                selected: selectedDataset,
                selectedDatasetCopy: selectedDatasetCopy,
            })                                    
        });
    }

    /*
    * Close the date picker popup
    */
    handleDateClose() {
        this.setState({ datePopup: false });
    }
    compareDataset(a, b) {
        const valueA = a.label.toUpperCase();
        const valueB = b.label.toUpperCase();
    
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      } 
    /*
    * Call Search api to get all the filtered data
    * to show in charts
    */
    handleSearch(event) {
        event.preventDefault();
        const options = [];
        let filteredData = {};
        if (this.state.dataSet) {
            this.state.dataSet.forEach((dataset) => {
                 options.push({ id: dataset.id, label: dataset.label && dataset.label.split('.')[0], value: dataset.value && dataset.value.split('.')[0] });
            });
        }
        var selectedId = options.filter((data) => this.state.selected.indexOf(data.value) >= 0).map((d) => d.id);
        var startDate = this.state.startDate ? moment(this.state.startDate, "MM/D/YYYY").startOf('day').unix() : '';
        var endDate = this.state.endDate ? moment(this.state.endDate, "MM/D/YYYY").endOf('day').unix() : '';
        // eslint-disable-next-line 
        //console.log("startDate", moment.unix(startDate).format("MM/DD/YYYY hh:mm A"));
        // eslint-disable-next-line 
        //console.log("endDate", moment.unix(endDate).format("MM/DD/YYYY hh:mm A"));

        if (startDate !== '' && endDate === '') {
            this.setState({ datePopup: true, errorMsg: MessageConstant.END_DATE_EMPTY });
        }
        else if (startDate === '' && endDate !== '') {
            this.setState({ datePopup: true, errorMsg: MessageConstant.START_DATE_EMPTY });
        }
        else if (startDate > endDate) {
            // alert('Invalid date');
            this.setState({ datePopup: true, errorMsg: MessageConstant.INVALID_DATE });
        }
        else {
            if (this.state.selected.length > 0) {
                filteredData.datasetId = selectedId;
            }
            if (startDate) {
                filteredData.startDate = startDate;
            }
            if (endDate) {
                filteredData.endDate = endDate;
            }
            //console.log("search data", filteredData);
            //console.log("this.state", this.state);
            this.props.getGimsFilteredReport(filteredData);
        }

    }

    /*
    * Hook to call the dataset after render
    */
    componentDidMount() {
        this.getDataSet();
    }

    /*
    * Rendering the view UI
    */
    render() {
        const { selected, dataSet } = this.state;
        let maxDate = new Date();
        maxDate.setDate(maxDate.getDate());   
        
        return (
          <Accordion defaultActiveKey="0" className="cardPadding">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                onClick={this.iconChange}
                className="cardBodyStyle"
              >
                <span className="search-title">FILTER GIMS REPORT</span>{" "}
                <Image
                  src="/Images/ico-drop-up.png"
                  className={this.state.iconName}
                ></Image>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="cardBodyStyle multiSelheight">
                  <Form onSubmit={this.handleSearch} className="searchForm">
                    <Form.Row>
                      <Col md={2}>
                        <Form.Label>Data Set</Form.Label>
                        <MultiSelect
                          options={dataSet}
                          selected={selected}
                          disableSearch={false}
                          hasSelectAll={true}
                          onSelectedChanged={(selectedDataset) => {
                            //console.log("selected values", selected);
                            if (selectedDataset.length === 0) {
                              this.setState({ startDate: "", endDate: "" });
                            }
                            this.setState({ selected: selectedDataset });
                            return null;
                          }}
                          overrideStrings={{
                            selectSomeItems: "Select Data Set to Search",
                            allItemsAreSelected: "All are Selected",
                            selectAll: "Select All",
                          }}
                        />
                      </Col>
                      <React.Fragment>
                        <Col md={2}>
                          <Form.Label>Dataset Upload Start Date</Form.Label>
                          <Calendar
                            className="calender-styles calender-set"
                            placeholder="mm/dd/yyyy"
                            maxDate={maxDate}
                            value={this.state.startDate}
                            onChange={(e) =>
                              this.setState({ startDate: e.value })
                            }
                            showIcon={true}
                          />
                        </Col>
                        <Col md={2}>
                          <Form.Label>Dataset Upload End Date</Form.Label>
                          <Calendar
                            className="calender-styles calender-set"
                            placeholder="mm/dd/yyyy"
                            maxDate={maxDate}
                            value={this.state.endDate}
                            onChange={(e) =>
                              this.setState({ endDate: e.value })
                            }
                            showIcon={true}
                          />
                        </Col>
                      </React.Fragment>
                      <Button
                        variant="secondary"
                        type="submit"
                        className="scorecardSearch button-color userSearch"
                      >
                        Search
                      </Button>
                      <Button
                        variant="secondary"
                        className="clear userSearch"
                        onClick={this.handleOnClear}
                      >
                        Clear
                      </Button>
                      <Col></Col>
                    </Form.Row>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <PopupModels
              showPopup={this.state.datePopup}
              iconFlag="error"
              message={this.state.errorMsg}
              handleClosePopup={this.handleDateClose.bind(this)}
            />
          </Accordion>
        );
    }
}

GimsReportFilter.propTypes = {
    getGimsFilteredReport: PropTypes.func
}

export default GimsReportFilter;