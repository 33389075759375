import React, { Component } from 'react';
import './FilterCountryTotals.css';
import { Accordion, Card, Button, Form, Col, Image } from 'react-bootstrap';
// import CommonService from '../../../commonService/CommonService';
import EndpointConstant from '../../../constants/EndpointConstant';
import utils from '../../../utils';
import PropTypes from 'prop-types';
import MultiSelect from "@khanacademy/react-multi-select";
import * as uuid from 'uuid';
import { select } from 'd3';
const repeatCallTimeDelayInMS = 10000;
class FilterCountryTotals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconName: 'icon-drop-up',
            country: '',
            teamName: '',
            dataSet: [],
            datasetId: '',
            requestSuccess: false
        }
        this.iconChange = this.iconChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnClear = this.handleOnClear.bind(this);
        this.getDatasetList = this.getDatasetList.bind(this)
    }
    componentDidMount() {
        this.getDatasetList();
    }
    iconChange() {
        if (this.state.iconName === 'icon-drop-up') {
            this.setState({ iconName: 'icon-drop-down' })
        } else {
            this.setState({ iconName: 'icon-drop-up' })
        }
    }
    handleOnChange(event) {
        //console.log("onchange", event);
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    handleOnClear() {
        this.setState({
            country: '',
            teamName: '',
            dataSet: [],
            datasetId: ''
        });
        this.getDatasetList()
        this.props.getCountryTotals();
        this.props.onDownloadFilter({});
    }
    getDatasetList() {
        let data = {};
        let self = this;
        utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
            result.datasets.sort(self.compare);
            //console.log(result);
            self.setState({
                dataSet: result.datasets,
            });
        });
    }
    handleSearch(event) {
        event.preventDefault();
        //console.log("handle search", this.state);

        let self = this;
        const generatedId = uuid.v4();
        let data = {getGeneratedResultId: generatedId,}
        if (this.state.country.length!=0) {
            data.country = this.state.country
        }
        if (this.state.teamName.length!=0) {
            data.teamName = this.state.teamName
        }
        if (this.state.datasetId.length!=0) {
            data.datasetId = this.state.datasetId
        }
        this.setState({ requestSuccess: false }, () => self.filterData(data));

    }

    filterData(filteredData, continous) {
        if (this.state.requestSuccess)
          return true;
        const generatedId = uuid.v4();
        let data = filteredData ? filteredData : {getGeneratedResultId: generatedId,};
        let self = this;
        utils.showLoader();
        if (!continous) {
            setTimeout(() => {data.getGeneratedResult = true;self.filterData(data, true)}, repeatCallTimeDelayInMS);
        }
        utils.makeRequest(this, EndpointConstant.FILTER_COUNTRYTOTALS, data, function (result) {
            if (result.error === 'false' || result.error === false) {
                utils.hideLoader()
                self.props.countryTotalsData(result, 'filter');
                self.props.onDownloadFilter(data);
                self.setState({ requestSuccess: true });
            } else {
                utils.showLoader()
                data.getGeneratedResult = true;
                setTimeout(() => {self.filterData(data, true)}, repeatCallTimeDelayInMS);
            }
            
            // self.props.resetPagination();
            // self.loading = false;
        });
    }

    compareDataset(a, b) {
        const valueA = a.label.toUpperCase();
        const valueB = b.label.toUpperCase();
    
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      } 
    render() {
        const {  dataSet } = this.state;
        let countryList = [];
        // const countryList = this.props.countryList.sort().map((countryList, index) => {
        //     return (
        //         <option key={index} value={countryList}>{countryList}</option>
        //     )
        // });
        this.props.countryList.sort().map((country, i) => {
            return countryList.push({ id: country, label: country, value: country });
           
          });
          
        // const datasetList = dataSet.sort().map((dataset, index) => {
        //     if (dataset.status === 'Processing') {
        //         return null;
        //     }
        //     return (
        //         <option key={index} value={dataset.id}>{dataset.datasetName && dataset.datasetName.split('.')[0]}</option>
        //     )
        // });
        //let datasetList = [];
        let filterDataSet = [];
        filterDataSet = dataSet.filter(data => data.status === 'Complete');
        let datasetList = filterDataSet.map((dataset, i) => {
            return ({ id: dataset.id, label: dataset.datasetName && dataset.datasetName.split('.')[0], value: dataset.id });
           
          });
          datasetList.sort(this.compareDataset);
        // const teamList = this.props.teamNames.sort().map((team, i) => {
        //     return (
        //         <option key={i} value={team}>{team}</option>
        //     )
        // })
        let teamList = []
        this.props.teamNames.sort().map((team, i) => {
            return teamList.push({ id: team, label: team, value: team });
           
          });
        return (
            <Accordion defaultActiveKey="0" className='cardPadding'>
                <Card >
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
                        <span className="search-title">FILTER COUNTRY TOTALS</span> <Image src="/Images/ico-drop-up.png" className={this.state.iconName} aria-hidden="true"></Image>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className='cardBodyStyle'>
                            <Form onSubmit={this.handleSearch} className="searchForm">
                                <Form.Row>
                                    <Col>
                                        <Form.Label>Country</Form.Label>
                                        {/* <Form.Control as='select' name='country' value={country} onChange={this.handleOnChange}>
                                            <option value="">--- ALL ---</option>
                                            {countryList}
                                        </Form.Control> */}
                                        <MultiSelect
                                            options={countryList}
                                            selected={this.state.country}
                                            disableSearch={false}
                                            hasSelectAll={true}
                                            onSelectedChanged={selected => {
                                                //console.log("selected values", selected);
                                                this.setState({ country: selected });
                                                return null;
                                            }
                                            }
                                            overrideStrings={{
                                                selectSomeItems: "--Select--",
                                                allItemsAreSelected: "All are Selected",
                                                selectAll: "Select All"
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Team Name</Form.Label>
                                        {/* <Form.Control as='select' name='teamName' value={teamName} onChange={this.handleOnChange}>
                                            <option value="">--- ALL ---</option>
                                            {teamList}
                                        </Form.Control> */}
                                        <MultiSelect
                                            options={teamList}
                                            selected={this.state.teamName}
                                            disableSearch={false}
                                            hasSelectAll={true}
                                            onSelectedChanged={selected => {
                                                //console.log("selected values", selected);
                                                this.setState({ teamName: selected });
                                                return null;
                                            }
                                            }
                                            overrideStrings={{
                                                selectSomeItems: "--Select--",
                                                allItemsAreSelected: "All are Selected",
                                                selectAll: "Select All"
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Data Set</Form.Label>
                                        {/* <Form.Control as='select' name='datasetId' value={datasetId} onChange={this.handleOnChange}>
                                            <option value="">--- ALL ---</option>
                                            {datasetList}
                                        </Form.Control> */}
                                         <MultiSelect
                                            options={datasetList}
                                            selected={this.state.datasetId}
                                            disableSearch={false}
                                            hasSelectAll={true}
                                            onSelectedChanged={selected => {
                                                //console.log("selected values", selected);
                                                this.setState({ datasetId: selected });
                                                return null;
                                            }
                                            }
                                            overrideStrings={{
                                                selectSomeItems: "--Select--",
                                                allItemsAreSelected: "All are Selected",
                                                selectAll: "Select All"
                                            }}
                                        />
                                    </Col>

                                    <Button variant="secondary" type="submit" className='scorecardSearch button-color userSearch'>Search</Button>
                                    <Button variant="secondary" className='clear userSearch' onClick={this.handleOnClear}>Clear</Button>
                                    <Col></Col>
                                </Form.Row>
                            </Form></Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
FilterCountryTotals.propTypes = {
    getCountryTotals: PropTypes.func,
    teamNames: PropTypes.array,
    countryList: PropTypes.array,
    onDownloadFilter: PropTypes.func
}

export default FilterCountryTotals;