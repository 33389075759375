import React, { Component } from 'react';
import { Modal, Button, Image,Form } from 'react-bootstrap';
import './Dataset.css';
import DatasetList from './DatasetList';
import DatasetUploader from './DatasetUploader';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
import { Sidebar, Header } from '..';
import MessageConstant from './../../constants/MessageConstant'
import PopupModels from '../PopupModels/PopupModels';
import Pagination from '../Pagination/Pagination';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {ProgressBar} from 'primereact/progressbar';
import moment from 'moment';

// import Files from './Files/Files';
// import {Redirect} from 'react-router-dom';
/**
 * A class that can return some html
 *@return {Object} .
 *@param {object} props
 *@deprecated 
 */
class Dataset extends Component {

  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    //this.percentExtract = this.percentExtract.bind(this);
    this.state = {
      dataset: [],
      dataSort: [],
      percent: 3,
      showDatasetNameInput: false,
      datasetName: '',
      datasetType: '',
      datasetDeleteSuccess: false,
      showpagination: false,
      startDate:'',
      endDate:'',
      showDataSync: false,
      extractValue:0,
      extractSuccess: false,
      extractValidation: false,
      extractErrMsg: '',
      datasetDataAfterBioconnect: {},
      extractResponseMsg: '',
      extractResponseFlagIcon: '' 
    };
    this.onSortDataList = this.onSortDataList.bind(this);
    this.handleDatasetNameInputClose = this.handleDatasetNameInputClose.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.showDatasetNameInput = this.showDatasetNameInput.bind(this);
    this.performFileUpload = this.performFileUpload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.hideDatasetNameInput = this.hideDatasetNameInput.bind(this);
    this.deleteDataSet = this.deleteDataSet.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.extractDataFromBioConnect = this.extractDataFromBioConnect.bind(this);
    this.hideDataSyncOnCancel = this.hideDataSyncOnCancel.bind(this);
    this.handleExtractSuccessClose = this.handleExtractSuccessClose.bind(this);
    this.interval = null;
    this.handleExtractValidationClose = this.handleExtractValidationClose.bind(this);
    this.getDatasetListForBioconnect = this.getDatasetListForBioconnect.bind(this);
  }
  componentDidMount() {
    this.getDatasetList();
  }
  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  hideDatasetNameInput() {
    this.setState({ showDatasetNameInput: false })
  }
  downloadDataset(id) {

    //console.log("downloadDataset ++++++ " + id);

    const data = {
      datasetName: id
    };
    utils.makeRequest(this, EndpointConstant.DOWNLOAD_DATASET, data, function (result) {

      //console.log(JSON.stringify(result));

      window.open(result.url, 'Download');

    });
  }
  onDrop(result) {

    result.sort(this.compare);
    this.setState({
      dataset: result
    });
  }

  compare(a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    return 0;
  }

  getDatasetList() {

    //console.log("getDatasetList ++++++ ");
    utils.showLoader()
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
      utils.hideLoader()
      result.datasets.sort(self.compare);
      self.setState({
        dataset: result.datasets,
        dataSort: result.datasets
      }, () => self.setState({ showpagination: true }));

      //console.log(JSON.stringify(result));

    });
  }

getDatasetListForBioconnect() {
  let data = {};
    let self = this;
    utils.makeRequestInBackground(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {

      result.datasets.sort(self.compare);

      self.setState({
        dataset: result.datasets,
        datasetDataAfterBioconnect: result.datasets[0],
      }, () => self.setState({ showpagination: true }));

      //console.log(JSON.stringify(result));

    });
}

  percentExtract(data) {
    switch (data) {
      case 'minus':
        this.setState((p) => {
          //console.log(p);
          // eslint-disable-next-line 
          let percent = parseInt(p.percent) - 1;
          if (percent <= 0) return p.percent = 1;
          else return p.percent = percent;
        });
        break;
      case 'plus':
        this.setState((p) => {
          //console.log(p);
          // eslint-disable-next-line 
          let percent = parseInt(p.percent) + 1;
          if (percent > 100) return p.percent = 100;
          else return p.percent = percent;
        });
        break;
      default:
        break;
    }
  }

  /*
  * on Sort functionality
  */
  sortDataList(prop, sortId) {
    return (a, b) => {
      if (sortId.sortId === 'sort') {
        return a[prop].toString().localeCompare(b[prop].toString());
      }
      else if (sortId.sortId === 'sort-up') {
        return b[prop].toString().localeCompare(a[prop].toString());
      }
      else if (sortId.sortId === 'sort-down') {
        return a;
      }
    }
  }
  onSortDataList(data, e) {
    e.preventDefault();
    let sortId = e.target.querySelector('i').id;
    // let className = e.target.querySelector('i').className;
    if (sortId === 'sort') {
      e.target.querySelector('i').id = 'sort-up';
      e.target.querySelector('i').className = 'fas fa-sort-up';
    }
    else if (sortId === 'sort-up') {
      e.target.querySelector('i').id = 'sort-down';
      e.target.querySelector('i').className = 'fas fa-sort-down';
    }
    else if (sortId === 'sort-down') {
      e.target.querySelector('i').id = 'sort';
      e.target.querySelector('i').className = 'fas fa-sort';
    }
    const { dataSort } = this.state;
    switch (data) {
      case 'name':
        {
          let datasetName = [...dataSort].sort(this.sortDataList('datasetName', { sortId: sortId }));
          this.setState({
            dataset: datasetName
          });
        }
        break;
      case 'createdat': {
        let createdAt = [...dataSort].sort(this.sortDataList('createdAt', { sortId: sortId }));
        this.setState({
          dataset: createdAt
        });
      }
        break;
      case 'createdby': {
        let createdBy = [...dataSort].sort(this.sortDataList('createdBy', { sortId: sortId }));
        this.setState({
          dataset: createdBy
        });
      }
        break;
      case 'status': {
        let status = [...dataSort].sort(this.sortDataList('status', { sortId: sortId }));
        this.setState({
          dataset: status
        });
      }
        break;
      default:
        break;
    }
  }

  showDatasetNameInput(id, files, fileType) {
    if (fileType) {
      this.setState({
        showDatasetNameInput: true,
        currentDatasetInput: 'upload',
        datasetName: '',
        files: files,
        fileType,
        id: id,
      });
    } else {
      this.setState({
        datasetName: files.split('.')[0],
        datasetType: files.split('.')[1],
        showDatasetNameInput: true,
        currentDatasetInput: 'editName',
        datasetId: id,

      });
    }
  }

  handleDatasetNameInputClose() {
    let self = this;
    let datasetName = this.state.datasetName;
    if (datasetName.length === 0) {
      this.refs.header.showAlertDialog("Please enter dataset name");
      return;
    }
    let datasetNameExist = false;
    this.state.dataset.forEach((data) => {
      const datasetNames = data.datasetName.split('.')[0];
      if (datasetNames.toString().toLowerCase() === self.state.datasetName.toString().toLowerCase()) {
        datasetNameExist = true;
      }
    });
    if (datasetNameExist) {
      this.refs.header.showAlertDialog("Dataset name already exist!");
      return;
    }
    if (this.state.currentDatasetInput === 'upload') {
      this.performFileUpload(this.state.files, this.state.fileType, this.state.id);
    } else {
      const data = {
        id: this.state.datasetId,
        name: this.state.datasetName + '.' + this.state.datasetType
      };
      this.setState({
        showDatasetNameInput: false,
      });
      utils.makeRequest(this, EndpointConstant.UPDATE_DATASET_NAME, data, function (result) {
        //console.log(JSON.stringify(result));
        self.onDrop(result.datasets);
        self.setState({
          successDialog: true,
          successMessageContent: 'Your Dataset name has been updated successfully',
          datasetName: ''
        });
      });
    }
  }

  performFileUpload(files, fileType, id) {
    let self = this;
    this.setState({
      showDatasetNameInput: false,
    });
    utils.uploadFiles(this, files[0], fileType, id, function (result) {
      //console.log(JSON.stringify(result.datasetList));
      self.onDrop(result.datasetList);
      self.setState({
        successDialog: true,
        successMessageContent: 'Your Dataset upload is still inprogress',
      });
    }, this.state.datasetName, EndpointConstant.UPLOAD_DATASET);
  }

  closeDialogBox() {
    this.setState({
      successDialog: false,
    });
  }
  deleteDataSet(data) {
    // let self=this
    data.sort(self.compare);
    this.setState({
      dataset: data,
      dataSort: data
    }, () => { this.setState({ datasetDeleteSuccess: true }) });

    //console.log(JSON.stringify(data));
  }
  handleDeleteClose() {
    this.setState({ datasetDeleteSuccess: false })
  }
  onChangePage(currentTodos) {
    this.setState({ currentTodos: currentTodos }, () => { this.setState({ showpagination: true }) })
  }

  handleOnChangeInput(e){
    const {value} = e.target;
     if(value >= 0 && value <=100) {
      this.setState({
        percent:e.target.value
       });
    } 
  }
  extractDataFromBioConnect(event){
    event.preventDefault();
    //console.log("extract Data from bio connect");
    if(this.state.startDate === "" || this.state.endDate === ""){
      //console.log("Invalid date");
      this.setState({
        extractValidation: true,
        extractErrMsg: 'Start date or End date should not be empty'
      });
      //console.log("unix date",moment(new Date(this.state.startDate), 'DD/MM/YYYY hh:mm:ss').unix());
    }
    else {
       const unixStartdate = moment(new Date(this.state.startDate), 'DD/MM/YYYY hh:mm:ss').unix();
       const unixEnddate = moment(new Date(this.state.endDate), 'DD/MM/YYYY hh:mm:ss').unix();
       //console.log("unix date",unixStartdate,"-----", unixEnddate);
       if(unixStartdate > unixEnddate){
         //console.log("start date should not greater than end date");
         this.setState({
          extractValidation: true,
          extractErrMsg: 'Start date should not be greater than End date'
        });
       }
       else {
         let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
         let endDate = moment(this.state.endDate).format('YYYY-MM-DD');
         const payload = {
           startDate: startDate,
           endDate: endDate,
           extractPercentage: this.state.percent
         }
         let self = this;
         //console.log("Payload",payload);
         utils.makeRequestInBackground(this, EndpointConstant.BIOCONNECT_EXTRACT, payload, function (result) {
          //console.log("after bioconnect call",JSON.stringify(result));
          if(result.hasOwnProperty('errorCode')){
            if(result.errorCode === "D_OLD_401" || result.errorCode === "D_IN_PROGRESS"){
              //console.log("errorCode",result.errorCode);
              //clearInterval(this.interval);
              self.hideDataSyncOnCancel();
              self.setState({    
                extractValue:0
              });
            }
          }
          else {
            //console.log("results",result);
            if(result.data.length === 0) {
              self.setState({
                extractResponseMsg:result.message
              });
            }
          }

         
        });
        clearInterval(this.interval);
        //console.log("this state",this.state);
        this.interval =null;
        this.setState({
          showDataSync: true
        });
        this.interval = setInterval(() => {
          this.getDatasetListForBioconnect();
          let val = this.state.extractValue;
          val += Math.floor(Math.random() * 10) + 1;

         if(val >= 100) {
            val = 100;
         }
          /*
            // this.growl.show({severity: 'info', summary: 'Success', detail: 'Process Completed'});
            
            this.hideDataSyncOnCancel();
            this.setState({
              extractSuccess: true,
              extractValue:0
            });
            clearInterval(this.interval);
              this.interval =null;
          } */
          if(this.state.datasetDataAfterBioconnect.hasOwnProperty('extractStatus')){
            if(this.state.datasetDataAfterBioconnect['extractStatus'] === 'Complete' && this.state.datasetDataAfterBioconnect['status'] === 'Processing') {
              this.hideDataSyncOnCancel();
              this.setState({
                extractSuccess: true,
                extractValue:0,
                extractResponseMsg: MessageConstant.DATASET_EXTRACTED_MESSAGE,
                extractResponseFlagIcon: 'success'
              });
              clearInterval(this.interval);
              this.interval =null;
            }
            else if(this.state.datasetDataAfterBioconnect['extractStatus'] === 'Complete' && this.state.datasetDataAfterBioconnect['status'] === 'Error') {
              this.hideDataSyncOnCancel();
              this.setState({
                extractSuccess: true,
                extractValue:0,
                extractResponseFlagIcon: 'error'
              });
              clearInterval(this.interval);
              this.interval =null;
            }
          }
          
          this.setState({
              extractValue: val
          });
        }, 10000);
       }
    }
    
  }

  hideDataSyncOnCancel(event) {
    //event.preventDefault();
    this.setState({
      showDataSync: false,
      extractValue: 0
    });
    clearInterval(this.interval);
    this.interval =null;
  }
  handleExtractSuccessClose(){
    this.setState({
      extractSuccess: false
    })
  }
  handleExtractValidationClose() {
    this.setState({
      extractValidation: false,
      extractErrMsg: ''
    })
  }
  render() {
   /* const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    const files = acceptedFiles.map((file) => {
      return (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      );
    }); */
    //console.log("bioconnect",this.state.datasetDataAfterBioconnect);
    return (
      <div className="dataset-list-screen">
        <Modal dialogClassName="deletionModal" show={this.state.successDialog} aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'></Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Image src="/Images/ico-success.png" className='success-img'></Image>
            <div className='success-styles'>Success!</div>
            {this.state.successMessageContent}
          </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.closeDialogBox.bind(this)} className='user-deletion-cancel'>Ok</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showDatasetNameInput} onHide={this.hideDatasetNameInput} className="dataset-list-screen-dialog">
          <Modal.Header closeButton>
            <Modal.Title>DATA SET NAME</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="dataset-name-entry-content">
              <label>Data Set Name</label><br />
              <input type="text" className="datasetname-input" value={this.state.datasetName} name="datasetName" onChange={this.changeHandler}></input>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleDatasetNameInputClose}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Header ref={`header`} />
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="menu-content">
          <h4 className="dataset-title">Upload Files</h4>
          <div className="row file-row">
            <div className="col-md-4 drag-drop">
              <DatasetUploader onDrop={this.onDrop} self={this} showDatasetNameInput={this.showDatasetNameInput} />
                {/* <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <div className="drag-text">
                  <p>Drag &apos; n &apos; drop some files here, or click to select files upto 20MB</p>
                  </div>
                </div>  */}
            </div>
            <div className="col-md-1 or-circle">
              <h5 className="drag-text">Or</h5>
              </div>
              <div className="col-md-7">
                <div className="drag-text">
                   <div className="plus-min">
                    <span className="plus-min-box minus" onClick={()=>this.percentExtract("minus")}>-</span>
                    {/* {<span className="plus-min-box percent">{this.state.percent}%</span> } */}
                    <span className='plus-min-box percent'>
                    <input type='text' className='percentStyle' value={this.state.percent} onChange={this.handleOnChangeInput}/>
                    %</span>
                  <span className="plus-min-box plus" onClick={()=>this.percentExtract("plus")}>+</span>
                  </div>
                  <div className="datasetStartdate">
                  <Form.Label>Start Date</Form.Label>
                  <Calendar className='calender-styles' style={{width:'132px'}} value={this.state.startDate} onChange={(e) => this.setState({ startDate: e.value  })} monthNavigator={true} yearNavigator={true} yearRange="2000:2100" showIcon={true} placeholder='mm/dd/yyyy'  />
                  </div>
                  <div className="datasetEnddate">
                  <Form.Label>End Date</Form.Label>
                  <Calendar className='calender-styles' style={{width:'132px'}}  value={this.state.endDate} onChange={(e) => this.setState({ endDate: e.value  })} monthNavigator={true} yearNavigator={true} yearRange="2000:2100" showIcon={true} placeholder='mm/dd/yyyy' />
                  </div>
                  
                  <button className="btn-extract" onClick={this.extractDataFromBioConnect}>
                  <img src="/Images/ico-extract-btn.png" className="extract-btn-icon" alt='Amgen'/><span className="extract-text">Extract Data</span></button>
                </div>
              </div> 
             
          </div>
          <DatasetList dataset={this.state.currentTodos} downloadDataset={this.downloadDataset} onSortDataList={this.onSortDataList} showDatasetNameInput={this.showDatasetNameInput} deleteDataSet={this.deleteDataSet} />
          {this.state.showpagination ? <Pagination data={this.state.dataset} onChangePage={this.onChangePage} /> : ''}
          <PopupModels showPopup={this.state.datasetDeleteSuccess} iconFlag="success" message={MessageConstant.DATASET_DELETED} handleClosePopup={this.handleDeleteClose.bind(this)} />
        </div>
        {/*
          * Model popup for Bio connnect Extract button clicked 
          */}
         <Modal show={this.state.showDataSync} onHide={this.hideDataSyncOnCancel} className="dataset-extract-dialog">
          <Modal.Header closeButton>
            <Modal.Title>DATA SET SYNC</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <ProgressBar style={{'height':'10px','borderRadius': '5px','border':'1px solid #0063C3','background':'#F2F2F2'}} showValue={false} value={this.state.extractValue} />
           <p className="progressMsg">Data sync is in progress...</p>
           <p className="progressMsg">{this.state.extractValue}% completed</p>
          </Modal.Body>
          <Modal.Footer className="confirm-popup-header">
            <Button variant="primary" className="clear " onClick={this.hideDataSyncOnCancel}>
              Cancel
            </Button>
          </Modal.Footer>
         </Modal>
         <PopupModels showPopup={this.state.extractSuccess} iconFlag={this.state.extractResponseFlagIcon} message={this.state.extractResponseMsg} handleClosePopup={this.handleExtractSuccessClose.bind(this)} />
         <PopupModels showPopup={this.state.extractValidation} iconFlag="error" message={this.state.extractErrMsg} handleClosePopup={this.handleExtractValidationClose.bind(this)} />
      </div>
    );
  }
}

export default Dataset;
