import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import './ReportsMangement.css';
import { Image, Modal, Button } from 'react-bootstrap';
import utils from '../../../utils';
import EndpointConstant from '../../../constants/EndpointConstant';
import MessageConstant from '../../../constants/MessageConstant'
import PopupModels from '../../PopupModels/PopupModels'
/**
 * A class that can return some html
 *@return {Object} .
 */
class ReportList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmationModal: false,
      reportDeleteSuccess: false,
      reportId: '',
      report: this.props.report,
      deleteMessage: '',
      deletefail: false,     
      usersInSystem:{}
    }
    this.downloadReport = this.downloadReport.bind(this);    
    this.deleteConfirmation = this.deleteConfirmation.bind(this);
    this.deleteReport = this.deleteReport.bind(this);
    this.deleteConfirmationHide = this.deleteConfirmationHide.bind(this);
    this.handleClose = this.handleClose.bind(this);         
  }

  componentDidMount() {
    this.getUsersList();
  }

  getUsersList() {
    let data = {};
    let self = this
    utils.makeRequest(this, EndpointConstant.GET_USER_LIST, data, function (result) {
      const users = result.users;
      let usersobj = {}

      //Adding self user
      let UserId = JSON.parse(window.localStorage.getItem('userId'))
      let firstName = JSON.parse(window.localStorage.getItem('firstName'))
      let lastName = JSON.parse(window.localStorage.getItem('lastName'))
      usersobj[UserId] = firstName + ' ' + lastName

      users.forEach(element => {
        let name = element.firstName + ' ' + element.lastName
        usersobj[element.id] = name
      });
      self.setState({ usersInSystem: usersobj})
    })
  }

  downloadReport(id) {
    this.props.downloadReport(id);
  }

  deleteConfirmation(id, data) {
    this.setState({ deleteConfirmationModal: true, reportId: id })
    return null;
  }  
  
  deleteConfirmationHide() {
    this.setState({ deleteConfirmationModal: false })
  }
  handleDeleteClose() {
    this.setState({ reportDeleteSuccess: false })
  }
  handleClose() {
    this.setState({ deletefail: false })
  }


  deleteReport() {
    let self = this
    let data = {
      id: this.state.reportId
    }
    utils.makeRequest(this, EndpointConstant.DELETE_REPORT, data, function (result) {
      if (result.error === false) {
        const report = result.reports;
        self.loading = false;
        self.setState({ deleteConfirmationModal: false })
        self.props.deleteReport(report);
      } else {
        self.setState({
          deleteMessage: MessageConstant.REPORT_ERRORS,
          deleteConfirmationModal: false,
          deletefail: true,
        });
      }
    })
  }
  render() {
    const claimFiles = this.props.report && this.props.report.map((data) => {
      return (
        <tr key={data.id}>
          <td><img src="/Images/ico-doc-excel.png" className='document-icon' alt='Amgen' />{data.reportName.split('.')[0]}</td>
          <td>
            <Moment unix format="MM/D/YYYY">
              {data.createdAt}
            </Moment></td>
          <td>{this.state.usersInSystem[data.createdBy]}</td>        
          <td className="document-tableheader">
            <a className="downloadLink" onClick={this.downloadReport.bind(null, data.id)}><img className="document-action" src="/Images/ico-download.png" alt='Amgen' />Download</a>
            <a className='delete-error' onClick={() => this.deleteConfirmation(data.id, data)}>
              <Image src="/Images/ico-delete.png" className='image-padding'></Image>&nbsp;Delete</a>
          </td> 
        </tr>
      );
    });
    return (
      <div >
        
        <br />
        <h4 className="document-title">Report Files</h4>
        <table>
          <tbody>
            <tr>
              <th>Report Name</th>
              <th>Date Added</th>
              <th>Report Uploaded By</th>              
              <th className="document-tableheader">Action</th>
            </tr>
            {claimFiles}
          </tbody>
        </table>
        
        {/* </aside> */}
        <Modal
          dialogClassName="deletionModal"
          show={this.state.deleteConfirmationModal}
          onHide={this.deleteConfirmationHide}
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            <div>{MessageConstant.DELETING_REPORT}</div>
          </Modal.Body>
          <Modal.Footer className='confirmation-footer'>
            <Button onClick={this.deleteConfirmationHide} className='clear'>
              No
            </Button>
            <Button active onClick={this.deleteReport}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>    

        <PopupModels showPopup={this.state.deletefail} iconFlag="error" message={this.state.deleteMessage} handleClosePopup={this.handleClose.bind(this)} />               
       
      </div>
    );
  }
}
ReportList.propTypes = {
  report: PropTypes.array,
  downloadReportt: PropTypes.func,
  onSortDataList: PropTypes.func
}
export default ReportList;
