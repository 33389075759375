import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Growl } from 'primereact/growl';
import './FileUploader.css';
import utils from './../../utils';
import PropTypes from 'prop-types';

class FileUploader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showUploadImage: false,
      highlightDragAndDrop: false,
      enableUpload: true
    };
    this.props = props;
    this.getExtension = this.getExtension.bind(this);
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  onDropFile(files) {
    //console.log("files", files);
    let self = this;
    // let titleIcon = files[0].name;
    let id = this.props.id ? this.props.id : null;
    let fileExtension = [];
    let fileType = [];
    let allowedFileAttachments;
    let exitFlag = false
    files.forEach((data, index) => {

      //File size validation if provided in props -- in MB      
      if(this.props.maxFileSize){
        let maxFileSizeAllowed = parseInt(this.props.maxFileSize)
        let fileSize = data.size / 1024 / 1024
        if(fileSize > maxFileSizeAllowed){
          this.growl.show({ severity: 'error', summary: 'Error', detail: `Please select file having size less than or equals to ${maxFileSizeAllowed} MB`, life: 5000 });
          exitFlag = true
          return;
        }
      }      
      //Validation End 

      fileExtension.push(this.getExtension(data.name));
      fileExtension.forEach((extension, i) => {
        // console.log("data check",data);
        extension = extension.toLowerCase();

        if (i === index) {
          if (!id) {
            if (extension === 'xlsx' || extension === 'csv' || extension === 'xls') {
              fileType.push(data.type);
            }
            else {
              //alert('Please select a valid Excel file.');
              this.growl.show({ severity: 'error', summary: 'Error', detail: `Please select a valid Excel file.`, life: 5000 });
              return;
            }
          } else {
            const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
            allowedFileAttachments = siteinfo.AllowedFileAttachments;
            if (!allowedFileAttachments.hasOwnProperty(extension)) {
              //alert('Please select a valid Attachment file.');
              this.growl.show({ severity: 'error', summary: 'Error', detail: `Please select a valid Attachment file.`, life: 5000 });
              setTimeout(() => {
                self.props.hideDatasetInput();
                if (self.props.hasOwnProperty('hideInput')) {
                  self.props.hideInput();
                }
              }, 0);

              return;
            }
          }

          // fileType.push(allowedFileAttachments[extension]);
          if (allowedFileAttachments.hasOwnProperty(extension)) {
            if (extension != 'msg') {
              fileType.push(data.type);
            }

            if (extension === 'xlsx') {
              if (!data.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                fileType.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
              }

            } else if (extension === 'csv') {
              if (!data.type == 'text/csv') {
                fileType.push('text/csv');
              }

            } else if (extension === 'xls') {
              if (!data.type == 'application/vnd.ms-excel') {
                fileType.push('application/vnd.ms-excel');
              }

            }
            else if (extension === 'zip') {
              if (!data.type == 'application/x-zip-compressed') {
                fileType.push('application/x-zip-compressed');
              }

            }
            // else if (extension === 'docx') {
            //   if(data.type != 'application/msword') {
            //     fileType.push('application/msword');
            //   }

            // }
            else if (extension === 'msg') {
              if (data.type != 'application/vnd.ms-outlook') {
                fileType.push('application/vnd.ms-outlook');
              }

            }

          }

        }
      });
    });


    if(exitFlag){
      return
    }
    this.props.showDatasetNameInput(id, files, fileType);    
  }

  onDragEnter() {
    this.setState({
      highlightDragAndDrop: true
    });
  }

  onDragLeave() {
    this.setState({
      highlightDragAndDrop: false
    });
  }

  handleImageErrored() {
    this.setState({
      showErrorImage: true
    });
  }

  render() {
    return (
      <React.Fragment>
        <Growl ref={el => (this.growl = el)} />
        <Dropzone
          className="dropzone"
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          onDrop={this.onDropFile.bind(this)}>
          <div className="drag-text">
            <img src="/Images/ico-cloud-dragNdrop.png" className="file-drop-icon" alt='Amgen' />
            <p className="select-file-title">Drop files here or click to <u>select files to upload</u></p>
          </div>
        </Dropzone>
      </React.Fragment>
    );
  }
}
FileUploader.propTypes = {
  id: PropTypes.string,
  self: PropTypes.object,
  showDatasetNameInput: PropTypes.func,
  hideDatasetInput: PropTypes.func,
}
export default FileUploader;