import React, { Component } from 'react';
import { Accordion, Card, Button, Form, Col, Modal, Image } from 'react-bootstrap';
import CommonService from '../../commonService/CommonService';
import EndpointConstant from './../../constants/EndpointConstant';
import MessageConstant from './../../constants/MessageConstant';
import utils from './../../utils';
import PropTypes from 'prop-types';
import RoleConstant from './../../constants/RoleConstant';
import MultiSelect from "@khanacademy/react-multi-select";
class DataModelSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      iconName: 'icon-drop-up',
      modelType: '',
      Status: '',
      searchresult: [],
      startTrainingConfirmation: false,
      trainingStartedSuccessDiaog: false,
      deploymentStartedSuccessDiaog: false,
      deployModelConfirmation: false,
      deployModelConfirmationMsg: MessageConstant.DEPLOY_PROD_CONFIRMATION,
      trainingVersion: '',
      showVersionSelection: false,
    };
    this.iconChange = this.iconChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.handleConfirmClose = this.handleConfirmClose.bind(this);
    this.triggerStartTraining = this.triggerStartTraining.bind(this);
    this.getPreConfiguredValuesFromSiteInfo = this.getPreConfiguredValuesFromSiteInfo.bind(this);
    this.checkToShowWarning = this.checkToShowWarning.bind(this);
  }
  iconChange() {
    if (this.state.iconName === 'icon-drop-up') {
      this.setState({ iconName: 'icon-drop-down' })
    } else {
      this.setState({ iconName: 'icon-drop-up' })
    }
  }
  handleOnChange(event) {
    //console.log("onchange", event);
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  handleOnClear() {
    this.setState({
      // iconName: 'fa fa-caret-up iconstyles-case',
      modelType: '',
      Status: '',
    })
    this.props.getModelReports();
  }
  handleSearch(event) {
    event.preventDefault();
    //console.log("handle search", this.state);

    let self = this
    let data = {}
    if (this.state.modelType.length!==0) {
      data.modelType = this.state.modelType
    }
    if (this.state.Status !== '') {
      data.Status = this.state.Status
    }
    //console.log("data",data);
    utils.makeRequest(this.props.self, EndpointConstant.FILTER_DATA_MODEL, data, function (result) {
      let searchresult = result.dataModelReports;
      // self.loading = false;
      searchresult = searchresult.sort(function (a, b) {
        return b.timestamp - a.timestamp;
      });
      self.setState({
        searchresult: searchresult
      }, () => { self.props.getSearchResult(searchresult) });

    })

  }

  startTraining() {
    this.setState({ startTrainingConfirmation: true });
  }
  closeModelDeployment() {
    this.setState({})
  }
  startModelDeployment() {
    this.setState({ deployModelConfirmation: true, showVersionSelection: false });
  }
  triggerModelDeployment() {
    if (this.checkToShowWarning(this.state.trainingVersion) && this.state.deployModelConfirmationMsg === MessageConstant.DEPLOY_PROD_CONFIRMATION) {
      this.setState({
        deployModelConfirmationMsg: MessageConstant.RECENT_TRAINING_ERR_CONFIRMATION
      });
      return;
    } else {
      this.setState({
        deployModelConfirmationMsg: MessageConstant.DEPLOY_PROD_CONFIRMATION,
        deployModelConfirmation: false,
      });
    }
    let data = { latestTrainedVersion: this.state.trainingVersion };
    let self = this;
    utils.makeRequest(this.props.self, EndpointConstant.START_MODEL_DEPLOYMENT, data, function () {
      self.setState({
        deploymentStartedSuccessDiaog: true
      });
    });
  }
  triggerStartTraining() {
    let data = {};
    let self = this;
    this.handleConfirmClose();
    utils.makeRequest(this.props.self, EndpointConstant.START_MODEL_TRAINING, data, function () {
      self.setState({
        trainingStartedSuccessDiaog: true
      });
    });
  }
  showVersionSelection() {
    this.setState({ showVersionSelection: true });
  }
  handleConfirmClose() {
    this.setState({
      startTrainingConfirmation: false,
      deployModelConfirmation: false,
      deployModelConfirmationMsg: MessageConstant.DEPLOY_PROD_CONFIRMATION,
      showVersionSelection: false
    });
  }
  closeDialogBox() {
    this.setState({
      trainingStartedSuccessDiaog: false,
      deploymentStartedSuccessDiaog: false,
    });
  }
  getPreConfiguredValuesFromSiteInfo() {
    //console.log("acceptanceDetails",this.props.acceptanceDetails)
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));

    return siteinfo.DataModelReportConfig;
  }
  checkToShowWarning(version) {
    // const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    // const medianLimit = parseFloat(siteinfo.DataModelReportConfig.medianLimitRange);
    const preConfiguredValues = this.props.acceptanceDetails;
    let showWarning = false;
    const selectedVersion = {
      AE: {
        timestamp: 0
      },
      PC: {
        timestamp: 0
      },
      OSF: {
        timestamp: 0
      },
    };
    this.props.data.forEach((item) => {
      if (version === item.version && item.modelType === 'AE') {
        selectedVersion.AE = item;
      }
      if (version === item.version && item.modelType === 'PC') {
        selectedVersion.PC = item;
      }
      if (version === item.version && item.modelType === 'OSF') {
        selectedVersion.OSF = item;
      }
    });
    //console.log(JSON.stringify(selectedVersion));
    if (selectedVersion.AE.precision > (parseFloat(preConfiguredValues.precision.AE.upperLimit)) ||
      selectedVersion.AE.precision < (parseFloat(preConfiguredValues.precision.AE.lowerLimit))) {
      showWarning = true;
    }
    if (selectedVersion.AE.accuracy > (parseFloat(preConfiguredValues.accuracy.AE.upperLimit)) ||
      selectedVersion.AE.accuracy < (parseFloat(preConfiguredValues.accuracy.AE.lowerLimit))) {
      showWarning = true;
    }
    if (selectedVersion.AE.sensitivity > (parseFloat(preConfiguredValues.sensitivity.AE.upperLimit)) ||
      selectedVersion.AE.sensitivity < (parseFloat(preConfiguredValues.sensitivity.AE.lowerLimit))) {
      showWarning = true;
    }
    if (selectedVersion.AE.auc > (parseFloat(preConfiguredValues.auc.AE.upperLimit)) ||
      selectedVersion.AE.auc < (parseFloat(preConfiguredValues.auc.AE.lowerLimit))) {
      showWarning = true;
    }

    // PC
    if (selectedVersion.PC.precision > (parseFloat(preConfiguredValues.precision.PC.upperLimit)) ||
      selectedVersion.PC.precision < (parseFloat(preConfiguredValues.precision.PC.lowerLimit))) {
      showWarning = true;
    }
    if (selectedVersion.PC.accuracy > (parseFloat(preConfiguredValues.accuracy.PC.upperLimit)) ||
      selectedVersion.PC.accuracy < (parseFloat(preConfiguredValues.accuracy.PC.lowerLimit))) {
      showWarning = true;
    }
    if (selectedVersion.PC.sensitivity > (parseFloat(preConfiguredValues.sensitivity.PC.upperLimit)) ||
      selectedVersion.PC.sensitivity < (parseFloat(preConfiguredValues.sensitivity.PC.lowerLimit))) {
      showWarning = true;
    }
    if (selectedVersion.PC.auc > (parseFloat(preConfiguredValues.auc.PC.upperLimit)) ||
      selectedVersion.PC.auc < (parseFloat(preConfiguredValues.auc.PC.lowerLimit))) {
      showWarning = true;
    }

    // OSF
    if (selectedVersion.OSF.precision > (parseFloat(preConfiguredValues.precision.OSF.upperLimit)) ||
      selectedVersion.OSF.precision < (parseFloat(preConfiguredValues.precision.OSF.lowerLimit))) {
      showWarning = true;
    }
    if (selectedVersion.OSF.accuracy > (parseFloat(preConfiguredValues.accuracy.OSF.upperLimit)) ||
      selectedVersion.OSF.accuracy < (parseFloat(preConfiguredValues.accuracy.OSF.lowerLimit))) {
      showWarning = true;
    }
    if (selectedVersion.OSF.sensitivity > (parseFloat(preConfiguredValues.sensitivity.OSF.upperLimit)) ||
      selectedVersion.OSF.sensitivity < (parseFloat(preConfiguredValues.sensitivity.OSF.lowerLimit))) {
      showWarning = true;
    }
    if (selectedVersion.OSF.auc > (parseFloat(preConfiguredValues.auc.OSF.upperLimit)) ||
      selectedVersion.OSF.auc < (parseFloat(preConfiguredValues.auc.OSF.lowerLimit))) {
      showWarning = true;
    }
    //console.log(JSON.stringify(showWarning));
    return showWarning;
  }
  render() {
    // const { modelType } = this.state
    // const modelTypes = CommonService.modelTypes.map((model, i) => {
    //   return (
    //     <option key={i} value={model.value}>{model.value}</option>
    //   )
    // });
    let modelTypeOptions = [];
    CommonService.modelTypes.sort().map((model, i) => {
      return modelTypeOptions.push({ id: model, label: model.value, value: model.value });
      });
    const modelTrainedVersions = this.props.versions.map((version, i) => {
      return (
        <option key={i} value={version}>{version}</option>
      )
    })

    // const dataModelStatus = CommonService.dataModelStatus.map((status, i) => {
    //   return (
    //     <option key={i} value={status.value}>{status.value}</option>
    //   )
    // })
    return (
      <div>
        <Modal dialogClassName="deletionModal selectModelVersion" show={this.state.showVersionSelection} onHide={this.handleConfirmClose} aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>
            Please select the training to deploy
          <Form.Control className="model-select-dropdown" as='select' name='trainingVersion' value={this.state.trainingVersion} onChange={this.handleOnChange}>
              <option value=''>--Select--</option>
              {modelTrainedVersions}
            </Form.Control>
          </Modal.Body>
          <Modal.Footer className='confirmation-footer-deploy'>
            <Button active onClick={this.startModelDeployment.bind(this)} >
              Deploy
        </Button>
            <Button onClick={this.handleConfirmClose} className='clear'>
              Cancel
        </Button>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName="deletionModal" show={this.state.startTrainingConfirmation} onHide={this.handleConfirmClose} aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>Do you want to start model training?</Modal.Body>
          <Modal.Footer className='confirmation-footer'>
            <Button onClick={this.handleConfirmClose} className='clear'>
              No
            </Button>
            <Button active onClick={this.triggerStartTraining.bind(this)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName="deletionModal" show={this.state.deployModelConfirmation} onHide={this.handleConfirmClose} aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'>
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className='confirmation-body'>{this.state.deployModelConfirmationMsg}</Modal.Body>
          <Modal.Footer className='confirmation-footer'>
            <Button onClick={this.handleConfirmClose} className='clear'>
              No
            </Button>
            <Button active onClick={this.triggerModelDeployment.bind(this)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName="deletionModal" show={this.state.trainingStartedSuccessDiaog} aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'></Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Image src="/Images/ico-success.png" className='success-img'></Image>
            <div className='success-styles'>Success!</div>
            Trainings have been started for AE, PC and OSF models. You will be notified once the training is completed.
        </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.closeDialogBox.bind(this)} className='user-deletion-cancel'>Ok</Button>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName="deletionModal" show={this.state.deploymentStartedSuccessDiaog} aria-labelledby="example-custom-modal-styling-title">
          <Modal.Header className='confirmation-header'></Modal.Header>
          <Modal.Body className='confirmation-body'>
            <Image src="/Images/ico-success.png" className='success-img'></Image>
            <div className='success-styles'>Success!</div>
            Production deployment has been initiated you will be notified once the deployment is completed.
        </Modal.Body>
          <Modal.Footer className='alert-confirmation-footer'>
            <Button active onClick={this.closeDialogBox.bind(this)} className='user-deletion-cancel'>Ok</Button>
          </Modal.Footer>
        </Modal>
        <Accordion defaultActiveKey="0" className='cardPadding'>
          <Card >
            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
              <span className="search-title">SEARCH DATA MODEL REPORTS</span><Image src="/Images/ico-drop-up.png" className={this.state.iconName} aria-hidden="true"></Image>

            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className='cardBodyStyle'>
                <Form onSubmit={this.handleSearch} className="searchForm">
                  <Form.Row>
                    <Col md={3}>
                      <Form.Label>Model Type</Form.Label>
                      {/* <Form.Control as='select' name='modelType' value={modelType} onChange={this.handleOnChange}>
                        <option value=''>--Select--</option>
                        {modelTypes}
                      </Form.Control> */}
                       <MultiSelect
                      options={modelTypeOptions}
                      selected={this.state.modelType}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selected => {
                        //console.log("selected values", selected);
                        this.setState({ modelType: selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    </Col>
                    {/*  <Col>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as='select' name='Status' value={Status} onChange={this.handleOnChange}>
                     <option value=''>--Select All--</option>
                      {dataModelStatus}
                    </Form.Control>
                  </Col> */}

                    <Button variant="secondary" type="submit" className='scorecardSearch button-color userSearch'>Search</Button>
                    <Button variant="secondary" className='clear userSearch' onClick={this.handleOnClear}>Clear</Button>
                    <Col></Col>
                    {
                      utils.getSessionItem('role') === RoleConstant.DataScientist &&

                      <span className='mt-4'>
                        <Button className='button-color download-font-size mr-3 pl-4 pr-4' onClick={this.startTraining.bind(this)}>Start Training</Button>
                        <Button className='button-color download-font-size pl-4 pr-4' onClick={this.showVersionSelection.bind(this)} >Deploy Model</Button>
                      </span>

                    }
                  </Form.Row>
                </Form></Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    )
  }
}
DataModelSearch.propTypes = {
  versions: PropTypes.array,
  data: PropTypes.array,
  self: PropTypes.object,
  getModelReports: PropTypes.func,
  acceptanceDetails: PropTypes.object,
}

export default DataModelSearch;