import React, { Component } from 'react'
import {ListGroup,Image} from 'react-bootstrap';
import './Sidebar.css';
import RouterConstant from './../../constants/RouterConstant';
import utils from './../../utils';
import { withRouter } from "react-router-dom";
import RoleConstant from "./../../constants/RoleConstant";
import PropTypes from 'prop-types';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.activeHome=this.activeHome.bind(this);
        
      }
      activeHome(){
        if((this.props.link && this.props.link.errorLink)==='active'||(this.props.link && this.props.link.scorecardLink)==='active'){
          window.location.reload(false)
        }
      }
    
    render() {
      const path = this.props.location.pathname.slice(1);
        return (
            <div className="siderbar-menu">
            <ListGroup >
              {(utils.getSessionItem("role") === RoleConstant.Admin ||
                utils.getSessionItem("role") === RoleConstant.CallCenterAdmin ||
                utils.getSessionItem("role") === RoleConstant.QualityMonitor ||
                utils.getSessionItem("role") === RoleConstant.TranslationVendor
                ) &&
              <ListGroup.Item action href={utils.getURL(RouterConstant.HOME_LINK)} onClick={this.activeHome} className={
                path===RouterConstant.HOME_LINK.slice(1)&&this.props.link.errorLink!=='active'&&this.props.link.scorecardLink!=='active'?'active':''}>
                {/* <i className="menu-icon fas fa-home"></i> */}
               
                <Image src="/Images/ico-home.png" className="sidebar-icon"></Image> 
                <span className='triangle-left'></span>
                <label>Home</label>
              </ListGroup.Item>}
              {(utils.getSessionItem("role") === RoleConstant.Admin) &&
              <ListGroup.Item className={path===RouterConstant.FILEUPLOAD_LINK.slice(1)?'active':''} action href={utils.getURL(RouterConstant.FILEUPLOAD_LINK)}>
                {/* <i className="menu-icon fas fa-cloud-download-alt"></i> */}
                <Image src="/Images/ico-data-load_sidebar.png" className="sidebar-icon"></Image>
                <span className='triangle-left'></span>
                <label>Data Load</label>
              </ListGroup.Item>}
              {(utils.getSessionItem("role") === RoleConstant.Admin) &&
              <ListGroup.Item className={path===RouterConstant.USERS_LINK.slice(1)?'active':''} action href={utils.getURL(RouterConstant.USERS_LINK)}>
                {/* <i className="menu-icon fas fa-users"></i> */}
                <Image src="/Images/ico-users.png" className="sidebar-icon"></Image>
                <span className='triangle-left'></span>
                <label>Users</label>
              </ListGroup.Item>}
              {(utils.getSessionItem("role") === RoleConstant.Admin || 
                utils.getSessionItem("role") === RoleConstant.CallCenterAdmin ||
                utils.getSessionItem("role") === RoleConstant.Reviewer) &&
              <ListGroup.Item className={path===RouterConstant.GIMS_REPORTS_LINK.slice(1)?'active':''}  action href={utils.getURL(RouterConstant.GIMS_REPORTS_LINK)}>
                {/* <i className="menu-icon fas fa-chart-pie"></i> */}
                <Image src="/Images/ico-GIMS-reports.png" className="sidebar-icon"></Image>
                <span className='triangle-left'></span>
                <label>GIMS Reports</label>
              </ListGroup.Item>}
              {(utils.getSessionItem("role") === RoleConstant.Admin || 
                utils.getSessionItem("role") === RoleConstant.DataScientist) &&
              <ListGroup.Item className={path===RouterConstant.DATA_MODEL_REPORTS_LINK.slice(1)?'active':''} action href={utils.getURL(RouterConstant.DATA_MODEL_REPORTS_LINK)}>
                {/* <i className="menu-icon far fa-chart-bar"></i> */}
                <Image src="/Images/ico-data-model-report.png" className="sidebar-icon"></Image>
                <span className='triangle-left'></span>
                <label>Data Model Reports</label>
              </ListGroup.Item>}
              {(utils.getSessionItem("role") === RoleConstant.Admin ||
                utils.getSessionItem("role") === RoleConstant.Reviewer || 
                utils.getSessionItem("role") === RoleConstant.QualityMonitor || 
                utils.getSessionItem("role") === RoleConstant.CallCenterAdmin) &&
              <ListGroup.Item className={path===RouterConstant.ERROR_LIST_LINK.slice(1)||(this.props.link && this.props.link.errorLink)==='active'?'active':''} action href={utils.getURL(RouterConstant.ERROR_LIST_LINK)}>
                {/* <i className="menu-icon fas fa-exclamation-triangle"></i> */}
                <Image src="/Images/ico-error-list.png" className="sidebar-icon"></Image>
                <span className='triangle-left'></span>
                <label>Error List</label>
              </ListGroup.Item>}
              {(utils.getSessionItem("role") === RoleConstant.Admin || 
                utils.getSessionItem("role") === RoleConstant.QualityMonitor) &&
              <ListGroup.Item className={path===RouterConstant.SCORE_CARD_LINK.slice(1)||(this.props.link && this.props.link.scorecardLink)==='active'?'active':''} action href={utils.getURL(RouterConstant.SCORE_CARD_LINK)}>
                {/* <i className="menu-icon fas fa-clipboard-check"></i> */}
                <Image src="/Images/ico-score-card.png" className="sidebar-icon"></Image>
                <span className='triangle-left'></span>
                <label>Score Card</label>
              </ListGroup.Item>}
          </ListGroup>
          </div>
        );
    }
}
Sidebar.propTypes = {
  location:PropTypes.object,
  link:PropTypes.object
}

export default withRouter(Sidebar);