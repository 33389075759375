var config = {};
config.env = 'prod';

//AWS
config.aws = {};
config.aws.apiRoot = "https://api-gims.amgen.com/";

//SSO
config.sso = {};
config.sso.url = "https://dev-630954.okta.com/home/selfdev630954_demookta_2/0oa1ihwzcwpC2mKfu357/aln1iiaocr7jdzWe1357";

//api-key
config.api = {};
config.api.key = 'qftL7DxT7c9DLIoKCNBDe90MvRroYIZU7MSdGNBH';

export default config;
