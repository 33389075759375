import React, { Component } from 'react';
import './ErrorList.css';
import { Accordion, Card, Button, Form, Col, Image } from 'react-bootstrap'
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
// import CommonService from '../../commonService/CommonService';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import MultiSelect from "@khanacademy/react-multi-select";
import PropTypes from 'prop-types';
/**
* A class that can return some html
*@return {Object} .
*@param {object} props
*/
class ErrorListSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: {
        errorId: this.props.searchData && this.props.searchData.error.errorId ? this.props.searchData.error.errorId : '',
        subcaseNumber: this.props.searchData && this.props.searchData.error.subcaseNumber ? this.props.searchData.error.subcaseNumber : '',
        errorType: this.props.searchData && this.props.searchData.error.errorType ? this.props.searchData.error.errorType : '',
        country: this.props.searchData && this.props.searchData.error.country ? this.props.searchData.error.country : '',
        modifiedAt: this.props.searchData && this.props.searchData.error.modifiedAt ? this.props.searchData.error.modifiedAt : '',
        associatedTeam: this.props.searchData && this.props.searchData.error.associatedTeam ? this.props.searchData.error.associatedTeam : '',
        status: this.props.searchData && this.props.searchData.error.status ? this.props.searchData.error.status : '',
      },
      selected: this.props.searchData && this.props.searchData.selected ? this.props.searchData.selected :[],
       dataSet:  [],    
      errorDetails: { errorDetails: '' },
      iconName: 'icon-drop-up'
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.iconChange = this.iconChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  getDataSet() {
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
      //console.log("get dataset", result);
      self.setState({
        dataSet: result.datasets
      });
      self.props.datasetIds(result.datasets);
    });
  }

  componentDidMount() {
    this.getDataSet();
  }
  handleSubmit(e) {
    e.preventDefault();
    //console.log(this.state.error,EndpointConstant.FILTER_ERRORS)
    let self = this
    let data = {}
    if (this.state.error.errorId !== '') {
      data.errorId = this.state.error.errorId
    }
    if (this.state.error.subcaseNumber !== '') {
      data.subcaseNumber = this.state.error.subcaseNumber
    }
    if (this.state.selected.length !== 0) {
      data.datasetId = this.state.selected;
    }
    if (this.state.error.errorType.length !== 0) {
      data.errorType = this.state.error.errorType
    }
    if (this.state.error.country.length !== 0) {
      data.country = this.state.error.country
    }
    if (this.state.error.modifiedAt !== '' && this.state.error.modifiedAt !== null) {
      let date = this.state.error.modifiedAt
      let month = date.getMonth() + 1
      //console.log(this.state.error.modifiedAt.getMonth()+1,this.state.error.modifiedAt.getFullYear(),this.state.error.modifiedAt.getDate())
      data.modifiedAt = date.getFullYear() + '-' + month + '-' + date.getDate()
    }
    // if(this.state.error.modifiedAt!==''){
    //   data.modifiedAt=this.state.error.modifiedAt
    // }
    if (this.state.error.associatedTeam.length !== 0) {
      data.associatedTeam = this.state.error.associatedTeam
    }
    if (this.state.error.status.length !== 0) {
      data.status = this.state.error.status
    }
    utils.makeRequest(this, EndpointConstant.FILTER_ERRORS, data, function (result) {
      const errors = result.errors;
      // self.loading = false;
      self.setState({
        errorDetails: { errorDetails: errors }
      }, () => { self.props.getSearchResult(errors);
        self.props.setSearchResult(self.state)
        self.props.getSearchClearData(errors) });

    })
  }

  iconChange() {
    if (this.state.iconName === 'icon-drop-up') {
      this.setState({ iconName: 'icon-drop-down' });
    } else {
      this.setState({ iconName: 'icon-drop-up' });
    }
  }

  clearFilters(e) {
    let event = e
    this.setState({
      error: {
        errorId: '', subcaseNumber: '', errorType: '',
        country: '', modifiedAt: null, associatedTeam: '', status: '',
      },
      selected: [],
     datasetName: []
    }, () => { this.handleSubmit(event) });
  }
  compareDataset(a, b) {
    const valueA = a.label.toUpperCase();
    const valueB = b.label.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  } 

  render() {

    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    // const errorTypeOptions = Object.keys(siteinfo.ErrorTypes).sort().map((errorType, i) => {
    //   return (
    //     <option key={i} value={errorType}>{siteinfo.ErrorTypes[errorType]}</option>
    //   )
    // });
    //const errorTypeOptions = [];
    const errorTypeOptions = Object.keys(siteinfo.ErrorTypes).sort().map((errorType, i) => {
      return ({ id: errorType, label: siteinfo.ErrorTypes[errorType], value: errorType });
     
    });
    const countryOptions = [];
    // const countryOptions = siteinfo.Countries.sort().map((country, i) => {
    //   return (
    //     <option key={i} value={country}>{country}</option>
    //   )
    // });
    siteinfo.Countries.sort().map((country, i) => {
      return countryOptions.push({ id: country, label: country, value: country });
      });
    
    // const AssignedToOptions = Object.keys(siteinfo.CallCenterTeam).sort().map((role, i) => {
    //   return (
    //     <option key={i} value={role}>{siteinfo.CallCenterTeam[role]}</option>
    //   );
    // });
    //const AssignedToOptions = [];
    const AssignedToOptions = Object.keys(siteinfo.CallCenterTeam).sort().map((role, i) => {
      return ({ id: role, label: siteinfo.CallCenterTeam[role], value: role });
      });
    // const teamOptions = siteinfo.CallCenterAdminTeam.map(team => {
    //   return (
    //     <option key={team} value={team}>{team}</option>
    //   );
    // });
   
    // const statusOptions = Object.keys(siteinfo.ErrorStatus).sort().map((status, i) => {
    //   return (
    //     <option key={i} value={status}>{siteinfo.ErrorStatus[status]}</option>
    //   )
    // })
    // const statusOptions = [];
    const statusOptions = Object.keys(siteinfo.ErrorStatus).sort().map((status, i) => {
      return ({ id: status, label: siteinfo.ErrorStatus[status], value: status });
      })

    const { selected, dataSet } = this.state;
    const options = [];
    if (dataSet) {
        dataSet.forEach((dataset) => {
        //console.log("dataset", dataset)
        if(dataset.status==='Complete'){
        options.push({ id: dataset.id, label: dataset.datasetName && dataset.datasetName.split('.')[0], value: dataset.id });
      }
      });      
    }
    options.sort(this.compareDataset);
    return (
      <Accordion defaultActiveKey="0" className='cardPadding'>
        <Card >
          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
            <span className="search-title">SEARCH ERROR LIST</span> <Image src="/Images/ico-drop-up.png" className={this.state.iconName} aria-hidden="true"></Image>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='cardBodyStyle'>
              <Form onSubmit={this.handleSubmit} className="errorSearchForm">
                <Form.Row className='mb-2'>
                  {/* <Form.Group as={Col} controlId="errorId" md="2"> */}
                  <Col>
                    <Form.Label>Error ID</Form.Label>
                    <Form.Control type='text' name='errorId' value={this.state.error.errorId} onChange={e => { this.setState({ error: { ...this.state.error, errorId: e.target.value } }) }} />
                    {/* </Form.Group> */}
                  </Col>

                  {/* <Form.Group as={Col} controlId="interactionId" md="2"> */}
                  <Col>
                    <Form.Label>Sub-Case Number</Form.Label>
                    <Form.Control type='text' name='subcaseNumber' value={this.state.error.subcaseNumber} onChange={e => { this.setState({ error: { ...this.state.error, subcaseNumber: e.target.value } }) }} />
                    {/* </Form.Group> */}
                  </Col>

                  {/* <Form.Group as={Col} controlId="errorType" md="2"> */}
                  <Col>
                    <Form.Label>Dataset</Form.Label>
                    <MultiSelect
                      options={options}
                      selected={selected}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={dataSelected => {
                        //console.log("selected values", selected);
                        this.setState({ selected:dataSelected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>
                  <Col>
                    <Form.Label>Error Type</Form.Label>
                    {/* <Form.Control as='select' name='errorType' value={this.state.error.errorType} onChange={e => { this.setState({ error: { ...this.state.error, errorType: e.target.value } }) }} >
                      <option value="">--Select--</option>
                      {errorTypeOptions}
                    </Form.Control> */}
                       <MultiSelect
                      options={errorTypeOptions}
                      name='errorType'
                      selected={this.state.error.errorType}
                      disableSearch={true}
                      hasSelectAll={true}
                      onSelectedChanged={selectedError => {
                        //console.log("selected values", selected);
                        this.setState({ error: { ...this.state.error, errorType: selectedError }  });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group>    */}
                  </Col>

                  {/* <Form.Group as={Col} md="2" controlId="associatedTeam"> */}
                  <Col>
                    <Form.Label>Associated Team</Form.Label>
                    {/* <Form.Control as='select' name='associatedTeam' value={this.state.error.associatedTeam} onChange={e => { this.setState({ error: { ...this.state.error, associatedTeam: e.target.value } }) }} >
                      <option value="">--Select--</option>
                      {AssignedToOptions}
                    </Form.Control> */}
                       <MultiSelect
                      options={AssignedToOptions}
                      selected={this.state.error.associatedTeam}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selectedTeam => {
                        //console.log("selected values", selected);
                        this.setState({ error: { ...this.state.error, associatedTeam: selectedTeam }  });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>

                  {/* <Form.Group as={Col} controlId="country" md="2"> */}
                  <Col>
                    <Form.Label>Country</Form.Label>
                    {/* <Form.Control as='select' name='country' value={this.state.error.country} onChange={e => { this.setState({ error: { ...this.state.error, country: e.target.value } }) }} >
                      <option value=''>--Select--</option>
                      {countryOptions}
                    </Form.Control> */}
                       <MultiSelect
                      options={countryOptions}
                      selected={this.state.error.country}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selectedCountry => {
                        //console.log("selected values", selected);
                        this.setState({ error: { ...this.state.error, country: selectedCountry }  });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>

                  {/* <Form.Group as={Col} controlId="c" md="2"> */}
                  <Col>
                    <Form.Label>Modified On</Form.Label>
                    {/* <Form.Control> */}
                    <Calendar className='calender-styles' value={this.state.error.modifiedAt} onChange={(e) => this.setState({ error: { ...this.state.error, modifiedAt: e.value } })} showIcon={true} placeholder='mm/dd/yyyy' />
                    {/* </Form.Control> */}
                    {/* </Form.Group> */}
                  </Col>

                  {/* <Form.Group as={Col} controlId="status" md="2"> */}
                  <Col>
                    <Form.Label>Error Status</Form.Label>
                    {/* <Form.Control as='select' name='status' value={this.state.error.status} onChange={e => { this.setState({ error: { ...this.state.error, status: e.target.value } }) }} >
                      <option value="">--Select--</option>
                      {statusOptions}
                    </Form.Control> */}
                    <MultiSelect
                      options={statusOptions}
                      selected={this.state.error.status}
                      disableSearch={true}
                      hasSelectAll={true}
                      onSelectedChanged={selectedStatus => {
                        //console.log("selected values", selected);
                        this.setState({ error: { ...this.state.error, status: selectedStatus }  });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group>    */}
                  </Col>
                </Form.Row>

                <Button variant="secondary" type="submit" className='errorSearch button-color'>Search</Button>
                <Button variant="secondary" className='clear' onClick={this.clearFilters}>Clear</Button>

              </Form></Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
  }
}
ErrorListSearch.propTypes = {
  getErrorList: PropTypes.func,
  searchData: PropTypes.object,
  setSearchResult : PropTypes.func,
}

export default ErrorListSearch;

