import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Growl } from 'primereact/growl';
import '../../FileUploader/FileUploader.css';
import PropTypes from 'prop-types';

class ReportUploader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showUploadImage: false,
      highlightDragAndDrop: false,
      enableUpload: true
    };
    this.props = props;
    this.getExtension = this.getExtension.bind(this);
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  onDropFile(files) {
    //console.log("files", files);

    // let titleIcon = files[0].name;
    let id = this.props.id ? this.props.id : null;
    let fileExtension = this.getExtension(files[0].name);
    let fileType = null;

    //File size validation if provided in props -- in MB      
    if(this.props.maxFileSize){
      let maxFileSizeAllowed = parseInt(this.props.maxFileSize)
      let fileSize = files[0].size / 1024 / 1024
      if(fileSize > maxFileSizeAllowed){
        this.growl.show({ severity: 'error', summary: 'Error', detail: `Please select file having size less than or equals to ${maxFileSizeAllowed} MB`, life: 5000 });        
        return;
      }
    }      
    //Validation End 

    if (!id) {
      if (fileExtension === 'docx' || fileExtension === 'pdf' || fileExtension === 'pptx' || fileExtension === 'ppt')
        fileType = files[0].type;
      else {
        //alert('Please select a valid Excel file.');
        this.growl.show({severity:'error', summary: 'Error', detail:`Please select a valid PDF/ PPT/ Word file.`, life: 5000});
        return;
      }
    }
    else {
      fileType = files[0].type;
    }
    if (fileExtension === 'xlsx') {
      fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (fileExtension === 'csv') {
      fileType = 'text/csv';
    } else if (fileExtension === 'xls') {
      fileType = 'application/vnd.ms-excel';
    }
    this.props.showReportNameInput(id, files, fileType);
  }

  onDragEnter() {
    this.setState({
      highlightDragAndDrop: true
    });
  }

  onDragLeave() {
    this.setState({
      highlightDragAndDrop: false
    });
  }  

  render() {
    return (
      <React.Fragment>
        <Growl ref={el => (this.growl = el)} />
        <Dropzone
          className="dropzone"
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          onDrop={this.onDropFile.bind(this)}
          multiple={false}
        >
          <div className="drag-text">
            <img src="/Images/ico-cloud-dragNdrop.png" className="file-drop-icon" alt='Amgen' />
            <p className="select-file-title">Drop files here or click to <u>select files to upload</u></p>
          </div>
        </Dropzone>
      </React.Fragment>
    );
  }
}
ReportUploader.propTypes = {
  id: PropTypes.string,
  showReportNameInput: PropTypes.func
}
export default ReportUploader;