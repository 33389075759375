import React, { Component } from 'react';
// import logoImg from './../../assets/images/Logo-GIMS.png';
import './LandingScreen.css';
import utils from './../../utils';
import RouterConstant from '../../constants/RouterConstant';
import EndpointConstant from '../../constants/EndpointConstant';

class LandingScreen extends Component {

	componentDidMount() {
		window.location.href = 'callback.html';
		// this.getSiteInfo();
	}

	Login() {
    utils.gotoPage(RouterConstant.LOGIN_LINK);
    // this.props.history.push(RouterConstant.LOGIN_LINK);
	}

	Register() {
		utils.gotoPage(RouterConstant.REGISTRATION_LINK);
	}

	getSiteInfo() {
	const idToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
	//console.log("Landinscreen access token",idToken);
	let token = null;
    if(idToken != null){
      token = idToken.accessToken.accessToken
    }
	utils.makeRequest(this, EndpointConstant.GET_SITE_INFO, {token:token}, function(result) {
	//console.log("result : " + JSON.stringify(result) );
	utils.setSessionItem("SiteInfo", JSON.stringify(result));
	});
	}


	render(){
		return (<div></div>);
		// return (
		// 	<div className="landing-container">				
		// 		<div className="landing-header">
		// 			<div className="row">
		// 				{/* <div className="logo"></div> */}
		// 				<div className="ls-logo-wrapper mx-auto">
		// 					<img src={logoImg} alt="Amgen" />
		// 				</div>
		// 			</div>
		// 		</div>
		// 		<div className="row">
		// 			<div className="col"><hr className="no-margin" /></div>
		// 		</div>
		// 		<div className="landing-wrapper">
		// 			<div className="row">
		// 				<button className="col-md-12 btn btn-primary mb-2 sign-in" onClick={() => this.Login()}>Sign in with OKTA</button>
		// 			</div>
		// 			<div className="row">
		// 				<div className="col"><hr/></div><div className="col-auto">OR</div><div className="col"><hr/></div>
		// 			</div>
		// 			<div className="row">
		// 				<button className="col-md-12 btn btn-primary mb-2 register" onClick={() => this.Register()}>Register</button>
		// 			</div>	
		// 		</div>
		// 	</div>
		// );
	}
}

export default LandingScreen;