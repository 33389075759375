/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './Registration.css';
import logoImg from './../../assets/images/Logo-GIMS.png';
import { Col, Form, Button, Card } from 'react-bootstrap';
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import Search from '../Search/Search';
import MessageConstant from './../../constants/MessageConstant';
import CommonService from '../../commonService/CommonService';
import Multiselect from "@khanacademy/react-multi-select";
import ConfirmationModal from './../PopupModels/ConfirmationModal'

/* eslint "require-jsdoc": ["error", {
    "require": {
        "FunctionDeclaration": true,
        "MethodDefinition": true,
        "ClassDeclaration": true,
        "ArrowFunctionExpression": true,
        "FunctionExpression": true
    }
}]*/

/**
 * A class that can return some html
 */
class Registration extends Component {
  // state={show:false}
  /**
 * @constructor
 * @param {void} props  */
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: utils.hasSessionItem('registerE') ? utils.getSessionItem('registerE') : '',
      role: '',
      justification: '',
      team: [],
      userId: '',
      sessionToken: '',
      regShow: false,
      userRegSuccess: false,
      userRegError: false,
      showForm: true,
      formValid: false,
      errorCount: null,
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        team: '',
        justification: ''
      },
      showList: false,
      searchList: {},
      isFormShow: false,
      errorCode: '',
      updateConfirmation: false,
      countQm: '',
      countTV: '',
      countCCA: '',
      deleteName: ''
    };
    this.isFormValid = false;
    this.changeHandler = this.changeHandler.bind(this);
    this.register = this.register.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleRegClose = this.handleRegClose.bind(this);
    /* eslint-disable-next-line */
    this.validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    this.validateForm = this.validateForm.bind(this);
    this.countErrors = this.countErrors.bind(this);
    this.errMsg = this.errMsg.bind(this);
    this.validateRegex = this.validateRegex.bind(this);
    this.handleShowList = this.handleShowList.bind(this);
    this.selectUsersFromSearch = this.selectUsersFromSearch.bind(this);
    this.handleSelectedChanged = this.handleSelectedChanged.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.handleUpdateConfirmation = this.handleUpdateConfirmation.bind(this);
    this.handleUpdateClose = this.handleUpdateClose.bind(this);
  }


  /**
  * Validate Forms
  */
  validateForm(errors) {
    //console.log(errors);
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }
  /**
  * Validate Regex
  */
  validateRegex(event) {
    let name = event.target.name;
    if (name === 'firstName' || name === 'lastName') {
      event.target.value = event.target.value.replace(/[^\w\s\][]/gi, '')
    }
    return event;
  }
  /**
  * Count Error
  */
  countErrors(errors) {
    let count = 0;
    Object.values(errors).forEach((val) => {
      if (val.length > 0) {
        count += 1;
      }
    });
    return count;
  }
  /**
  * Error Message On Submit
  */
  errMsg(state) {
    const { firstName, lastName, email, role, team, justification, errors } = state;
    if (firstName === '') {
      errors.firstName = MessageConstant.REG_FIRSTNAME_VALIDATION;
    }
    if (lastName === '') {
      errors.lastName = MessageConstant.REG_LASTNAME_VALIDATION;
    }
    if (email === '') {
      errors.email = MessageConstant.REG_EMAIL_VALIDATION;
    }
    if (role === '') {
      errors.role = MessageConstant.REG_ROLE_VALIDATION;
    }
    if (team === '' && role === 'CallCenterAdmin') {
      errors.team = MessageConstant.REG_TEAM_VALIDATION;
    }
    if (justification === '') {
      errors.justification = MessageConstant.REG_JUSTIFICATION_VALIDATION;
    }
    return errors;
  }
  /**
    * @param {Object} e .
    */
  handleShow() {
    this.props.closePopup();
    this.setState({
      regShow: true
    });
  }
  /**
    * @param {Object} e .
    */
  handleClose() {
    this.setState({
      regShow: false
    });
  }

  /**
    * @param {Object} e The new state.
    */
  changeHandler(event) {
    //this.setState({ [e.target.name]: e.target.value });
    event.preventDefault();
    //let validate = this.validateRegex(event);
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case 'firstName':
        errors.firstName = value === '' ? MessageConstant.REG_FIRSTNAME_VALIDATION : '';
        break;
      case 'lastName':
        errors.lastName = value === '' ? MessageConstant.REG_LASTNAME_VALIDATION : '';
        break;
      case 'email':
        errors.email = this.validEmailRegex.test(value) ? '' : MessageConstant.REG_EMAIL_NOTVALID;
        break;
      case 'role':
        errors.role = value === '' ? MessageConstant.REG_ROLE_VALIDATION : '';
        break;
      case 'team':
        errors.team = value === '' ? MessageConstant.REG_TEAM_VALIDATION : '';
        break;
      case 'justification':
        errors.justification = value === '' ? MessageConstant.REG_JUSTIFICATION_VALIDATION : '';
        break;
      default:
        break;
    }

    // console(errors);console(name);console(value);

    this.setState({ errors, [name]: value });
  }
  /**
    * @param {Object} e The new state.
    */
  handleRegClose() {
    this.props.closePopup();
  }
  /**
    * @param {Object} e .
    */
  register(e) {

    e.preventDefault();
    let errorsMsg = this.errMsg(this.state);
    this.setState({ errors: errorsMsg });
    this.setState({ formValid: this.validateForm(this.state.errors) });
    this.setState({ errorCount: this.countErrors(this.state.errors) });
    let self = this;
    self.refs.btn.setAttribute("disabled", "disabled");
    setTimeout(() => {
      const { formValid } = this.state;
      if (formValid) {
        //  let self = this;
        if (this.props.adminRegister) {
          let adminDetails = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            role: this.state.role,
            justification: this.state.justification,
            userId: this.state.userId,
            sessionToken: this.state.sessionToken
          }
          if (adminDetails.role === 'CallCenterAdmin') {
            adminDetails.team = this.state.team;
          }
          utils.makeRequest(this.props.self, EndpointConstant.ADD_USER, adminDetails, function (result) {
            self.props.getUser();
            // window.alert('added user')
            // utils.gotoPage(RouterConstant.ADMIN_REG_SUCCESS);
            // utils.gotoPage(RouterConstant.USERS_LINK)   
            if (result.status === 'success') {
              //self.setState({regShow:true});
              let success = MessageConstant.ADMIN_REGISTRATION_MESSAGE(self.state.firstName);
              self.props.closePopup(success, 'Registration', true);
              //alert("User Added successfully");
            }
            else {
              let error = MessageConstant.USER_ALREADY_EXISTS(self.state.firstName);
              self.props.closePopup(error, 'Registration', true);
              //alert("User already exist");
            }
          });

        }
        else {
          const idToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
          //console.log("add user nonadmin",idToken);
          let token = null;
          if (idToken != null) {
            token = idToken.accessToken.accessToken
          }
          let nonadminDetails = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            role: this.state.role,
            justification: this.state.justification,
            token: token
          }

          if (nonadminDetails.role === 'CallCenterAdmin') {
            nonadminDetails.team = this.state.team;
          }
          utils.makeRequest(this, EndpointConstant.ADD_USER, nonadminDetails, function (result) {
            //console.log(result)
            if (result.error === false) {
              self.setState({
                userRegSuccess: true,
                showForm: false,
                userRegError: false
              });
            }
            else {
              self.setState({
                userRegSuccess: false,
                errorCode: result.errorCode
              }, () => self.setState({ userRegError: true }))
            }

          });

        }
      }
      else {
        //console.log("Invalid fields");
      }
    }, 500);
  }
  /**
   * @param {Object} e .
   */
  updateUser(event) {
    event.preventDefault();
    let self = this;
    //console.log("updateUser state", this.state);
    //console.log("updateUser current user", this.props.currentUser);
    let updateDetails = {
      role: this.state.role,
      justification: this.state.justification,
      id: this.props.currentUser.id
    }
    if (updateDetails.role === 'CallCenterAdmin') {
      updateDetails.team = this.state.team;
    }
    utils.makeRequest(this.props.self, EndpointConstant.USER_STATUS_UPDATE, updateDetails, function (result) {
      self.props.getUser();
      // window.alert('added user')
      // utils.gotoPage(RouterConstant.ADMIN_REG_SUCCESS);
      // utils.gotoPage(RouterConstant.USERS_LINK) 
      //console.log("resultsss", result);
      if (result.error !== true) {
        //self.setState({regShow:true});
        let success = MessageConstant.UPDATE_USER_SUCCESS;
        self.props.closePopup(success, 'Update User', true);
        //alert("User Added successfully");
      }
      else {
        let error = MessageConstant.UPDATE_USER_ERROR;
        self.props.closePopup(error, 'Update User', true);
        //alert("User already exist");
      }
    });
  }
  /**
   * @param {Object} e The new state.
   */
  handleShowList(e) {
    const { results, showList } = e;
    //console.log("handleShowList", e);
    this.setState({
      showList,
      searchList: results
    });
    //console.log("state", this.state);
  }
  /**
    * @param {Object} e The new state.
    */
  selectUsersFromSearch(data, event) {
    event.preventDefault();
    const { firstName, lastName, email } = data
    //console.log("selectUsersFromSearch", data);
    this.setState({
      firstName, lastName, email,
      showList: false,
      isFormShow: true
    })
  }
  /**
    * @param {Object} e The new state.
    */
  onKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\||1|2|3|4|5|6|7|8|9|0/.test(keyValue)) {
      event.preventDefault();
    }
  }
  /**
   * @param {Object} e The new state.
   */
  isValidForm() {
    //    this.isFormValid = true;
    const { firstName, lastName, email, role, team, justification } = this.state;
    if (firstName && lastName && email && role && justification) {
      if (role === 'CallCenterAdmin' && team.length === 0) {
        this.isFormValid = false;
      }
      else {
        this.isFormValid = true;
      }
    }
    else {
      this.isFormValid = false;
    }
  }
  /**
    * @param {Object} selected.
    */
  handleSelectedChanged(selected) {
    this.setState({ team: selected });
    // if(selected.length === 0) {
    let errors = this.state.errors;
    // let errors = this.state.errors;
    // errors.team = MessageConstant.REG_TEAM_VALIDATION;
    let errormsg = errors.team = selected.length === 0 ? MessageConstant.REG_TEAM_VALIDATION : ''
    this.setState({ errors: { team: errormsg } });
    // }
  }

  /**
    * @param {Object} e.
    */
  handleUpdateConfirmation(e) {
    e.preventDefault();
    let updateUser = this.props.allUsers;
    let countQm = 0;
    let countTV = 0;
    let countCCA = 1;
    let currentTeam = this.props.currentUser.team;
    let newTeam = this.state.team;
    //console.log("role",this.state.userId)
    let userRole = this.props.currentUser.role;
    let teamCCA = userRole === "CallCenterAdmin" && userRole === this.state.role ? currentTeam.filter((o) => newTeam.indexOf(o) === -1) : currentTeam;
    let id = this.props.currentUser.id;
    let name = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName;
    if (userRole === "CallCenterAdmin" || userRole === "QualityMonitor" || userRole === "TranslationVendor") {
      if ((userRole === "CallCenterAdmin" && teamCCA.length === 0 && userRole === this.state.role) || (userRole === "QualityMonitor" && userRole === this.state.role) || (userRole === "TranslationVendor" && userRole === this.state.role)) {
        this.setState({ updateConfirmation: true })
      }
      else {
        updateUser.forEach((user) => {
          if (userRole === "QualityMonitor") {
            if (user.role === "QualityMonitor")
              ++countQm;
          }
          else if (userRole === "TranslationVendor") {
            if (user.role === "TranslationVendor")
              ++countTV;
          }
          else if (userRole === "CallCenterAdmin") {
            if (user.role === "CallCenterAdmin") {
              if (id !== user.id) {
                teamCCA = teamCCA.filter((o) => user.team.indexOf(o) === -1);
              }
            }
          }
        });
        teamCCA.length === 0 ? ++countCCA : '';
        (countQm === 1 || countTV === 1 || countCCA === 1) ? this.setState({ updateConfirmation: true, deleteName: name, countQm: countQm, countTV: countTV, countCCA: countCCA, teamCCA: teamCCA }) : this.setState({ updateConfirmation: true, deleteName: name })
      }
    }
    else {
      this.setState({ updateConfirmation: true, deleteName: name })
    }
  }

  /**
    * @param {Object} e.
    */
  handleUpdateClose() {
    this.setState({ updateConfirmation: false })
  }



  /**
   *renders the ui
   *@return {Object} .
    */
  componentDidMount() {
    if (this.props.adminUpdate) {
      const { firstName, lastName, email, role, team, justification } = this.props.currentUser;
      this.setState({
        firstName, lastName, email, role, team, justification,
        showList: false,
        isFormShow: true
      })
    }
  }
  /**
   * 
   * @param {a} a 
   * @param {a} b
   * @return boolean 
   */
  compare(b, a) {
    if (a.value < b.value) {
      return 1;
    }
    if (a.value > b.value) {
      return -1;
    }
    return 0;
  }
  /**
   *renders the ui
   *@return {Object} .
    */
  render() {
    this.isValidForm();
    const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));

    //console.log("siteInfo  " + JSON.stringify(siteInfo.UserRoles));

    const rolesOtions = Object.keys(siteInfo.UserRoles).sort().map((role, i) => {
      return (
        <option key={i} value={role}>{siteInfo.UserRoles[role]}</option>
      );
    });

    rolesOtions.sort(CommonService.sortRole);

    const associatedTeamsArray = Object.entries(siteInfo.CallCenterTeam);
    const associatedTeams = [];
    associatedTeamsArray.forEach(function (team) {
      associatedTeams.push({
        label: team[1], value: team[0]
      })
    });
    associatedTeams.sort(this.compare);
    // const teamOptions = Object.keys(siteInfo.CallCenterTeam).map((team, i) => {
    //   return (
    //     <option key={i} value={team}>{siteInfo.CallCenterTeam[team]}</option>
    //   );
    // });

    const { errors, errorCode } = this.state;

    return (
      <div >
        {this.state.userRegSuccess ? <Card className="alert-box-error">
          <div className="card-header-title">
            <Card.Img variant="top" className="logo-size" src="/Images/Logo.svg" />
            <Card.Text><h4 className="logo-title">GLOBAL INTAKE MONITORING SYSTEM</h4></Card.Text>
          </div>
          <Card.Body className="msg-body">{MessageConstant.REG_MESSAGE_FOR_USER}</Card.Body>
        </Card> : ''}
        {this.state.userRegError ? <Card className="alert-box-user-exist">
          <Card.Body>
            <img className="error-already-exist" src="/Images/ico-error.png" alt='Amgen' />
            <span className="error-msg-exist col-md-11">{errorCode === 'U100' ? MessageConstant.USER_ALREADY_EXISTS_NON_ADMIN : MessageConstant.USER_DOESNT_EXISTS_NON_ADMIN}</span>
          </Card.Body>
        </Card> : ''}
        {!this.state.userRegSuccess &&
          <div className={this.props.adminRegister ? '' : 'content-cneter'}>

            {!this.props.adminRegister && this.state.showForm &&
              <div className="register-header no-padding">
                {/* <div class="logo"></div> */}
                <div className="reg-logo-wrapper mx-auto">
                  <img src={logoImg} alt="Amgen" />
                </div>
              </div>
            }
            <div className="row">
              <div className="col"><hr className="no-margin" /></div>
            </div>
            <div className="registration-form">
              {/* <h5 className={this.state.showForm?'mb-4 text-center':'fromDispaly mb-4 text-center'}>{this.props.adminRegister?'Add User':'Registration'}</h5> */}

              {(this.props.adminRegister && !this.props.adminUpdate) ? <Search showList={this.state.showList} handleShowList={this.handleShowList} /> : ''}
              {this.state.showList && <div>
                <div className="list-group search-list-group">
                  {this.state.searchList.users.map((data) => {
                    return <a key='' href="" onClick={(e) => this.selectUsersFromSearch(data, e)} className="list-group-item list-group-item-action search-list-item">{data.firstName + ' ' + data.lastName}</a>
                  })
                  }
                </div>
              </div>}
              <div className={this.state.showForm ? '' : 'fromDispaly'}>
                {((this.props.adminRegister && this.state.isFormShow) || (!this.props.adminRegister)) && <Form onSubmit={this.props.adminUpdate ? this.handleUpdateConfirmation : this.register} className='reg-form' noValidate>
                  <Form.Row>
                    <Form.Group as={Col} controlId="firstName">
                      <Form.Label className='align-lable'>First Name</Form.Label>
                      <Form.Control type='text' name='firstName' value={this.state.firstName} onKeyPress={this.onKeyPress.bind(this)}
                        onChange={this.changeHandler} disabled={this.props.adminRegister} />
                      {errors.firstName && errors.firstName.length > 0 &&
                        <div className="error"><span>{errors.firstName}</span></div>}
                    </Form.Group>

                    <Form.Group as={Col} controlId="lastName">
                      <Form.Label className='align-lable'>Last Name</Form.Label>
                      <Form.Control type='text' name='lastName' value={this.state.lastName} onKeyPress={this.onKeyPress.bind(this)}
                        onChange={this.changeHandler} disabled={this.props.adminRegister} />
                      {errors.lastName && errors.lastName.length > 0 &&
                        <div className="error"><span>{errors.lastName}</span></div>}
                    </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="email">
                    <Form.Label className='align-lable'>Email</Form.Label>
                    <Form.Control type='text' name='email' value={this.state.email}
                      onChange={this.changeHandler} disabled={this.props.adminRegister} />
                    {errors.email && errors.email.length > 0 &&
                      <div className="error"><span>{errors.email}</span></div>}
                  </Form.Group>

                  <Form.Group controlId="role">
                    <Form.Label className='align-lable'>Role</Form.Label>
                    <Form.Control as='select' name='role' value={this.state.role}
                      onChange={this.changeHandler}>
                      <option value="">--- Select Role ---</option>
                      {rolesOtions}
                    </Form.Control>
                    {errors.role && errors.role.length > 0 &&
                      <div className="error"><span>{errors.role}</span></div>}
                  </Form.Group>
                  {this.state.role === 'CallCenterAdmin' &&
                    <Form.Group controlId="team">
                      <Form.Label className='align-lable'>Team(s)</Form.Label>
                      {/* <Form.Control as='select' name='team' value={this.state.team}
                  onChange={this.changeHandler}>
                  <option value="">--- Select Team ---</option>
                  {teamOptions  }
                </Form.Control> */}
                      <div className="tea">
                        <Multiselect
                          options={associatedTeams}
                          onSelectedChanged={this.handleSelectedChanged}
                          selected={this.state.team}
                          disableSearch={false}
                          hasSelectAll={false}
                          overrideStrings={{
                            selectSomeItems: "-- Select Team --",
                          }}
                        />
                      </div>
                      {errors.team && errors.team.length > 0 &&
                        <div className="error"><span>{errors.team}</span></div>}
                    </Form.Group>
                  }
                  <Form.Group controlId="justification">
                    <Form.Label className='align-lable'>{this.props.adminUpdate ? 'Justification' : this.props.adminRegister ? 'Invitation' : 'Justification'}</Form.Label>
                    <Form.Control as="textarea" rows="3" name='justification' value={this.state.justification} maxLength={MessageConstant.REG_MAX_LENGTH_TEXTAREA}
                      onChange={this.changeHandler} />
                    {errors.justification && errors.justification.length > 0 &&
                      <div className="error"><span>{errors.justification}</span></div>}
                  </Form.Group>
                  <Form.Group controlId="btnaction" className="row padding-form">
                    <div className="col-md-6 padding-left">
                      {!this.props.adminRegister && <Button variant="secondary" className='margin mr-4 btn-block cancel' onClick={() => { this.props.adminRegister ? this.props.closePopup() : utils.gotoPage('/') }}>
                        Cancel
                      </Button>}
                    </div>
                    <div className="col-md-6 padding-right">
                      {
                        this.props.adminUpdate ?
                          (<Button variant="secondary" type="submit" className='textcolor btn-block register' disabled={!this.isFormValid}>
                            Update
                          </Button>)

                          : (<Button ref="btn" variant="secondary" type="submit" className='textcolor btn-block register' disabled={!this.isFormValid}>
                            {this.props.adminRegister ? 'Add' : 'Register'}
                          </Button>)
                      }
                    </div>
                  </Form.Group>
                </Form>}
              </div>

            </div>
          </div>
        }
        <ConfirmationModal showModal={this.state.updateConfirmation}
          handleCloseModal={this.handleUpdateClose}
          message={this.state.countQm === 1 ? MessageConstant.QM_USER_EDIT(this.state.deleteName) : (this.state.countTV === 1 ? MessageConstant.TV_USER_EDIT(this.state.deleteName) : (this.state.countCCA === 1 ? MessageConstant.CCA_EDIT(this.state.teamCCA) : MessageConstant.USER_EDIT(this.state.deleteName)))}
          navigate={this.updateUser} />
      </div>
    );
  }
}

export default Registration;
