import React, { Component } from 'react';
import './SamplingDataset.css';
import PropTypes from 'prop-types';
import {Modal } from 'react-bootstrap';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
class SamplingDataset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            samplingData:[],
            samplingDatasetId:[],
        }
    }
    componentDidMount() {
        this.getSamplingDataset();
      }
      
    getSamplingDataset(){
    let data = {};
    let self = this;
    //console.log('samplingDataset',this.props.myProp);
    let datasetId = this.props.myProp;
    utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
        // console.log("samplingData",result);
        if (result.error === false) {
            let samplingData = result.datasets;
            // console.log("samplingData",samplingData);
           
            let sampleTableData = [];
            samplingData.map((sample)=>{
                if(datasetId===sample.id) {
                    // console.log("samplingData",sample.sampleTable);
                    sample.sampleTable.forEach((sampleData)=>{
                        // console.log("data",data);
                        sampleData.teams=Array.from(new Set(sampleData.teams));
                        sampleTableData.push(sampleData);
                    })
                }
               
                
            });
            self.setState({ samplingData: sampleTableData })
          
          
        }
        });
    }
    add3Dots(string, limit) {

        var dots = "...";
        if (string && string.length > limit) {
          // you can also use substr instead of substring
          string = string.substring(0, limit) + dots;
        }
    
        return string;
      }
    render(){
        let sampleTableData = this.state.samplingData;
        const details = sampleTableData.map((result,index)=>{
                // console.log("result",result.teams.toString());
                // let array=[];
                // array.push(result.errorRate);
                // var sum = array.reduce(function(a, b){
                //     return a + b;
                // }, 0);
               
               
                
                return (
                    <tr key={index}>
                        <td>{result.group}</td>
                        <td>{result.population}</td>
                        <td title={result.teams}>{this.add3Dots(result.teams.toString(), 20)}</td>
                        <td>{result.sampleSize}</td>
                        <td>{result.sampleCases}</td>
                    </tr>
                )
            });
            // console.log("details",details);
        return (
           
            <React.Fragment>
                <Modal
                    dialogClassName="samplingPopupModal"
                    show={this.props.showPopup}
                    onHide={this.props.handleClosePopup}
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton className='samplingConfirm-popup-header samplingDataset-header'>
                    Sampling
                    </Modal.Header>

                    <Modal.Body className='samplingDataset-confirm-popup-body'>
                     <table>
                         <thead>
                                <tr>
                                    <th>Stratum</th>
                                    <th>Population Size</th>
                                    <th>Teams</th>
                                    <th>Sample Size (%)</th>
                                    <th>Sampled Cases</th>
                                </tr>
                            </thead>
                        
                        <tbody>
                            {details}
                        
                        </tbody>
                    </table>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
SamplingDataset.propTypes = {
    handleClosePopup: PropTypes.func,
    showPopup:PropTypes.bool,
    myProp: PropTypes.string
  
  }
export default SamplingDataset;