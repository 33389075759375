import React, { Component } from 'react';
import './ErrorList.css';
import { Card, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import PropTypes from 'prop-types';

class CommentHistory extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
 /**
   *renders the ui
   *@return {Object} .
   */
    render() {
        const {commentHist}= this.props;
        let commentHistory = '';
        if(commentHist.length > 0){
            // [commentHist[commentHist.length-1],commentHist[commentHist.length-2]]=[commentHist[commentHist.length-2],commentHist[commentHist.length-1]]
         commentHistory =  commentHist.map((data)=>{
               return (
                    <Card className="mb-2 commentHistory">
                        <div className="cmt">
                            <span className="updatedBy">Updated By: {data.modifiedBy}</span>
                            <span className="updatedAt">Updated At: <Moment unix format="MM/D/YYYY">{data['modifiedAt']}</Moment></span>
                            </div>
                        <Card.Body>{data.comments}</Card.Body>
                    </Card>
               )
           }); 
        }
        else {
           commentHistory = (
                <Card className="mb-2">
                    <Card.Body>Comments Not Available</Card.Body>
                </Card>
            );
        }
        return (
           <React.Fragment>
               <Row>
                   <Col md={12} sm={12}>
                    {commentHistory}
                    {/* ABC */}
                   </Col>
               </Row>
               
           </React.Fragment>
        );
        }
}
CommentHistory.propTypes = {
    commentHist: PropTypes.string,
   
  }
export default CommentHistory;