import React, { Component } from 'react';
import './AdminApprovalPending.css';
import { Card } from 'react-bootstrap';

class AdminApprovalPending extends Component {

  render() {
    return (
    <Card className='content-cneter'>
        <h4>AMGEN</h4>
  <Card.Body>your registration Request hasbeen sent for approval. 
      You can start using the website once admin approves your request.</Card.Body>
    </Card>
    );
  }
}

export default AdminApprovalPending;
