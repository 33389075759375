import React, { Component } from 'react';
import './AddUser.css';
import { Col, Form, Button } from 'react-bootstrap';
import Search from '../Search/Search';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
/* eslint "require-jsdoc": ["error", {
    "require": {
        "FunctionDeclaration": true,
        "MethodDefinition": true,
        "ClassDeclaration": true,
        "ArrowFunctionExpression": true,
        "FunctionExpression": true
    }
}]*/
/**
 * A class that can return some html
 */
class AddUser extends Component {
  /**
 * @constructor
 * @param {void} props  */
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      role: 'TCS',
      justification: '',
    };
    this.changeHandler = this.changeHandler.bind(this)
    this.register = this.register.bind(this);
  }
  /**
    * @param {Object} e The new state.
    */
  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  /**
    * @param {Object} e .
    */
  register(e) {
    // var endpointName = EndpointConstant.ADD_USER
    e.preventDefault();
    utils.makeRequest(this, EndpointConstant.ADD_USER, this.state, function () {
    
    })
  }
  /**
   *renders the ui
   *@return {Object} .
    */
  render() {
    const roles = [{ name: 'Admin', id: 1 },
    { name: 'Quality Monitor', id: 2 },
    { name: 'TCS', id: 3 },
    { name: 'Call Center Admin', id: 4 },
    { name: 'RWS-tanslattion Vendor', id: 5 },
    { name: 'Data Scientist', id: 6 }];
    const rolesOtions = roles.map((role, i) => {
      return (
        <option key={i} value={role.name}>{role.name}</option>
      );
    });
    return (
      <div className='content-cneter'>
        <div>
          <h3>Registration</h3>
          <Search />
          <Form onSubmit={this.register} className='reg-form'>
            <Form.Row>
              <Form.Group as={Col} controlId="firstName">
                <Form.Label className='align-lable'>First Name</Form.Label>
                <Form.Control type='text' name='firstName' value={this.state.firstName}
                  onChange={this.changeHandler} />
              </Form.Group>

              <Form.Group as={Col} controlId="lastName">
                <Form.Label className='align-lable'>Last Name</Form.Label>
                <Form.Control type='text' name='lastName' value={this.state.lastName}
                  onChange={this.changeHandler} />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="email">
              <Form.Label className='align-lable'>Email</Form.Label>
              <Form.Control type='text' name='email' value={this.state.email}
                onChange={this.changeHandler} />
            </Form.Group>

            <Form.Group controlId="role">
              <Form.Label className='align-lable'>Roles</Form.Label>
              <Form.Control as='select' name='role' value={this.state.role}
                onChange={this.changeHandler}>
                {rolesOtions}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="justification">
              <Form.Label className='align-lable'>Example textarea</Form.Label>
              <Form.Control as="textarea" rows="3" name='justification' value={this.state.value}
                onChange={this.changeHandler} />
            </Form.Group>
            <Button variant="secondary" className='margin'>
              Cancel
  </Button>
            <Button variant="secondary" type="submit" className='textcolor'>
              Register
  </Button>
          </Form>

        </div>
      </div>
    );
  }
}

export default AddUser;
