import React, { Component } from 'react';
import './InteractionSearch.css';
import { Accordion, Card, Button, Form, Col, Image } from 'react-bootstrap'
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
import CommonService from '../../commonService/CommonService';
import PropTypes from 'prop-types';
import RoleConstant from './../../constants/RoleConstant';
import MultiSelect from "@khanacademy/react-multi-select";
/**
* A class that can return some html
*@return {Object} .
*@param {object} props
*/
// const Regex = RegExp(/[^A-Za-z-@./#&+\s]/gi);

// function InteractionSearch(props) 
class InteractionSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interactionId: this.props.searchData && this.props.searchData.interactionId ? this.props.searchData.interactionId : '',
      subcaseNumber: this.props.searchData && this.props.searchData.subcaseNumber ? this.props.searchData.subcaseNumber : '',
      createdBy: this.props.searchData && this.props.searchData.createdBy ? this.props.searchData.createdBy : '',
      associatedTeam: this.props.searchData && this.props.searchData.associatedTeam ? this.props.searchData.associatedTeam : '',
      interactionOwner: this.props.searchData && this.props.searchData.interactionOwner ? this.props.searchData.interactionOwner : '',
      country: this.props.searchData && this.props.searchData.country ? this.props.searchData.country : '',
      channel: this.props.searchData && this.props.searchData.channel ? this.props.searchData.channel : '',
      status: this.props.searchData && this.props.searchData.status ? this.props.searchData.status : '',
      assignedTo: this.props.searchData && this.props.searchData.assignedTo ? this.props.searchData.assignedTo : '',
      interactionDetails: [],
      filteredInteractions : [],
      iconName: 'icon-drop-up',
      interactions: [],
      selected: this.props.searchData && this.props.searchData.selected ? this.props.searchData.selected : '',
      dataSet: [],
      LanguageSelected: [],
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.iconChange = this.iconChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    
  }

  /*
    * Getting all the dataset dropdown option
    * by calling dataset API for search section
    */
  getDataSet() {
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
      //console.log("get dataset", result);
      self.setState({
        dataSet: result.datasets
      });
      self.props.datasetIds(result.datasets);
    });
  }

  componentDidMount() {
    this.getDataSet();    
  }

  handleSubmit(e) {
    e.preventDefault();
    //console.log(EndpointConstant.FILTER_INTERACTIONS)
    let self = this
    let data = {}
    if (this.state.interactionId !== '') {
      data.caseNumber = this.state.interactionId;
    }
    if (this.state.subcaseNumber !== '') {
      data.subcaseNumber = this.state.subcaseNumber;
    }
    // if(this.state.createdBy!==''){
    //   data.createdBy=this.state.createdBy
    // }
    if (this.state.associatedTeam.length !== 0) {
      data.associatedTeam = this.state.associatedTeam;
    }
    if (this.state.interactionOwner !== '') {
      data.interactionOwner = this.state.interactionOwner;
    }
    if (this.state.selected.length !== 0) {
      data.datasetId = this.state.selected;
    }
    if (this.state.country.length !== 0) {
      data.interactionCountry = this.state.country;
    }
    if (this.state.channel.length !== 0) {
      data.channel = this.state.channel;
    }
    if (this.state.status.length !== 0) {
      data.status = this.state.status;
    }
    if (this.state.assignedTo.length !== 0) {
      data.assignedTo = this.state.assignedTo;
    }
    if (this.state.interactionId === '' && this.state.subcaseNumber === '' && this.state.associatedTeam.length === 0  && this.state.selected.length === 0 &&
      this.state.country.length === 0  && this.state.channel.length === 0 && this.state.status.length === 0 && this.state.assignedTo.length === 0 ) {
      //data.lastEvaluatedKey = null;
      this.clearFilters();
    }
    else {
      utils.makeRequest(this, EndpointConstant.GET_INTERACTION_FROM_JSON, data, function (result) {
        // 
      if(utils.getSessionItem("role") === RoleConstant.Admin || utils.getSessionItem("role") === RoleConstant.QualityMonitor)
        {
          let roleInteraction =self.props.roleInteractions;
          let filteredInteractionList = self.getFilteredData(roleInteraction, data);
          self.setState({ filteredInteractions: filteredInteractionList});
        }
        const interactions = (utils.getSessionItem("role") === RoleConstant.Admin || utils.getSessionItem("role") === RoleConstant.QualityMonitor)?self.state.filteredInteractions:result.interactions;
        // self.loading = false;
        self.setState({ interactionDetails: interactions })
        self.props.getSearchResult(interactions)
        self.props.setSearchData(self.state)
        // console.log(this.state,'useres');
        var searchlastEvaluatedKey = result.lastEvaluatedKey;
        //console.log(searchlastEvaluatedKey,'searchlastEvaluatedKey');
        self.props.parentCallback(searchlastEvaluatedKey);
        
        // setLastEvaluatedKey(lastEvaluatedKey) {
        //   this.setState({ lastEvaluatedKey: lastEvaluatedKey })
        // }

      })
    }
  
  }

   getFilteredData(interactions, requestData) {
    let interactionData = interactions;
    if (requestData.hasOwnProperty('datasetId')) {
      interactionData = interactionData.filter((value) => requestData.datasetId.some((val) => val ==value.datasetId));
    }
    if (requestData.hasOwnProperty('caseNumber')) {
      interactionData = interactionData.filter((value) => requestData.caseNumber == value.interactionId);
    }
    if (requestData.hasOwnProperty('subcaseNumber')) {
      requestData.subcaseNumber = requestData.subcaseNumber.toLowerCase();
      interactionData = interactionData.filter((value) => requestData.subcaseNumber == value.subcaseNumber);
    }
    if (requestData.hasOwnProperty('associatedTeam')) {
      interactionData = interactionData.filter((value) => requestData.associatedTeam.some((val) => val == value.associatedTeam));
    }
    if (requestData.hasOwnProperty('interactionCountry')) {
      interactionData = interactionData.filter((value) => requestData.interactionCountry.some((val) => val == value.interactionCountry));
    }
    if (requestData.hasOwnProperty('channel')) {
      interactionData = interactionData.filter((value) => requestData.channel.some((val) => val == value.channel));
    }
    if (requestData.hasOwnProperty('status')) {
      interactionData = interactionData.filter((value) => requestData.status.some((val) => {
        if(val == 'Completed(Model)'){
            return value.mlResult == true;
        }
        else if(val == 'Completed'){
          return (value.status=='Completed' && value.mlResult != true);
        }
        return val == value.status
      })
      );
    }
    if (requestData.hasOwnProperty('assignedTo')) {
      interactionData = interactionData.filter((value) => requestData.assignedTo.some((val) => {
          if(val == RoleConstant.CallCenterAdmin){
              return value.assignedToCallCenterAdmin == true;
          }
         return val == value.assignedTo
        })
      );
    }
    return interactionData;
  }

  iconChange() {
    if (this.state.iconName === 'icon-drop-up') {
      this.setState({ iconName: 'icon-drop-down' })
    } else {
      this.setState({ iconName: 'icon-drop-up' })
    }
  }

  clearFilters() {
    let self = this
    this.setState({
      interactionId: '',subcaseNumber: '', createdBy: '', associatedTeam: '',
      interactionOwner: '', country: '', channel: '', status: '', assignedTo: '',
      selected: [], datasetName: []
    }, () => {
      //  this.props.getInteractionList();
      this.props.setSearchData(this.state)
      let data = {
        lastEvaluatedKey: null
      }
      utils.makeRequest(this, EndpointConstant.GET_INTERACTION_FROM_JSON, data, function (result) {
        
        let interactions = (utils.getSessionItem("role") === RoleConstant.Admin || utils.getSessionItem("role") === RoleConstant.QualityMonitor)?self.props.roleInteractions:result.interactions;
        const interactionOrdered = {};
        interactions.forEach((interaction) => {
          if (!interactionOrdered.hasOwnProperty(interaction.interactionId)) {
            interactionOrdered[interaction.interactionId] = [];
          }
          interactionOrdered[interaction.interactionId].push(interaction);
          //console.log(interaction);
        });
        // if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
        //   Object.keys(interactionOrdered).forEach((interactionId) => {
        //     let mlCompleted = true;
        //     interactionOrdered[interactionId].forEach((subcase) => {
        //       if (!subcase.hasOwnProperty('mlResult') || !subcase.mlResult) {
        //         // console.log(interactionId + "subcase.mlResult ++ " + subcase.mlResult);
        //         mlCompleted = false;
        //       }
        //     });
        //     if (mlCompleted) {
        //       delete interactionOrdered[interactionId];
        //     }
        //   });
        // }
        //console.log(interactionOrdered);
        interactions = [];
        Object.keys(interactionOrdered).forEach((interactionId) => {
          interactions = interactions.concat(interactionOrdered[interactionId]);
        });
        self.loading = false;
        // interactions = interactions.concat(self.state.interactions);

        //console.log("interactions ++ " + JSON.stringify(interactions));
        var joined = self.state.interactions.concat(interactions);
        self.setState({ interactions: joined, interactionsSort: joined });
        // self.loading = false;
        if (result.hasOwnProperty('lastEvaluatedKey')) {
          self.props.setLastEvaluatedKey(result.lastEvaluatedKey)
        }
        self.setState({ interactionDetails: interactions })

        self.props.getSearchResult(interactions)
        // console.log(this.state,'useres')

      })
    });
  }
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const valueA = a.value.toUpperCase();
    const valueB = b.value.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  compareDataset(a, b) {
    const valueA = a.label.toUpperCase();
    const valueB = b.label.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }



  render() {
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const role = utils.getSessionItem("role");
    
    let countIntercations = this.props.countIntercations;
    //console.log("count",countIntercations.length)
    let countQM =0;
    let countCCA=0;
    let countAdmin=0;
    let countTV = 0;
    countIntercations.length !== undefined ?countIntercations.map((countAssigned)=>{
    if(countAssigned.assignedTo==="Admin"){
     ++countAdmin;
    }
     if(countAssigned.assignedTo==="QualityMonitor"){
      ++countQM;
     }
     if(countAssigned.assignedToCallCenterAdmin=== true){
      ++countCCA;
     }
     if(countAssigned.assignedTo==="TranslationVendor"){
      ++countTV;
     }
    }):'';
    
    //const teams = [];
    //const assigned = [];
    const teams = Object.keys(siteinfo.CallCenterTeam).map((team, i) => {
      return({ key: i, value: team, text: siteinfo.CallCenterTeam[team] });
      //return null;
    });
    teams.sort(this.compare);
    delete siteinfo.AssignedTo.Model;
    const assigned = Object.keys(siteinfo.AssignedTo).map((roles, i) => {
      return ({ key: i, value: roles, text: siteinfo.AssignedTo[roles], count: roles=== "Admin"?countAdmin:roles=== "QualityMonitor"?countQM:roles=== "CallCenterAdmin"?countCCA:roles=== "TranslationVendor"?countTV:0 });
      
    });
    assigned.sort(this.compare);
    //const teamOptions = [];
    // const teamOptions = teams.map((team, i) => {
    //   return (
    //     <option key={i} value={team.value}>{team.text}</option>
    //   );
    // });
    const teamOptions = teams.map((optionTeam) => {
      //console.log("dataset", teams)
      return ({ id: optionTeam, label: optionTeam.text, value: optionTeam.value });
    });
    // const channelOptions = siteinfo.Channel.map((channel, i) => {
    //   return (
    //     <option key={i} value={channel}>{channel}</option>
    //   )
    // });
    const channelOptions = [];
    siteinfo.Channel.map((channel) => {
      //console.log("dataset", channel)
      return channelOptions.push({ id: channel, label: channel, value: channel });
    });
    // siteinfo.Countries.sort(this.compare);
    // console.log(siteinfo.Countries,'countrues')
    const countryOptions = [];
    // const countryOptions = siteinfo.Countries.sort().map((country, i) => {
    //   return (
    //     <option key={i} value={country}>{country}</option>
    //   )
    // })
    siteinfo.Countries.sort().map((country) => {
      //console.log("dataset", country)
      return countryOptions.push({ id: country, label: country, value: country });
    });
    // const statusOptions = CommonService.interactionStatus.map((status, i) => {
    //   return (
    //     <option key={i} value={status.value}>{status.name}</option>
    //   )
    // })
    const statusOptions = [];
    CommonService.interactionStatus.map((status) => {
      //console.log("dataset", status)
      return statusOptions.push({ id: status, label: status.name, value: status.value });
    });
    // const AssignedToOptions = assigned.map((role, i) => {
    //   return (
    //     <option key={i} value={role.value}>{role.text}</option>
    //   );
    // });

    //const AssignedToOptions = [];
    const AssignedToOptions = assigned.map((assignedRole) => {
      //console.log("assignedRole", assignedRole);
      return ({ id: assignedRole, label: assignedRole.text + " "+ "(" +assignedRole.count+")", value: assignedRole.value });
      
    });
    
    // const options1 = [
    //   {text: "One", value: "One"},
    //   {text: "Two", value:"Two"},
    //   {text: "Three", value:"Three"},
    // ];
    // const LanguageOptions = options1.map((lang,i)=>{
    //   return (
    //     <option key={i} value={lang.value}>{lang.text}</option>
    //   );

    // });
    const {dataSet } = this.state;
    const options = [];
    if (dataSet) {
      dataSet.forEach((dataset) => {
        //console.log("dataset", dataset)
        if(dataset.status==='Complete'){
         options.push({ id: dataset.id, label: dataset.datasetName && dataset.datasetName.split('.')[0], value: dataset.id });
      }
      });
    }
   options.sort(this.compareDataset);
    // const {languageSelected} = this.state;
    // console.log("options1",options1[0].value);
    return (
    
      <Accordion defaultActiveKey="0" className='cardPadding'>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
            <span className="search-title">SEARCH</span>
            <Image src="/Images/ico-drop-up.png" className={this.state.iconName}></Image>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='cardBodyStyle'>
              <Form onSubmit={this.handleSubmit} className="searchForm">
                <Form.Row className='mb-2'>
                  {/* <Form.Group as={Col} controlId="interactionId" md="2"> */}
                  <Col>
                    <Form.Label>Case Number</Form.Label>
                    <Form.Control type='text' name='interactionId' value={this.state.interactionId} onChange={e => { this.setState({ interactionId: e.target.value }) }} />
                    {/* </Form.Group> */}
                  </Col>
                  <Col>
                    <Form.Label>Sub-Case Number</Form.Label>
                    <Form.Control type='text' name='subcaseNumber' value={this.state.subcaseNumber} onChange={e => { this.setState({ subcaseNumber: e.target.value }) }} />
                    {/* </Form.Group> */}
                  </Col>

                  
                  {/* <Form.Group as={Col} controlId="associatedTeam" md="2"> */}
                  <Col>
                    <Form.Label>Associated Team</Form.Label>
                    {/* <Form.Control as='select' name='associatedTeam' value={this.state.associatedTeam} onChange={e => { this.setState({ associatedTeam: e.target.value }) }}>
                      <option value="">--Select--</option>
                      {teamOptions}
                    </Form.Control> */}
                    
                    <MultiSelect
                      options={teamOptions}
                      selected={this.state.associatedTeam}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selected => {
                        //console.log("selected values", selected);
                        this.setState({ associatedTeam : selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    
                  </Col>
                  {/* </Form.Group> */}
                  {/* <Form.Group as={Col} controlId="interactionOwner" md="2"> */}
                  <Col>
                    <Form.Label>Dataset</Form.Label>
                   
                    <MultiSelect
                      options={options}
                      selected={this.state.selected}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selected => {
                        //console.log("selected values", selected);
                        this.setState({ selected: selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>
                  {/* <Col> */}
                 
                      {/* </Col> */}
                  {/* <Form.Group as={Col} controlId="country" md="2"> */}
                  <Col>
                    <Form.Label>Country</Form.Label>
                    {/* <Form.Control as='select' name='country' value={this.state.country} onChange={e => { this.setState({ country: e.target.value }) }}>
                      <option value=''>--Select--</option>
                      {countryOptions}
                    </Form.Control> */}
                    <MultiSelect
                      options={countryOptions}
                      selected={this.state.country}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selected => {
                        //console.log("selected values", selected);
                        this.setState({ country : selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>
                  {/* <Form.Group as={Col} controlId="channel" md="2"> */}
                  <Col>
                    <Form.Label>Channel</Form.Label>
                    {/* <Form.Control as='select' name='channel' value={this.state.channel} onChange={e => { this.setState({ channel: e.target.value }) }}>
                      <option value=''>--Select--</option>
                      {channelOptions}
                    </Form.Control> */}
                    <MultiSelect
                      options={channelOptions}
                      selected={this.state.channel}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selected => {
                        //console.log("selected values", selected);
                        this.setState({ channel : selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                  </Col>
                  {/* </Form.Group> */}
                  {/* <Form.Group as={Col} controlId="status" md="2"> */}
                  {/* </Form.Row>
              
                  <Form.Row className='mb-2' > */}
                  {(role === RoleConstant.TranslationVendor || role === RoleConstant.CallCenterAdmin )? " ":
                  <Col >
                    <Form.Label>Status</Form.Label>
                    {/* <Form.Control as='select' name='status' value={this.state.status} onChange={e => { this.setState({ status: e.target.value }) }}>
                     
                      {statusOptions}
                    </Form.Control> */}
                    <MultiSelect
                      options={statusOptions}
                      selected={this.state.status}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selected => {
                        //console.log("selected values", selected);
                        this.setState({ status : selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group> */}
                    </Col> }

                  {(role === RoleConstant.QualityMonitor || role === RoleConstant.Admin) &&
                    <Col >
                      <Form.Label>Assigned To</Form.Label>
                      {/* <Form.Control as='select' name='assignedTo' value={this.state.assignedTo} onChange={e => { this.setState({ assignedTo: e.target.value }) }}>
                        <option value="">--Select--</option>
                        {AssignedToOptions}
                      </Form.Control> */}
                       <MultiSelect
                      options={AssignedToOptions}
                      selected={this.state.assignedTo}
                      disableSearch={true}
                      hasSelectAll={true}
                      onSelectedChanged={selected => {
                        //console.log("selected values", selected);
                        this.setState({ assignedTo: selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                      {/* </Form.Group> */}
                    </Col>}

                </Form.Row>
                <Button variant="secondary" type="submit" className='interactionSearch button-color interactionSearch-marginset'>Search</Button>
                <Button variant="secondary" className='clear interactionSearch-marginset' onClick={this.clearFilters}>Clear</Button>

              </Form></Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
  }
}
InteractionSearch.propTypes = {
  getSearchResult: PropTypes.func,
  getInteractionList: PropTypes.func,
  searchData: PropTypes.object,
  setSearchData: PropTypes.func,
  roleInteractions: PropTypes.array


}
export default InteractionSearch;

