import React, { Component } from 'react';
// import { Col, Row, Button } from 'react-bootstrap';
// import { Header } from '../';
import utils from './../../utils';
import './Login.css';
import SpinnerLoader from '../Loader/SpinnerLoader';
import RouterConstant from '../../constants/RouterConstant';
import PropTypes from 'prop-types';


class Login extends Component {

  constructor(props) {
    super(props);
    this.performSSORedirect = this.performSSORedirect.bind(this);
    this.checkAuthentication = this.checkAuthentication.bind(this);
  }
  
  componentDidMount() {
    this.performSSORedirect();
    // this.checkAuthentication();
  }

  performSSORedirect() {
    // window.location.href = config.sso.url;
    // this.props.auth.login(utils.getURL(RouterConstant.RESET_AUTH));

    let path = { pathname: utils.getURL(RouterConstant.RESET_AUTH) };
    window.localStorage.setItem('secureRouterReferrerPath', JSON.stringify(path));
    
    window.location.href = window.okta.redirectUri;
  }

  async checkAuthentication() {
    //const authenticated = await this.props.auth.isAuthenticated();
    
    //console.log("authenticated ::: " + authenticated);
  }

  render() {
    return (
      <div>
        <SpinnerLoader />
      </div>
    );
  }
}
Login.propTypes = {
  auth:PropTypes.object,
}

export default Login;