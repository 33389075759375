import React, { Component } from 'react';
import './AgentNotesPopup.css';
import PropTypes from 'prop-types';
import {Modal, Button } from 'react-bootstrap';
class AgentNotesPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.iframeLoaded = this.iframeLoaded.bind(this)
    }
    // componentDidMount() {
    //     // this.getSamplingDataset();
        
    //   }
   
    componentDidMount() {
        const { viewData } = this.props;
        var iframeAgentNotesPopup = viewData.agentNotes;
        // console.log("viewData",viewData.agentNotes);
        document.querySelector('.agentNotesPopup-iframe')
        .contentDocument.write(iframeAgentNotesPopup);
        this.iframeLoaded(); 
          }
      iframeLoaded() {
        if(this.props.viewData.agentNotes != ''){
            var iframeHeight = document.getElementById('myIframe');
            // console.log("iframe id",iframeHeight);
            // console.log("height",iframeHeight.contentWindow.document.body.scrollHeight)
            // iframeHeight.style.height = iframeHeight.contentWindow.document.body.scrollHeight + 'px';
            iframeHeight.contentDocument.body.style.fontFamily = "Helvetica";
            iframeHeight.contentDocument.body.style.fontSize = "15px";
            iframeHeight.contentDocument.body.style.color= '#333333';
        
        //iframeHeight.contentDocument.body.p.style.margin = '0px';
        var elmnts = iframeHeight.contentWindow.document.getElementsByTagName("p");
        //console.log('elemnts',elmnts.length);
        for(var i=0;i<elmnts.length;i++) {
            elmnts[i].style.margin='0px';
            elmnts[i].style.marginBottom='2px';
        }
      }
    }
    render(){
        
            const { viewData } = this.props;
            //console.log("viewData",viewData)
        return (
            <React.Fragment>
            <Modal
                dialogClassName="popupModalAgent"
                show={this.props.showPopup}
                onHide={this.props.handleClosePopup}
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton className='confirm-popup-header agentNotes-header'>
                AgentNotes
                </Modal.Header>

                <Modal.Body className='confirm-popup-body'>
                    {/* <div className='scrollagentNotespopup'>{viewData.agentNotes}</div> */}
                    <iframe className="agentNotesPopup-iframe" id="myIframe" onLoad={this.iframeLoaded}></iframe>
               
                    </Modal.Body>
                <Modal.Footer className='confirm-popup-footer'>
                    <Button active onClick={this.props.handleClosePopup}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
        );
    }
}
AgentNotesPopup.propTypes = {
    handleClosePopup: PropTypes.func,
    showPopup:PropTypes.bool,
    viewData: PropTypes.array,
  
  }
export default AgentNotesPopup;