import React, { Component } from 'react';
import './ScoreCard.css';
import { Accordion, Card, Button, Form, Col, Image } from 'react-bootstrap'
// import CommonService from '../../commonService/CommonService';
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import PropTypes from 'prop-types';
import RoleConstant from '../../constants/RoleConstant';
import MultiSelect from "@khanacademy/react-multi-select";
class ScoreCardSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      iconName: 'icon-drop-up',
      subcaseNumber: this.props.getResult && this.props.getResult.subcaseNumber ? this.props.getResult.subcaseNumber : '',
      channel: this.props.getResult && this.props.getResult.channel ? this.props.getResult.channel : '',
      attachmentIncluded: this.props.getResult && this.props.getResult.attachmentIncluded ? this.props.getResult.attachmentIncluded:'',
      QMAssociate: this.props.getResult && this.props.getResult.QMAssociate ? this.props.getResult.QMAssociate:'',
      qualityMonitorQCBy: this.props.getResult && this.props.getResult.qualityMonitorQCBy ? this.props.getResult.qualityMonitorQCBy:'',
      amgenQCBy: this.props.getResult && this.props.getResult.amgenQCBy ? this.props.getResult.amgenQCBy:'',
      selected: this.props.getResult && this.props.getResult.selected ? this.props.getResult.selected : [],
      dataSet:  [],
      sampleSet: this.props.sampleName,
    }
    this.iconChange = this.iconChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.sampleName !== this.props.sampleName) {
      this.setState({
        subcaseNumber: '',
        selected: [],
        channel: '',
        attachmentIncluded: '',
        QMAssociate: '',
        qualityMonitorQCBy: '',
        amgenQCBy: '',
      });
    }
  }

  getDataSet() {
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
      //console.log("get dataset", result);
      self.setState({
        dataSet: result.datasets
      })
    });
  }

  componentDidMount() {
    this.getDataSet();
  }

  iconChange() {
    if (this.state.iconName === 'icon-drop-up') {
      this.setState({ iconName: 'icon-drop-down' })
    } else {
      this.setState({ iconName: 'icon-drop-up' })
    }
  }
  handleOnChange(event) {
    //console.log("onchange", event);
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  handleOnClear() {
    let self = this;
    let { sampleName } = this.props;
    self.setState({
      // iconName: 'fa fa-caret-up iconstyles-case',
      subcaseNumber: '',
      selected: [], 
      datasetName: [],
      channel: '',
      attachmentIncluded: '',
      QMAssociate: '',
      qualityMonitorQCBy: '',
      amgenQCBy: '',
      scorecardList: []
    }, () => {
      // if (sampleName) {
      //   self.props.clearSampling(true);
      // } else {
       
      //   if(!sampleName) {
      //   self.props.clearState();
      //   self.props.getScoreCardList();
      //   if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
      //   self.props.getScoreCardList('stage1');
      //   }
      // } else if (sampleName) {
        
          if (sampleName) {
          let data = {};
          data.sampleSetName = sampleName;
          utils.makeRequest(this, EndpointConstant.SCORECARDS_FROM_SAMPLESET, data, function (result) {
            if(result.hasOwnProperty('error') && !result.error) {
              const scorecardList = result.scorecards;
              // console.log(scorecardList);
              // self.loading = false;
              self.setState({
                scorecardList: scorecardList
              }, () => { self.props.getSearchResult(scorecardList);
              self.props.setSearchResult(self.state) });
            //console.log("scorecards",self.state.scorecardList)
          }
      })
    } else {
      self.props.clearState();
        self.props.getScoreCardList();
        if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
        self.props.getScoreCardList('stage1');
    }
  }
      
      // if(sampleName && self.props.hasOwnProperty('getResult') && self.props.getResult.length==0) {
      //   // self.props.getScoreCardList();
      //   if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
      //     self.props.getScoreCardList('stage1');
      //     }
      // } else {
      //   self.props.clearState();
      //   self.props.getScoreCardList();
        // if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
        //   self.props.getScoreCardList('stage1');
        //   }
      // }
      // }
    })
  }
  handleSearch(event) {
    event.preventDefault();
    //console.log("handle search", this.state);
    // console.log("Ref" + this.props.handleClear);
    let { sampleName } = this.props;
    if (!sampleName) {
      this.props.clearSampling(false);
      sampleName = false;
    }
    let self = this
    let data = {}
    if (this.state.subcaseNumber !== '') {
      data.subcaseNumber = this.state.subcaseNumber
    }
    if (this.state.selected.length !== 0) {
      data.datasetId = this.state.selected;
    }
    if (this.state.channel.length !== 0) {
      data.channel = this.state.channel
    }
    if (this.state.attachmentIncluded !== '') {
      data.attachmentIncluded = this.state.attachmentIncluded
    }
    if (this.state.QMAssociate !== '') {
      data.QMAssociate = this.state.QMAssociate
    }
    if (this.state.qualityMonitorQCBy !== '') {
      data.qualityMonitorQCBy = this.state.qualityMonitorQCBy
    }
    if (this.state.amgenQCBy !== '') {
      data.amgenQCBy = this.state.amgenQCBy
    }
    data.sampleName = sampleName;
    utils.makeRequest(this, EndpointConstant.GET_SCORECARD_FROM_JSON, data, async function (result) {
      utils.showLoader();
      await fetch(result.scorecards, {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        method: 'GET',
        body: null,
      })
        .then((response) => response.json())
        .then((s3ResponseJson) => {
          utils.hideLoader();
          const scorecardList = s3ResponseJson;
          self.setState({
            scorecardList: scorecardList
          }, () => {
            self.props.getSearchResult(scorecardList);
            self.props.setSearchResult(self.state)
          });

        })
        .catch((error) => {
          console.error(error);

        });

    })

  }

  compareDataset(a, b) {
    const valueA = a.label.toUpperCase();
    const valueB = b.label.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  } 

  render() {
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const { subcaseNumber, channel, QMAssociate, qualityMonitorQCBy, amgenQCBy } = this.state
    // const ifrOptions = CommonService.ifrType.map((status, i) => {
    //   return (
    //     <option key={i} value={status.value}>{status.value}</option>
    //   )
    // })
    // const channelOptions = siteinfo.Channel.map((channel, i) => {
    //   return (
    //     <option key={i} value={channel}>{channel}</option>
    //   )
    // });
    //const channelOptions = [];


    // const channelOptions = siteinfo.Channel.map((optionChannel) => {
    //   console.log("channeldataset", channelOptions)
    //   return ({ id: optionChannel, label: optionChannel , value: optionChannel });
    // });


    const channelOptions = [];
    siteinfo.Channel.map((channel) => {
      //console.log("dataset", channel)
      return channelOptions.push({ id: channel, label: channel, value: channel });
    });


    const { selected, dataSet } = this.state;
    const options = [];
    if (dataSet) {
      dataSet.forEach((dataset) => {
        //console.log("dataset", dataset)
        if(dataset.status==='Complete'){
         options.push({ id: dataset.id, label: dataset.datasetName && dataset.datasetName.split('.')[0], value: dataset.id });
      }
      });
    }
    options.sort(this.compareDataset);
    return (
      <Accordion defaultActiveKey="0" className='cardPadding searchScorecard'>
        <Card >
          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
            <span className="search-title">SEARCH SCORE CARD</span><Image src="/Images/ico-drop-up.png" className={this.state.iconName}></Image>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='cardBodyStyle'>
              <Form onSubmit={this.handleSearch} className="searchForm">
                <Form.Row className='mb-2'>
                  <Col>
                    <Form.Label>Sub-Case Number</Form.Label>
                    <Form.Control type='text' name='subcaseNumber' value={subcaseNumber} onChange={this.handleOnChange} />
                  </Col>

                  <Col>
                    <Form.Label>Dataset</Form.Label>
                    <MultiSelect
                      options={options}
                      selected={selected}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selectedDataset => {
                        //console.log("selected values", selected);
                        this.setState({ selected:selectedDataset });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>
                  <Col>
                    <Form.Label>Channel</Form.Label>
                    {/* <Form.Control as='select' name='channel' value={channel} onChange={this.handleOnChange}>
                      <option value=''>--Select--</option>
                      {channelOptions}
                    </Form.Control> */}
                    <MultiSelect
                      options={channelOptions}
                      selected={channel}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={selectedChannel => {
                        //console.log("selected values", selectedChannel);
                        this.setState({ channel:selectedChannel });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Attachment Included?</Form.Label>
                    <Form.Check
                      custom inline
                      label="Yes"
                      type='radio'
                      id='Yes'
                      name="attachmentIncluded"
                      value='Yes'
                      checked={this.state.attachmentIncluded === 'Yes'}
                      onChange={this.handleOnChange}
                      className="attachment-included"
                    />
                    <Form.Check
                      custom inline
                      label="No"
                      type='radio'
                      name="attachmentIncluded"
                      id='No'
                      value='No'
                      checked={this.state.attachmentIncluded === 'No'}
                      onChange={this.handleOnChange}
                      className="attachment-included"
                    />
                  </Col>
                  <Col>
                    <Form.Label>QM Associate</Form.Label>
                    <Form.Control type='text' name='QMAssociate' value={QMAssociate} onChange={this.handleOnChange} />
                  </Col>
                  <Col>
                    <Form.Label>Quality Monitor QC By</Form.Label>
                    <Form.Control type='text' name='qualityMonitorQCBy' value={qualityMonitorQCBy} onChange={this.handleOnChange} />
                  </Col>
                  <Col>
                    <Form.Label>Amgen QC By</Form.Label>
                    <Form.Control type='text' name='amgenQCBy' value={amgenQCBy} onChange={this.handleOnChange} />
                  </Col>
                  </Form.Row>
                  <Button variant="secondary" type="submit" className='scorecardSearch button-color '>Search</Button>
                  <Button variant="secondary" className='clear' onClick={this.handleOnClear}>Clear</Button>
                  
              </Form></Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
  }
}

ScoreCardSearch.propTypes = {
  getSearchResult: PropTypes.func,
  setSearchResult: PropTypes.func,
  sampleName: PropTypes.string,
  clearSampling: PropTypes.func,
  getResult: PropTypes.array,
}

export default ScoreCardSearch;
