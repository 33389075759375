import React, { Component } from 'react';
import utils from './../../utils';
import EndpointConstant from './../../constants/EndpointConstant';
import PropTypes from 'prop-types';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    //console.log(error + ": Error ++++++ ");
    //console.log(info + ": info ++++++ ");
    let data = {
      error: error,
      info: info
    };
    utils.makeRequestInBackground(EndpointConstant.LOG_UI_ERROS, data, function () { });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<h1 style={"text-align: center; position: absolute; top: 0; bottom: 0; margin: auto; height: 50px; right: 0; left: 0;"}>Something went wrong.<br/>Please try refreshing the screen.</h1>);
    }
    return this.props.children;
  }
}
ErrorHandler.propTypes = {
  children: PropTypes,
}

export default ErrorHandler;