

import React, { Component } from 'react';
import './CountryTotals.css';
import FilterCountryTotals from '../FilterCountryTotals/FilterCountryTotals';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import { Sidebar, Header } from '..';
// import { DataModelCharts } from '..';
import utils from '../../../utils';
import EndpointConstant from '../../../constants/EndpointConstant';
import { Popover,Image} from 'react-bootstrap';
// import Pagination from '../Pagination/Pagination';
import Pagination from '../../Pagination/Pagination'
import PropTypes from 'prop-types';
import * as uuid from 'uuid';
const repeatCallTimeDelayInMS = 10000;
// import DataModelSearch from './DataModelSearch'

class CountryTotals extends Component {

  constructor(props) {
    super(props);
    this.state = {
      countryData: [],
      countryList: [],
      teamNames: [],
      showpagination: false,
      currentTodos: [],
      requestSuccess: false,
      emptyData: false,
    }
    this.getCountryTotals = this.getCountryTotals.bind(this);
    this.countryTotalsData = this.countryTotalsData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onDownloadFilter = this.onDownloadFilter.bind(this);
    this.getCountryTotalsOnClear = this.getCountryTotalsOnClear.bind(this);
  }
  componentDidMount() {
    this.getCountryTotals(null);
  }
  countryTotalsData(result, filter) {
    let countryData = [];
    let countryList = [];
    let teamNames = [];
    let dataLength = Object.keys(result.countryTotals[0]).length;
    if(dataLength === 0) {
      this.setState({emptyData: true})
    } else {
      this.setState({emptyData: false})
    }
    //console.log(result, 'result countrytotals')
    for (let i = 0; i < result.countryTotals.length; i++) {
      let countryDataValues = {}
      Object.keys(result.countryTotals[i]).forEach((country) => {
        //console.log(result.countryTotals[i][country], country, 'yoyo')
        if (country !== 'datasetName') {
          if (countryList.indexOf(country) === -1 && country !== '') {
            countryList.push(country)
          }
          //console.log(Object.keys(result.countryTotals[i][country]), 'Object.keys(result.countryTotals[i][country])')
          Object.keys(result.countryTotals[i][country]).forEach((teams) => {
            countryDataValues = {}
            //console.log(teams, result.countryTotals[i][country][teams], 'yoyo result')
            countryDataValues.datasetName = result.countryTotals[i].datasetName
            countryDataValues.countryName = country
            countryDataValues.teamName = teams
            countryDataValues.caseCount = result.countryTotals[i][country][teams].caseCount
            countryDataValues.totalError = result.countryTotals[i][country][teams].totals
            countryDataValues.errorPercent = result.countryTotals[i][country][teams].errorPercentage
            countryDataValues.bce = result.countryTotals[i][country][teams].bce
            countryDataValues.bceErrorType = result.countryTotals[i][country][teams].bceErrorType
            countryDataValues.subcaseB = result.countryTotals[i][country][teams].subcaseForBce
            countryDataValues.bnce = result.countryTotals[i][country][teams].bnce
            countryDataValues.bnceErrortype = result.countryTotals[i][country][teams].bnceErrorType
            countryDataValues.subcaseNB = result.countryTotals[i][country][teams].subcaseForBnce
            countryData.push(countryDataValues)
            if (teamNames.indexOf(teams) === -1 && teams !== '') {
              return teamNames.push(teams)
            }
            return null;
          })

          //console.log(countryDataValues, 'countryDataValues')
          //console.log(countryData, 'countryData')

        }
        return null;
      })
    }
    this.setState({ countryData: countryData }, () => this.setState({ showpagination: true }))
    if (filter !== 'filter') {
      this.setState({ countryList: countryList, teamNames: teamNames })
    }
  }
  getCountryTotalsOnClear() {
    let self = this;
    this.setState({ requestSuccess: false }, () => self.getCountryTotals());
  }

  getCountryTotals(filteredData, continous) {
    if (this.state.requestSuccess)
      return true;
    const generatedId = uuid.v4();
    let data = filteredData ? filteredData : {getGeneratedResultId: generatedId,};
    let self = this;
    utils.showLoader(); 
    if (!continous) {
      setTimeout(() => {data.getGeneratedResult = true;self.getCountryTotals(data, true)}, repeatCallTimeDelayInMS);
    }   
    utils.makeRequest(this, EndpointConstant.COUNTRY_TOTALS, data, function (result) {
      if (result.error === 'false' || result.error === false) {
          utils.hideLoader()
          self.countryTotalsData(result);
          self.setState({requestSuccess: true});
      } else {
          utils.showLoader()
          data.getGeneratedResult = true;
          setTimeout(() => {self.getCountryTotals(data, true)}, repeatCallTimeDelayInMS);
      }
      //console.log(result.countryTotals, 'result')
      // console.log(Object.keys(result.countryTotals[1]['United Kingdom']['Germany MedInfo Call Center']),'result')
      
    })

  }

  colorChangeStatus(status) {
    const styles = {
      Approved: '#0F9D58',
      Rejected: '#EA4335',
      Processing: '#F4B400'
    }
    switch (status) {
      case ('Approved'):
        return { color: styles.Approved };
      case ('Rejected'):
        return { color: styles.Rejected };
      default:
        return {};
    }
  }
  onChangePage(currentTodos) {
    //console.log(currentTodos, 'currentTodos')
    // if(currentTodos.length>0)
    this.setState({ currentTodos: currentTodos }, () => { this.setState({ showpagination: true }) })
  }

  onDownloadFilter(filteredData) {
    this.props.onDownloadFilter(filteredData);
  }

  resetPagination() {
    // this.refs.pagination.updateCurrentPageNumber();
  }

  add3Dots(string, limit) {
    var dots = "...";
    if (string && string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }

    return string;
  }

  render() {
    // const {dataModelReports}=this.state
    const { countryList, teamNames } = this.state
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const dataModelList = this.state.currentTodos.length > 0 && this.state.currentTodos.map((data, index) => {
      // let bceErrorType = ''
      // console.log("data.bceErrorTyp12 +++++++ " + data.bceErrorType);
      data.bceErrorType && data.bceErrorType.map((errorType, indexes) => {
        // console.log("errorType1 +++++++ " + errorType);
        if (siteinfo.ErrorTypes.hasOwnProperty(errorType)) {
          data.bceErrorType[indexes] = siteinfo.ErrorTypes[errorType];
        }
        return null;
      });
      // let bnceErrorType = ''
      data.bnceErrortype && data.bnceErrortype.map((errorType, ind) => {
        // console.log("errorType2 +++++++ " + siteinfo.ErrorTypes[errorType]);
        if (siteinfo.ErrorTypes.hasOwnProperty(errorType)) {
          data.bnceErrortype[ind] = siteinfo.ErrorTypes[errorType];
        }
        return null
      });
      let subcaseB = ''
      data.subcaseB && Object.values(data.subcaseB).map((subcaseb) => {
        return subcaseB = subcaseB + subcaseb.toUpperCase() + ','
      })
      let subcaseNB = ''
      data.subcaseNB && Object.values(data.subcaseNB).map((subcase) => {
        return subcaseNB = subcaseNB + subcase.toUpperCase() + ','
      })
      const popover = (<Popover id="popover-positioned-bottom" className="popInteracation">
        <Popover.Title as="h3" >
          <span>Sub-case Numbers</span>
          <button type="button" onClick={() => document.body.click()} className="close">&times;</button>
        </Popover.Title>
        <Popover.Content>
          {data.subcaseNB ? data.subcaseNB.join(', ').toUpperCase() : '-'}
        </Popover.Content>
      </Popover>)
      const popoverB = (<Popover id="popover-positioned-bottom" className="popInteracationB">
        <Popover.Title as="h3" >
          <span>Sub-case Numbers</span>
          <button type="button" onClick={() => document.body.click()} className="close">&times;</button>
        </Popover.Title>
        <Popover.Content>
          {data.subcaseB ? data.subcaseB.join(', ').toUpperCase() : '-'}
        </Popover.Content>
      </Popover>)
      return (
        <tr key={index} className='countryTotalRow'>
          <td title={data.datasetName}>{this.add3Dots(data.datasetName,7)}</td>
          <td title={data.countryName}>{this.add3Dots(data.countryName,6)}</td>
          <td title={data.teamName}>{this.add3Dots(data.teamName,5)}</td>
          <td>{data.caseCount}</td>
          <td>{data.totalError ? data.totalError : '-'}</td>
          <td>{data.errorPercent ? Math.floor(data.errorPercent) : '-'}</td>
          <td>{data.bce ? data.bce : '-'}</td>
          <td title={data.bceErrorType}>{data.bceErrorType ? this.add3Dots(data.bceErrorType.join(', '),8) : '-'}</td>
          {/* <td>{bceErrorType}</td> */}
          <td>{data.subcaseB ? data.subcaseB[0].toUpperCase() : '-'}
            {data.subcaseB && <OverlayTrigger trigger="click" placement="bottom" rootClose ref={`overlay`} overlay={popoverB}  >
              <i className="iconStyle" aria-hidden="true"></i>
            </OverlayTrigger>}
          </td>
          {/* <td>{subcaseB}</td> */}
          <td>{data.bnce ? data.bnce : '-'}</td>
          <td title={data.bnceErrortype}>{data.bnceErrortype ? this.add3Dots(data.bnceErrortype.join(', '),8) : '-'}</td>
          {/* <td>{bnceErrorType}</td> */}
          <td>{data.subcaseNB ? data.subcaseNB[0].toUpperCase() : '-'}
            {data.subcaseNB && <OverlayTrigger trigger="click" placement="bottom" rootClose ref={`overlay`} overlay={popover}  >
              <i className="iconStyle" aria-hidden="true"></i>
            </OverlayTrigger>}
          </td>
        </tr>
      );
    })

    return (
      <div className="gims-charts">
        <div className="row">
          <div className="col-md-12">
            <FilterCountryTotals resetPagination={this.resetPagination.bind(this)} countryList={countryList} teamNames={teamNames} countryTotalsData={this.countryTotalsData} getCountryTotals={this.getCountryTotalsOnClear} onDownloadFilter={this.onDownloadFilter} />
          </div>
        </div>
        {this.state.currentTodos.length > 0 || this.state.emptyData === true?
        <table>
          <thead>
            <tr>
              <th className='width5'>Data Set</th>
              <th>Country</th>
              <th className='width2'>Team Name</th>
              <th>Cases</th>
              <th className='width2'>Total Errors</th>
              <th className='width5'>% Errors</th>
              <th className='width3'>BC Errors</th>
              <th>BC Error Type</th>
              <th className='width1'>Sub-case # for business-critical errors</th>
              <th className='width2'>BNC Errors</th>
              <th>BNC Error Type</th>
              <th className='width4'>Sub-Case # For BNC Errors</th>
            </tr>
          </thead>

          <tbody>
            {dataModelList}
          </tbody>
        </table>
        :<div className="countryLoad"><Image src="/Images/loading.gif" className="countryLoading"/><br/><span>Loading data, Please wait...</span></div>}

        {
          // eslint-disable-next-line react/no-string-refs
          this.state.showpagination ? <Pagination ref="pagination" data={this.state.countryData} onChangePage={this.onChangePage} /> : ''
        }
      </div>

    )
  }
}
CountryTotals.propTypes = {
  onDownloadFilter: PropTypes.func,
}

export default CountryTotals;