import React, { Component } from 'react';
import logoImg from './../../assets/images/Logo-GIMS.png';
import utils from './../../utils';
import RouterConstant from '../../constants/RouterConstant';
import EndpointConstant from '../../constants/EndpointConstant';
import MessageConstant from '../../constants/MessageConstant';

class Logout extends Component {
	componentDidMount() {
		this.getSiteInfo();
	}

	Login() {
    utils.gotoPage(RouterConstant.LOGIN_LINK);
    // this.props.history.push(RouterConstant.LOGIN_LINK);
	}
	getSiteInfo() {
		const idToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
    //console.log("logoout accesstoken",idToken);
	utils.makeRequest(this, EndpointConstant.GET_SITE_INFO, {token:null}, function(result) {
    //console.log("result : " + JSON.stringify(result) );
    utils.setSessionItem("SiteInfo", JSON.stringify(result));
	});
	}


	render(){
		return (
			<div className="landing-container">				
				<div className="landing-header">
					<div className="row">
						{/* <div className="logo"></div> */}
						<div className="ls-logo-wrapper mx-auto">
							<img src={logoImg} alt="Amgen" />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col"><hr className="no-margin" /></div>
				</div>
				<div className="landing-wrapper">
                    <div>{MessageConstant.LOGOUT_MESSAGE}</div>
					<div className="row">
						<button className="col-md-12 btn btn-primary mb-2 sign-in" onClick={() => this.Login()}>Sign in with OKTA</button>
					</div>
					
				</div>
			</div>
		);
	}
}

export default Logout;