import React, { Component } from 'react';
import './PopupModels.css';
import { Modal, Button, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import MessageConstant from './../../constants/MessageConstant';

class PopupModels extends Component {
    constructor(props) {
        //console.log("showPopup", props.showPopup);
        super(props);
        this.state = {
            //show: this.props.showPopup
        }
    }

    render() {
        const iconType = this.props.iconFlag === "success" ? '/Images/ico-success.png' : '/Images/ico-error.png';
        return (
            <React.Fragment>
                <Modal
                    dialogClassName="popupModal"
                    show={this.props.showPopup}
                    onHide={this.props.handleClosePopup}
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton className='confirm-popup-header'>

                    </Modal.Header>

                    <Modal.Body className='confirm-popup-body'>
                        <Image src={iconType} className={this.props.iconFlag==="success"?'icon-img':'icon-img-error'} alt='Amgen'></Image>
                        {this.props.iconFlag==="success"?<div className='success-styles'>Success!</div>:
                        <div className='error-styles'>Error!</div>}
                        {this.props.message}</Modal.Body>
                    <Modal.Footer className='confirm-popup-footer'>
                        <Button active onClick={this.props.handleClosePopup}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}
PopupModels.propTypes = {
    message:PropTypes.string,
    handleClosePopup: PropTypes.func,
    showPopup:PropTypes.bool,
    iconFlag:PropTypes.string
  
  }
export default PopupModels;